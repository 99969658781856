export const nativeFonts = [
  "Arial",
  "Courier",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
];

export const googleFonts = [
  // Sans-serif
  "Inter",
  "Lato",
  "Poppins",
  "Roboto",

  // Serif
  "IBM Plex Serif",
  "Lora",
  "Merriweather",
  "Roboto Slab",
];

export const loadGoogleFont = (font) => {
  const link = document.createElement("link");
  link.href = `https://fonts.googleapis.com/css?family=${font.replace(" ", "+")}&display=swap`;
  link.rel = "stylesheet";
  document.head.appendChild(link);
};
