/**
 * Drawer directive
 *
 * This directive is used to open drawers from any element.
 *
 * Usage:
 *
 * <button v-be-drawer="'drawer-id'">Open drawer</button>
 * <button v-be-drawer.drawer-id>Open drawer</button>
 */

import { EventBus } from "@/event-bus";
import { KEY_CODE_ENTER, KEY_CODE_SPACE } from "@/constants/key-codes";

// Prop name we use to store info on root element
const PROPERTY = "__be_drawer_directive";

const getTarget = ({ modifiers = {}, arg, value }) => {
  if (typeof value === "string") {
    return value;
  } else if (typeof arg === "string") {
    return arg;
  } else {
    return Object.keys(modifiers).reverse()[0];
  }
};

const getTriggerElement = (el) => {
  // If root element is a dropdown-item or nav-item,
  // we need to target the inner link or button instead
  // '.dropdown-menu > li, li.nav-item'
  return el &&
    Element.prototype.matches.call(el, ".dropdown-menu > li, li.nav-item")
    ? el.querySelector("a, button") || el
    : el;
};

const setRole = (trigger) => {
  // Ensure accessibility on non-button elements
  if (trigger && trigger.tagName !== "BUTTON") {
    // Only set role if not already set
    if (!trigger.getAttribute("role")) {
      trigger.setAttribute("role", "button");
    }

    // Add a tabindex if not a button or link, and tabindex is not provided
    if (trigger.tagName !== "A" && !trigger.getAttribute("tabindex")) {
      trigger.setAttribute("tabindex", "0");
    }
  }
};

const bind = (el, binding) => {
  const target = getTarget(binding);
  const trigger = getTriggerElement(el);

  if (target && trigger) {
    const handler = (event) => {
      const currentTarget = event.currentTarget;

      // Toggle drawer only if trigger is not disabled
      if (
        !(currentTarget.tagName === "BUTTON" && currentTarget.disabled) &&
        !(
          currentTarget.tagName === "A" &&
          currentTarget.classList.contains("disabled")
        )
      ) {
        const { type, key } = event;

        if (
          type === "click" ||
          (type === "keydown" &&
            (key === KEY_CODE_ENTER || key === KEY_CODE_SPACE))
        ) {
          EventBus.emit("be::drawer::toggle", target);
        }
      }
    };

    // Store info on root element
    el[PROPERTY] = { handler, target, trigger };

    // If element is not a button, we add `role="button"` for accessibility
    setRole(trigger);

    // Listen for click events
    trigger.addEventListener("click", handler);

    // If trigger is not a button, but is has `role="button"`,
    // we also listen for keydown events
    if (
      trigger.tagName !== "BUTTON" &&
      trigger.getAttribute("role") === "button"
    ) {
      trigger.addEventListener("keydown", handler);
    }
  }
};

const unbind = (el) => {
  const oldProp = el[PROPERTY] || {};

  const { trigger, handler } = oldProp;

  if (trigger && handler) {
    // Remove event listeners
    trigger.removeEventListener("click", handler);
    trigger.removeEventListener("keydown", handler);
    el.removeEventListener("click", handler);
    el.removeEventListener("keydown", handler);
  }

  // Remove stored info
  delete el[PROPERTY];
};

const componentUpdated = (el, binding, vnode) => {
  const oldProp = el[PROPERTY] || {};
  const target = getTarget(binding);
  const trigger = getTriggerElement(el);

  // If target or trigger has changed, we need to unbind and rebind
  if (target !== oldProp.target || trigger !== oldProp.trigger) {
    unbind(el);
    bind(el, binding, vnode);
  }

  // If trigger element is not a button, ensure accessibility
  setRole(trigger);
};

export default {
  mounted: componentUpdated,
  updated: componentUpdated,
  unmounted: unbind,
};
