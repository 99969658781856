<template>
  <be-dropdown-item v-be-modal.create-template-modal>
    {{
      $t(
        `components.meetings.agenda_templates.create_template.title.${materialType}`
      )
    }}
  </be-dropdown-item>

  <be-modal
    id="create-template-modal"
    :title="$t('views.companies.meetings.action_bar.convert_to_template.title')"
    @hidden="onHidden"
  >
    <p>
      {{
        $t(
          "views.companies.meetings.action_bar.convert_to_template.description"
        )
      }}
    </p>

    <be-form-group
      :label="
        $t(
          'views.companies.meetings.action_bar.convert_to_template.input_label'
        )
      "
      label-for="template-title"
    >
      <be-form-input
        id="template-title"
        v-model="templateTitle"
        required
        @keydown.enter="createTemplate"
      />
    </be-form-group>

    <template #footer="{ cancel }">
      <be-button variant="secondary" @click="cancel">
        {{ $t("buttons.titles.cancel") }}
      </be-button>

      <be-button
        :disabled="!templateTitle"
        :loading="loading"
        variant="primary"
        @click="createTemplate"
      >
        {{ $t("buttons.titles.create") }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
export default {
  props: {
    meetingId: {
      type: Number,
      required: true,
    },

    materialType: {
      type: String,
      required: false,
      default: "agenda",
    },
  },

  data() {
    return {
      loading: false,
      templateTitle: "",
    };
  },

  methods: {
    async createTemplate() {
      this.loading = true;

      try {
        const { data } = await axios.post(
          this.url(`/meetings/${this.meetingId}/material_to_agenda_templates`),
          {
            material: {
              template_title: this.templateTitle,
              material_type: this.materialType,
            },
          }
        );

        window.location = this.url(`meetings/agenda_templates/${data.id}/edit`);
      } catch (error) {
        this.handleError(error);
        this.loading = false;
      }
    },

    onHidden() {
      this.loading = false;
      this.templateTitle = "";
    },
  },
};
</script>
