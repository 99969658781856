export const getCSSVariable = (name) => {
  return window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(name);
};

export const setCSSVariable = (name, value) => {
  document.documentElement.style.setProperty(name, value);
};

export const removeCSSVariable = (name) => {
  document.documentElement.style.removeProperty(name);
};
