<template>
  <be-modal
    id="generate-suggestions-modal"
    :title="$t('components.meetings.suggestions.generate_suggestions.title')"
    :ok-title="
      $t(
        'components.meetings.suggestions.generate_suggestions.confirm_button_title'
      )
    "
    @ok="onOk"
    @hidden="onHidden"
  >
    <p>
      {{
        $t("components.meetings.suggestions.generate_suggestions.description")
      }}
    </p>

    <be-form-group
      :label="
        $t('components.meetings.suggestions.generate_suggestions.input_label')
      "
      label-for="suggestion-range"
    >
      <div class="d-flex justify-content-between align-items-center">
        <be-form-input
          id="suggestion-range"
          v-model="suggestionRange"
          type="range"
          min="1"
          :max="GENERATE_SUGGESTIONS_MAX_DAYS"
          step="1"
          class="d-inline-block"
        />

        <span class="ml-3 font-weight-semibold">{{ suggestionRange }}</span>
      </div>
    </be-form-group>
  </be-modal>
</template>

<script>
const GENERATE_SUGGESTIONS_MAX_DAYS = 5;
const GENERATE_SUGGESTIONS_DEFAULT_VALUE = 2;

export default {
  emits: ["generate-suggestions"],

  data() {
    return {
      GENERATE_SUGGESTIONS_MAX_DAYS,
      suggestionRange: GENERATE_SUGGESTIONS_DEFAULT_VALUE,
    };
  },

  methods: {
    onHidden() {
      this.suggestionRange = GENERATE_SUGGESTIONS_DEFAULT_VALUE;
    },

    onOk() {
      this.$emit("generate-suggestions", this.suggestionRange);
    },
  },
};
</script>
