<template>
  <div>
    <div id="budget-list"></div>

    <div id="budget-show"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      initialized: false,
    };
  },

  mounted() {
    this.waitForFinancials(this.initializeComponent);
  },

  methods: {
    initializeComponent() {
      if (this.initialized) {
        return;
      }

      this.initialized = true;

      window.wzGetController().loadGoogleAuth2Api(() => {
        window
          .wzGetController()
          .addBudgets("#budget-list", this.$activeFinancialsUuid, (select) => {
            document.querySelector("#budget-show").innerHTML = "";

            window
              .wzGetController()
              .addBudget(
                "#budget-show",
                this.$activeFinancialsUuid,
                select,
                () => {
                  // We can't use window.location.reload() because it will
                  // remove the current tab query parameter from the URL.
                  /* eslint-disable no-self-assign */
                  window.location.href = window.location.href;
                  /* eslint-enable no-self-assign */
                }
              );
          });
      });
    },
  },
};
</script>
