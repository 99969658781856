<template>
  <form
    ref="evaluation-form"
    novalidate="novalidate"
    :action="url(`/evaluation_forms/${evaluationForm.id}`)"
    accept-charset="UTF-8"
    method="POST"
  >
    <input name="utf8" type="hidden" value="✓" />

    <input type="hidden" name="authenticity_token" :value="$csrfToken" />

    <input id="locale" type="hidden" name="locale" value="sv" />

    <input id="_method" type="hidden" name="_method" value="PUT" />

    <input id="save_type" type="hidden" name="save_type" :value="saveType" />

    <div class="card mb-2">
      <div class="card-body">
        <div class="row align-items-end">
          <div class="col-12 col-lg-6">
            <be-form-group
              :label="$t('activerecord.attributes.evaluation_form.title')"
              label-for="evaluation-form-title"
              :error="getErrors(evaluationForm, ['title'])"
              class="mb-lg-0"
            >
              <be-form-input
                id="evaluation-form-title"
                :model-value="evaluationForm.title"
                name="evaluation_form[title]"
                required
                @change="clearErrors(evaluationForm, ['title'])"
                @input="update('title', $event)"
              />
            </be-form-group>
          </div>

          <div class="col-12 col-lg-auto">
            <be-form-group
              :label="$t('activerecord.attributes.evaluation_form.due_at')"
              label-for="due_at"
              class="mb-lg-0"
              required
            >
              <be-form-datepicker
                id="due_at"
                :model-value="evaluationForm.due_at"
                name="evaluation_form[due_at]"
                :clearable="false"
                @input="update('due_at', $event)"
              />
            </be-form-group>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-2 sticky-top">
      <div class="card-body">
        <div class="row">
          <div class="col-12 d-md-flex justify-content-end">
            <div>
              <be-button
                v-if="allExpanded"
                variant="outline-secondary"
                @click="collapseAll()"
              >
                {{ $t("buttons.toggle_all_selection.minimize_all") }}
              </be-button>

              <be-button
                v-else
                variant="outline-secondary"
                @click="expandAll()"
              >
                {{ $t("buttons.toggle_all_selection.expand_all") }}
              </be-button>

              <be-button
                type="submit"
                data-name="preview_evaluation_form_edit"
                variant="outline-secondary"
                :loading="loading"
                @click.prevent="preview"
              >
                {{ $t("buttons.titles.preview") }}
              </be-button>

              <be-button
                data-name="save"
                variant="outline-primary"
                :loading="loading"
                @click="saveForm()"
              >
                {{ $t("buttons.titles.save") }}
              </be-button>

              <be-button
                type="submit"
                variant="primary"
                data-name="save_and_send"
                :loading="loading"
                @click.prevent="saveAndContinue()"
              >
                {{ $t("buttons.titles.save_and_continue") }}
              </be-button>

              <be-dropdown ellipsis>
                <be-dropdown-item v-be-modal.save-as-template-modal>
                  {{
                    $t(
                      "components.evaluation_forms.editor.save_as_template.title"
                    )
                  }}
                </be-dropdown-item>
              </be-dropdown>

              <be-modal
                id="save-as-template-modal"
                :title="
                  $t(
                    'components.evaluation_forms.editor.save_as_template.title'
                  )
                "
                @ok="saveAsTemplate"
                @hidden="resetSaveAsTemplateModal"
              >
                <be-alert>
                  {{
                    $t(
                      "components.evaluation_forms.editor.save_as_template.description"
                    )
                  }}
                </be-alert>

                <be-form-group
                  :label="
                    $t(
                      'components.evaluation_forms.editor.save_as_template.input_label'
                    )
                  "
                  label-for="template-title"
                  :error="templateTitleError"
                  class="mb-0"
                >
                  <be-form-input
                    id="template-title"
                    v-model="templateTitle"
                    @input="templateTitleError = null"
                    @keydown.enter="saveAsTemplate"
                  />
                </be-form-group>

                <template #footer="{ cancel }">
                  <be-button variant="light" @click="cancel">
                    {{ $t("buttons.titles.cancel") }}
                  </be-button>

                  <be-button
                    variant="primary"
                    :disabled="!templateTitle"
                    :loading="savingTemplate"
                    @click="saveAsTemplate"
                  >
                    {{ $t("buttons.titles.save") }}
                  </be-button>
                </template>
              </be-modal>
            </div>
          </div>
        </div>
      </div>
    </div>

    <drop-list
      v-if="activeSections.length"
      :items="sections"
      class="list-group list-group-flush"
      accepts-type="section"
      column
      @reorder="updateSectionPositions"
    >
      <template #item="{ item: section, index }">
        <drag
          :key="`section-${section.id || section.uuid}`"
          type="section"
          handle=".section-draggable-handle"
          @dragstart="handleOnDragStart"
          @dragend="handleOnDragStop"
        >
          <evaluation-section
            :expanded="isSectionExpanded(section.id || section.uuid)"
            :component-indexes="[index]"
            :evaluation-form="evaluationForm"
            :loaded-section="section"
            :question-types="evaluationForm.question_types"
            :dragging="dragging"
            :expanded-question-ids="expandedQuestionIds"
            @toggle-expanded="toggleSectionExpanded(section.id || section.uuid)"
            @set-question-ids-expanded="setQuestionIdsExpanded"
            @section-removed="updateSectionPositions"
          />
        </drag>
      </template>

      <!-- This slot must be defined, even if it is empty -->
      <template #feedback></template>
    </drop-list>

    <div v-else class="card mb-2">
      <div class="card-body">
        <be-alert>
          <i18n-t
            keypath="components.evaluation_forms.editor.section.no_sections"
            tag="span"
          >
            <template #add_a_section>
              <be-link @click="addSection">
                {{ $t("components.evaluation_forms.editor.section.add_one") }}
              </be-link>
            </template>
          </i18n-t>
        </be-alert>
      </div>
    </div>

    <div v-show="!dragging" class="card mb-2">
      <div class="card-body">
        <div class="mb-3 mb-lg-0 d-flex justify-content-end">
          <be-dropdown
            split
            right
            variant="light"
            size="sm"
            @click="addSection"
          >
            <template #button-content>
              <i class="fas fa-plus mr-1" />
              {{ $t("views.companies.evaluation_forms.edit_form.add_section") }}
            </template>

            <be-dropdown-item v-be-modal.section-library>
              {{
                $t("components.evaluation_forms.editor.library.sections.choose")
              }}
            </be-dropdown-item>
          </be-dropdown>

          <!-- Section library modal -->
          <be-modal
            id="section-library"
            :title="
              $t('components.evaluation_forms.editor.library.sections.title')
            "
            :ok-title="$t('buttons.titles.select')"
            size="lg"
            :ok-disabled="evaluationForm.library_sections.length === 0"
            @ok="addSectionsFromLibrary"
          >
            <evaluation-library
              v-if="evaluationForm.library_sections.length > 0"
              :items="evaluationForm.library_sections"
              item-type="section"
              @library-item-chosen="selectLibrarySection"
            />

            <be-alert v-else variant="info" class="mb-0">
              {{ $t("components.evaluation_forms.editor.library.no_sections") }}
            </be-alert>
          </be-modal>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-footer">
        <div class="row align-items-center">
          <div class="col-12 col-lg d-md-flex justify-content-end">
            <div>
              <be-button
                type="submit"
                data-name="preview_evaluation_form_edit"
                variant="outline-secondary"
                :loading="loading"
                @click.prevent="preview"
              >
                {{ $t("buttons.titles.preview") }}
              </be-button>

              <be-button
                data-name="save"
                variant="outline-primary"
                :loading="loading"
                @click="saveForm()"
              >
                {{ $t("buttons.titles.save") }}
              </be-button>

              <be-button
                type="submit"
                variant="primary"
                data-name="save_and_send"
                :loading="loading"
                @click.prevent="saveAndContinue()"
              >
                {{ $t("buttons.titles.save_and_continue") }}
              </be-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import omit from "lodash/omit";
import transform from "lodash/transform";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import isObject from "lodash/isObject";

import { Drag, DropList } from "vue-easy-dnd";
import EvaluationSection from "./EvaluationSection.vue";
import EvaluationLibrary from "./EvaluationLibrary.vue";

export default {
  components: {
    EvaluationLibrary,
    EvaluationSection,
    Drag,
    DropList,
  },

  props: {
    initialEvaluationForm: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dragging: false,
      loading: false,
      newSections: [],
      saveType: "save",
      templateTitle: "",
      templateTitleError: null,
      savingTemplate: false,
      expandedQuestionIds: [],
    };
  },

  computed: {
    ...mapGetters({
      evaluationForm: "evaluation_forms/getEvaluationForm",
    }),

    allExpanded() {
      return this.allQuestionIds.every((questionId) =>
        this.expandedQuestionIds.includes(questionId)
      );
    },

    allQuestionIds() {
      return this.sections
        .map((section) => section.evaluation_questions)
        .flat()
        .map(
          (evaluation_question) =>
            evaluation_question.id || evaluation_question.uuid
        );
    },

    sections: {
      get() {
        return this.$store.getters["evaluation_forms/getSections"];
      },

      set(value) {
        this.$store.dispatch("evaluation_forms/updateSections", value);
      },
    },

    activeSections() {
      return this.sections.filter((section) => section._destroy != 1);
    },
  },

  created() {
    this.$store.dispatch(
      "evaluation_forms/setEvaluationForm",
      this.initialEvaluationForm
    );

    this.updateUuids();
    this.handleErrors(this.evaluationForm);
  },

  mounted() {
    if (this.evaluationForm.evaluation_sections.length == 1) {
      const firstSection = this.evaluationForm.evaluation_sections[0];

      this.$store.dispatch("evaluation_forms/updateSection", {
        sectionId: firstSection.id || firstSection.uuid,
        data: { position: 1 },
      });
    }
  },

  methods: {
    handleOnDragStart(e) {
      this.dragging = true;

      this.$nextTick(() => {
        e.native.target.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      });
    },

    handleOnDragStop(e) {
      this.dragging = false;

      this.$nextTick(() => {
        e.native.target.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      });
    },

    questionIdsForSectionId(sectionId) {
      const section = this.sections.find(
        (section) => section.id === sectionId || section.uuid === sectionId
      );

      return section.evaluation_questions.map(
        (evaluation_question) =>
          evaluation_question.id || evaluation_question.uuid
      );
    },

    setQuestionIdsExpanded({ questionIds, expanded }) {
      const withoutQuestionIds = this.expandedQuestionIds.filter(
        (questionId) => !questionIds.includes(questionId)
      );

      const newExpandedQuestionIds = expanded
        ? [...withoutQuestionIds, ...questionIds]
        : [...withoutQuestionIds];

      this.expandedQuestionIds = newExpandedQuestionIds;
    },

    isSectionExpanded(sectionId) {
      const questionIdsForSection = this.questionIdsForSectionId(sectionId);
      return questionIdsForSection.every((questionId) =>
        this.expandedQuestionIds.includes(questionId)
      );
    },

    toggleSectionExpanded(sectionId) {
      if (this.isSectionExpanded(sectionId)) {
        this.setQuestionIdsExpanded({
          questionIds: this.questionIdsForSectionId(sectionId),
          expanded: false,
        });
      } else {
        this.setQuestionIdsExpanded({
          questionIds: this.questionIdsForSectionId(sectionId),
          expanded: true,
        });
      }
    },

    expandAll() {
      this.expandedQuestionIds = this.allQuestionIds;
    },

    collapseAll() {
      this.expandedQuestionIds = [];
    },

    addSection(sectionData = {}) {
      const firstQuestionUuid = this.generateUuid();

      const newSection = {
        ...{
          uuid: Math.random().toString(16).slice(2),
          _destroy: 0,
          position: this.activeSections.length + 1,
          title: "",

          evaluation_questions: [
            {
              uuid: firstQuestionUuid,
              title: "",
              question_type: "open",
              _destroy: 0,
              section_position: 1,
              possible_answers: [],
            },
          ],
        },

        ...sectionData,
      };

      this.$store.dispatch(
        "evaluation_forms/addSection",
        omit(newSection, "id")
      );

      this.setQuestionIdsExpanded({
        questionIds: [firstQuestionUuid],
        expanded: true,
      });
    },

    addSectionsFromLibrary() {
      this.newSections.forEach((newSection) => {
        this.addSection(newSection);
      });

      this.newSections = [];
    },

    handleErrors(evaluationForm) {
      this.$store.dispatch(
        "evaluation_forms/setEvaluationForm",
        evaluationForm
      );
      this.updateUuids();

      // Check if sections contain questions with errors,
      // if it does, open the questions with errors
      this.activeSections.forEach((section) => {
        section.evaluation_questions.forEach((question) => {
          // Find answers with errors
          const answersWithErrors = question.possible_answers.filter(
            (answer) => {
              answer.errors && Object.keys(answer.errors).length > 0;
            }
          );

          if (
            (question.errors && Object.keys(question.errors).length > 0) ||
            answersWithErrors.length > 0
          ) {
            this.setQuestionIdsExpanded({
              questionIds: [question.id || question.uuid],
              expanded: true,
            });
          }
        });
      });
    },

    postToServer() {
      let replaceKeysDeep = (obj) => {
        return transform(obj, function (result, value, key) {
          var currentKey = key;
          if (isArray(value) && !isNumber(key)) {
            currentKey = key + "_attributes";
            if (isEmpty(value)) {
              return;
            }
          }
          result[currentKey] =
            isObject(value) && currentKey !== "due_at"
              ? replaceKeysDeep(value)
              : value;
        });
      };

      let data = replaceKeysDeep(this.cloneDeep(this.evaluationForm));

      return axios.patch(
        this.url(`/evaluation_forms/${this.evaluationForm.id}`),
        { evaluation_form: data }
      );
    },

    preview() {
      this.saveType = "preview_evaluation_form_edit";

      this.loading = true;

      this.$nextTick(() => {
        this.$refs["evaluation-form"].submit();
      });
    },

    async saveAsTemplate() {
      if (!this.templateTitle) {
        this.templateTitleError = this.$t("activemodel.errors.messages.blank");
        return;
      }

      this.savingTemplate = true;

      try {
        await axios.post(
          this.url(
            `/evaluation_forms/${this.evaluationForm.id}/evaluation_form_to_templates`
          ),
          {
            evaluation_form: {
              template_title: this.templateTitle,
            },
          }
        );

        this.$beModal.hide("save-as-template-modal");
      } catch (error) {
        this.handleError(error);
      } finally {
        this.savingTemplate = false;
      }
    },

    saveAndContinue() {
      this.saveType = "save_and_send";

      this.$nextTick(() => {
        this.loading = true;
        this.$refs["evaluation-form"].submit();
      });
    },

    saveForm() {
      this.loading = true;

      this.postToServer()
        .then((response) => {
          this.$store.dispatch(
            "evaluation_forms/setEvaluationForm",
            response.data
          );
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.handleErrors(error.response.data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    selectLibrarySection(newSection) {
      let idx = this.newSections.findIndex(
        (section) => section.id == newSection.id
      );

      if (idx > -1) {
        this.newSections.splice(idx, 1);
      } else {
        this.newSections.push({
          id: newSection.id,
          title: newSection.title,
        });
      }
    },

    toggleSections() {
      this.expandedSectionIds = this.activeSections.map(
        (section) => section.id || section.uuid
      );
    },

    update(key, value) {
      this.$store.dispatch("evaluation_forms/updateForm", {
        data: { [key]: value },
      });
    },

    updateSectionPositions(event) {
      // Update the DropList data
      if (event) {
        event.apply(this.sections);
      }

      this.$store.dispatch("evaluation_forms/updateSectionPositions");
    },

    updateUuids() {
      this.$store.dispatch("evaluation_forms/updateUuids");
    },

    resetSaveAsTemplateModal() {
      this.templateTitle = "";
      this.templateTitleError = null;
      this.savingTemplate = false;
    },
  },
};
</script>
