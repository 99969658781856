<template>
  <be-progress
    v-if="totalAmount < grantedValue && totalAmount !== 0"
    height="2rem"
    class="mb-3"
    v-bind="$attrs"
  >
    <be-progress-bar :value="totalAmount" :max="grantedValue">
      <strong>
        <money-format :currency="currency" :value="totalAmount" />
        /
        <money-format :currency="currency" :value="grantedValue" />
      </strong>
    </be-progress-bar>
  </be-progress>

  <be-alert v-if="totalAmount === grantedValue" variant="success">
    {{ $t("views.companies.inquiry_payments.index.inquiry_fully_planned") }}
  </be-alert>

  <be-alert v-if="totalAmount === 0" variant="info">
    {{ $t("views.companies.inquiry_payments.index.no_payments") }}
  </be-alert>
</template>

<script>
export default {
  props: {
    totalAmount: {
      type: Number,
      required: true,
    },

    grantedValue: {
      type: Number,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },
  },
};
</script>
