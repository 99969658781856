<template>
  <be-modal
    id="material-pdf"
    :title="modalTitle"
    size="lg"
    ok-only
    ok-variant="light"
    :ok-title="$t('buttons.titles.close')"
    @show="modalOpened"
    @hide="stopPolling"
  >
    <be-alert v-if="version" variant="info">
      {{ $t("components.meetings.material.pdf_modal.version_info") }}
    </be-alert>

    <be-alert v-if="exportIsOutdated" variant="warning">
      {{ $t("components.meetings.material.pdf_modal.outdated_info") }}
    </be-alert>

    <div
      class="d-flex flex-column flex-lg-row justify-content-between align-items-center rounded p-3 mb-2 bg-light"
    >
      <template v-if="loading || exportInProgress">
        <div class="d-flex flex-grow-1 align-items-center">
          <be-spinner>
            {{
              exportInProgress
                ? $t("components.meetings.material.pdf_modal.creating_new")
                : $t("buttons.states.loading")
            }}
          </be-spinner>
        </div>
      </template>

      <template v-else-if="documentExport">
        <template v-if="documentExport.status === 'failed'">
          <div class="d-flex align-items-center text-danger">
            <i class="fas fa-hexagon-exclamation fa-xl ml-1 mr-2" />
            {{ $t("components.shared.export_modal.export_failed") }}
          </div>

          <be-button variant="primary" @click="requestDocument">
            {{ $t("components.meetings.material.pdf_modal.create_new") }}
          </be-button>
        </template>

        <template v-else>
          <div class="d-flex align-items-center">
            <i class="fal fa-file-pdf fa-xl text-muted ml-1 mr-2" />

            <span class="text-break">{{ documentFilename }}</span>
          </div>

          <be-button
            v-if="documentUrl"
            :href="documentUrl"
            variant="primary"
            class="text-nowrap"
            icon="fa-arrow-down-to-bracket"
            @click="markAsRead"
          >
            {{ $t("buttons.titles.download") }}
          </be-button>
        </template>
      </template>
    </div>

    <div
      v-if="documentExport && !loading && !exportInProgress"
      class="d-flex align-items-center pl-1 text-muted"
    >
      <template v-if="isExport">
        {{
          $t("components.meetings.material.pdf_modal.created_at_w_time", {
            time: updatedAt,
          })
        }}.

        <be-button
          variant="link"
          class="p-0 ml-1 text-reset-line-height"
          @click="requestPdf"
        >
          {{ `${$t("components.meetings.material.pdf_modal.create_new")}?` }}
        </be-button>
      </template>

      <template v-else-if="material.material_type === 'agenda'">
        {{
          $t(
            "components.meetings.material.pdf_modal.agenda_marked_as_done_w_time",
            {
              time: updatedAt,
            }
          )
        }}.
      </template>

      <template v-else>
        {{
          $t(
            "components.meetings.material.pdf_modal.minutes_marked_as_done_w_time",
            {
              time: updatedAt,
            }
          )
        }}.
      </template>
    </div>
  </be-modal>
</template>

<script>
export default {
  props: {
    meeting: {
      type: Object,
      required: true,
    },

    material: {
      type: Object,
      required: true,
    },

    version: {
      type: String,
      required: false,
      default: null,
    },

    dataLastUpdatedAt: {
      type: [Date, String],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      documentExport: {},
      interval: null,
      loading: false,
    };
  },

  computed: {
    exportInProgress() {
      return ["pending", "processing"].includes(this.documentExport?.status);
    },

    documentFilename() {
      return this.documentExport?.filename;
    },

    documentUrl() {
      if (this.documentExport) {
        return (
          this.documentExport.attachment_url || this.documentExport.pdf_url
        );
      } else {
        return null;
      }
    },

    modalTitle() {
      if (this.material.material_type === "agenda") {
        return this.$t("buttons.titles.export_w_title", {
          title: this.$t("activerecord.models.agenda.one").toLowerCase(),
        });
      }

      return this.$t("buttons.titles.export_w_title", {
        title: this.$t("activerecord.models.minutes.one").toLowerCase(),
      });
    },

    updatedAt() {
      let date = this.documentExport?.updated_at;
      if (this.isExport) {
        date = this.documentExport?.created_at;
      }

      if (date) {
        date = new Date(date);
        return this.$d(date, this.dateFormatIfSameDay(date));
      }

      return null;
    },

    exportIsOutdated() {
      const dataUpdatedAt = new Date(this.dataLastUpdatedAt);

      if (dataUpdatedAt && this.documentExport) {
        const exportUpdatedAt = new Date(this.documentExport.updated_at);
        return exportUpdatedAt < dataUpdatedAt;
      }

      return false;
    },

    isExport() {
      return "export_type" in this.documentExport;
    },
  },

  watch: {
    exportInProgress() {
      if (this.exportInProgress) {
        this.startPolling();
      } else {
        this.stopPolling();
      }
    },
  },

  beforeUnmount() {
    this.stopPolling();
  },

  methods: {
    async requestPdf() {
      try {
        const { data } = await axios.patch(
          `${this.meeting.paths.base}/materials/${this.material.id}/pdf`
        );
        this.documentExport = data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async fetchPdf() {
      try {
        const { data } = await axios.get(
          `${this.meeting.paths.base}/materials/${this.material.id}/pdf`
        );

        this.documentExport = data;
      } catch (error) {
        if (error.response.status !== 404) {
          this.handleError(error);
        }

        this.documentExport = null;
      }
    },

    startPolling() {
      if (this.interval == null) {
        this.interval = setInterval(this.fetchPdf, 3000);
      }
    },

    stopPolling() {
      clearInterval(this.interval);
      this.interval = null;
    },

    resetPolling() {
      this.stopPolling();
      this.startPolling();
    },

    async modalOpened() {
      this.loading = true;

      await this.fetchPdf();

      if (!this.documentExport) {
        await this.requestPdf();
      }

      this.loading = false;

      if (this.exportInProgress) {
        this.startPolling();
      }
    },

    async markAsRead() {
      // Nothing to do for exports
      if (this.isExport) {
        return;
      }

      try {
        await axios.post(
          this.url(`/documents/${this.documentExport.id}/readings`)
        );
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
