import { createApp } from "vue";
import BaseApp from "./BaseApp.vue";

const app = createApp(BaseApp);
/**
 * Load BigNumber for rounding and decimal placing of calculations where floats
 * are involved.
 */
import { BigNumber } from "bignumber.js";
window.BigNumber = BigNumber;

import "bootstrap";

/**
 * Load Rails Unobtrusive JavaScript (Rails UJS) in order to handle links
 * with attached data-methods.
 */

import "@/rails-ujs";
import "@/simple-form-helpers";

/**
 * Load Core2, a modular standard library for JavaScript. Includes polyfills
 * for ECMAScript up to 2019: promises, symbols, collections, iterators, typed
 * arrays, many other features, ECMAScript proposals, some cross-platform
 * WHATWG / W3C features and proposals like URL.
 */

import "core-js";

/**
 * Then we'll load Vue as the frontend Javascript framework,
 * as well as VueX for global state management
 */

import ActionCableVue from "actioncable-vue";

app.use(ActionCableVue, {
  connectionUrl: "/cable",
  connectImmediately: false,
});

/**
 * Load all self-defined mixins
 */
import CloneDeep from "./mixins/cloneDeep";
import Forms from "./mixins/forms";
import ImageHelper from "./mixins/image";
import Platform from "./mixins/platform";
import Translations from "./mixins/translations";
import Url from "./mixins/url";
import Users from "./mixins/users";
import Uuid from "./mixins/uuid";

app.mixin(CloneDeep);
app.mixin(Forms);
app.mixin(ImageHelper);
app.mixin(Platform);
app.mixin(Translations);
app.mixin(Url);
app.mixin(Users);
app.mixin(Uuid);

/**
 * Add ability to use `v-autofocus` to focus an element on load
 * (e.g. on page load or in a modal)
 */
import autofocus from "./directives/autofocus";
app.directive("autofocus", autofocus);

/**
 * Add ability to use `v-click-outside` to detect clicks outside of an element
 */
import clickOutside from "./directives/click-outside";
app.directive("click-outside", clickOutside);

/**
 * Add ability to use `v-be-hover` to detect hover events on an element
 */
import hover from "./directives/hover";
app.directive("be-hover", hover);

/**
 * Add ability to use `v-be-popover` to add a popover to an element
 */
import popover from "./directives/popover";
app.directive("be-popover", popover);

/**
 * Add toggle directive to handle toggling of BeCollapse components
 */
import toggle from "./directives/toggle";
app.directive("be-toggle", toggle);

/*
 * Add ability to use `v-be-tooltip` to add a tooltip to an element
 */
import tooltip from "./directives/tooltip";
app.directive("be-tooltip", tooltip);

/*
 * Add ability to use `v-be-visible` to check if an element is visible in the viewport
 */
import visible from "./directives/visible";
app.directive("be-visible", visible);

/*
 * Add ability to use `v-be-drawer` to open and close drawers
 */
import drawer from "./directives/drawer";
app.directive("be-drawer", drawer);

/**
 * Add modal directive to handle opening and closing of modals
 */
import modal from "./directives/modal";
app.directive("be-modal", modal);

/**
 * `$beModal` is a global helper that allows us to open and close modals
 */
import BeModal from "./helpers/be-modal";
app.mixin({
  beforeCreate() {
    if (!this.$beModal) {
      this.$beModal = new BeModal(this);
    }
  },
});

/**
 * Add confirm plugin to handle confirm modals with `$confirm` and
 * confirm mixin handler with `promptConfirm` and `promptRemovalConfirm`
 */
import ConfirmPlugin from "./helpers/confirm";
import ConfirmHandler from "./mixins/ConfirmHandler";
app.use(ConfirmPlugin);
app.mixin(ConfirmHandler);

/**
 * Add ability to use `v-dompurify-html` to sanitize HTML
 */
import VueDOMPurifyHTML from "vue-dompurify-html";
app.use(VueDOMPurifyHTML, {
  hooks: {
    // https://github.com/LeSuisse/vue-dompurify-html/issues/1258#issuecomment-905232305
    // https://github.com/cure53/DOMPurify/issues/317#issuecomment-698800327
    afterSanitizeAttributes: function (node) {
      if ("target" in node) {
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noopener");
      }
    },
  },
});

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import { axiosFlashInterceptor } from "@/axios-flash-interceptor";

import axios from "axios";
window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.interceptors.response.use(
  (response) => {
    axiosFlashInterceptor(response);

    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    axiosFlashInterceptor(error.response);

    return Promise.reject(error);
  }
);

/**
 * Load error handling utilities
 */
import { handleError } from "./utils/error-handling";
app.mixin({
  methods: {
    handleError,
  },
});

export { app };
