export default {
  computed: {
    hasLoadedMaterials() {
      return (
        this.agenda?.status == "loaded" && this.minutes?.status == "loaded"
      );
    },
  },

  methods: {
    getMinutes(meeting) {
      const minutes = this.$store.getters["meetings/getMinutes"](meeting);
      if (minutes) {
        return minutes;
      } else {
        return {};
      }
    },

    getMinutesStatus(meeting) {
      const minutes = this.$store.getters["meetings/getMinutes"](meeting);
      return minutes?.status || "loading";
    },

    getAgenda(meeting) {
      const agenda = this.$store.getters["meetings/getAgenda"](meeting);
      if (agenda) {
        return agenda;
      } else {
        return {};
      }
    },

    getAgendaStatus(meeting) {
      const agenda = this.$store.getters["meetings/getAgenda"](meeting);
      return agenda?.status || "loading";
    },

    getAttendances(meeting) {
      return this.$store.getters["meetings/getAttendancesForMeeting"](
        meeting.id
      );
    },

    getAttendanceStatus(meeting) {
      return this.$store.getters["meetings/attendanceStatusForMeeting"](
        meeting.id
      );
    },

    getFolder(meeting) {
      const folder = this.$store.getters["meetings/folderForMeeting"](
        meeting.id
      );

      if (folder) {
        return folder;
      } else {
        return {};
      }
    },

    getFolderStatus(meeting) {
      return this.$store.getters["meetings/folderStatusForMeeting"](meeting.id);
    },

    getInvitations(meeting) {
      return this.$store.getters["meetings/getInvitationsForMeeting"](
        meeting.id
      );
    },

    getInvitationStatus(meeting) {
      return this.$store.getters["meetings/invitationStatusForMeeting"](
        meeting.id
      );
    },

    getCommentsRequests(meeting) {
      return this.$store.getters["meetings/getCommentsRequestsForMeeting"](
        meeting.id
      );
    },

    getCommentsRequestStatus(meeting) {
      return this.$store.getters["meetings/commentsRequestStatusForMeeting"](
        meeting.id
      );
    },

    async fetchMinutesForMeeting(meeting, force = false) {
      if (force || !this.getMinutes(meeting)?.status) {
        await this.$store.dispatch("meetings/fetchMinutes", meeting);
      }
    },

    async fetchAgendaForMeeting(meeting) {
      if (!this.getAgenda(meeting)?.status) {
        await this.$store.dispatch("meetings/fetchAgenda", meeting);
      }
    },

    async fetchFolderForMeeting(meeting) {
      if (
        meeting.policy &&
        meeting.policy.show_overview &&
        !this.getFolder(meeting)?.status
      ) {
        await this.$store.dispatch("meetings/fetchFolderForMeeting", meeting);
      }
    },

    async fetchAttendancesForMeeting(meeting, options = {}) {
      if (options?.reload || this.getAttendanceStatus(meeting) != "loaded") {
        await this.$store.dispatch(
          "meetings/fetchAttendancesForMeeting",
          meeting
        );
      }
    },

    async fetchInvitationsForMeeting(meeting) {
      if (this.getInvitationStatus(meeting) != "loaded") {
        await this.$store.dispatch(
          "meetings/fetchInvitationsForMeeting",
          meeting
        );
      }
    },

    async fetchCommentsRequestsForMeeting(meeting, options = {}) {
      if (
        options?.reload ||
        this.getCommentsRequestStatus(meeting) != "loaded"
      ) {
        await this.$store.dispatch(
          "meetings/fetchCommentsRequestsForMeeting",
          meeting
        );
      }
    },

    attendanceRole(attendance) {
      const roles = [];
      if (attendance?.chairman) {
        roles.push(this.$t("activerecord.attributes.attendance.chairman"));
      }
      if (attendance?.secretary) {
        roles.push(this.$t("activerecord.attributes.attendance.secretary"));
      }
      if (attendance?.reviewer) {
        roles.push(this.$t("activerecord.attributes.attendance.reviewer"));
      }

      if (attendance && attendance.user_id == null) {
        roles.push(this.$t("models.attendance.external"));
        roles.push(attendance.email);
      }
      return roles.join(" • ");
    },

    reviewLevelClasses(reviewLevel) {
      if (reviewLevel === "request_changes") {
        return "fa fa-times text-danger ";
      } else if (reviewLevel === "approve") {
        return "fa fa-check text-success";
      }

      return null;
    },
  },
};
