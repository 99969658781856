import { handleError } from "@/utils/error-handling";

export const errorLoggerCallback = (
  errorTitle: string,
  errorMessage: string,
  extraInfo?: string,
  url = window.location.href
): void => {
  const message = `
    Title: fortnoxWebAnalytics:${errorTitle}
    ErrorLog: ${errorMessage}
    ${extraInfo ? `ExtraInfo: ${extraInfo}  ` : ""}
    URL: ${url}
  }`;

  handleError(new Error(message));
};
