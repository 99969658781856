<template>
  <div class="report-view">
    <template
      v-for="element in rootElements"
      :key="`view-element-${element.id}`"
    >
      <component
        :is="reportElementComponent(element.component_type)"
        :element="element"
        :elements-by-parent="elementsByParent"
        :report="report"
        :force-show="forceShow"
        :mode="mode"
      />
    </template>

    <div v-if="autoRead" v-be-visible="observeVisibilityOptions" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";
import sortBy from "lodash/sortBy";
import { computed } from "vue";
import { googleFonts, loadGoogleFont } from "@/helpers/fonts";
import { getCSSVariable, setCSSVariable, removeCSSVariable } from "@/utils/css";

import DecisionsElement from "./elements/DecisionsElementViewer.vue";
import FinancialElement from "./elements/FinancialElementViewer.vue";
import ImageElement from "./elements/ImageElementViewer.vue";
import PageBreakElement from "./elements/PageBreakElementViewer.vue";
import ReportColumnsElement from "./elements/ColumnsElementViewer.vue";
import SpaceElement from "./elements/SpaceElementViewer.vue";
import TasksElement from "./elements/TasksElementViewer.vue";
import TextElement from "./elements/TextElementViewer.vue";
import TiptapTextElement from "./elements/TiptapTextElementViewer.vue";
import TiptapTitleElement from "./elements/TiptapTitleElementViewer.vue";
import TitleElement from "./elements/TitleElementViewer.vue";

import ReportMixin from "@/mixins/reports";

const AUTO_READ_AFTER_SECONDS = 1 * 1000; // 1 second

export default {
  components: {
    DecisionsElement,
    FinancialElement,
    ImageElement,
    PageBreakElement,
    ReportColumnsElement,
    SpaceElement,
    TasksElement,
    TextElement,
    TiptapTextElement,
    TiptapTitleElement,
    TitleElement,
  },

  mixins: [ReportMixin],

  provide() {
    return {
      accentColor: computed(() => this.report.accent_color),
    };
  },

  props: {
    report: {
      type: Object,
      required: true,
    },

    mode: {
      type: String,
      required: false,
      default: "report",
    },

    autoRead: {
      type: Boolean,
      required: false,
      default: false,
    },

    forceShow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["reading-updated"],

  data() {
    return {
      read: false,

      observeVisibilityOptions: {
        callback: this.visibilityChanged,
        once: true,
        throttle: AUTO_READ_AFTER_SECONDS,
      },
    };
  },

  computed: {
    elementsByParent() {
      const elements_by_parent = {};

      const elements = this.report.report_components || [];

      elements.forEach((element) => {
        const parent_id = element.parent_component_id || "root";
        if (elements_by_parent[parent_id] === undefined) {
          elements_by_parent[parent_id] = [];
        }

        elements_by_parent[parent_id].push(element);
      });

      for (const key of Object.keys(elements_by_parent)) {
        elements_by_parent[key] = sortBy(elements_by_parent[key], "position");
      }

      return elements_by_parent;
    },

    rootElements() {
      return this.elementsByParent["root"] || [];
    },
  },

  watch: {
    "report.accent_color": {
      handler(value) {
        if (value) {
          setCSSVariable("--report-accent-color", value);
        } else {
          removeCSSVariable("--report-accent-color");
        }
      },
    },

    "report.font_family": {
      handler(value) {
        this.setFontCSSVariables(value);
      },
    },
  },

  async created() {
    this.visualbyReportFinancialUuid(
      this.report.report_type,
      this.report.corporate_group_data
    );
  },

  mounted() {
    if (this.mode !== "template") {
      EventBus.on("LOADED_BI", () => {
        if (!this.report.allow_drill_down) {
          this.visualbyReportSetFeature("drill-down", false);
        }
      });
    }

    // Set accent color
    setCSSVariable("--report-accent-color", this.report.accent_color);

    this.$nextTick(() => {
      // Set font family
      if (this.report.font_family) {
        this.setFontCSSVariables(this.report.font_family);
      }
    });
  },

  methods: {
    ...mapActions({
      createReading: "readings/createReading",
    }),

    async visibilityChanged(isVisible) {
      if (this.autoRead && !this.read) {
        if (isVisible) {
          this.read = true;

          const reading = await this.createReading(this.report);

          if (reading) {
            this.$emit("reading-updated", reading);
          }
        }
      }
    },

    setFontCSSVariables(font) {
      const defaultFontFamily = getCSSVariable("--font-family-sans-serif");
      const defaultHeadingsFontWeight = getCSSVariable(
        "--headings-font-weight"
      );

      if (font) {
        if (googleFonts.includes(font)) {
          loadGoogleFont(font);
        }

        setCSSVariable("--report-font-family", `${font}, ${defaultFontFamily}`);
        setCSSVariable(
          "--report-header-font-weight",
          font === "Easer Grotesk Web" ? 500 : 600
        );
      } else {
        setCSSVariable("--report-font-family", defaultFontFamily);
        setCSSVariable(
          "--report-header-font-weight",
          defaultHeadingsFontWeight
        );
      }
    },
  },
};
</script>
