import axios from "axios";
import { url } from "@/url-helpers";
import { handleError } from "@/utils/error-handling";
import generateUuid from "@/utils/uuid";
import cloneDeep from "lodash/cloneDeep";

const defaultInquiryForm = {
  inquiry: {
    currency: "SEK",

    inquiry_receiver: {
      name: "",
      receiver_identifier: "",
    },
  },

  document: {},

  payment: {
    pay_at: "",
    amount: 0,
    reference: "",
    reference_type: "ocr",

    inquiry_receiver: {},
  },
};

export const state = {
  inquiries: [],
  inquiryPayments: [],
  inquiry: null,
  hasLoadedInquiries: false,
  isLoadingInquiries: false,
  hasLoadedInquiryPayments: false,
  isLoadingInquiryPayments: false,

  newInquiryForm: cloneDeep(defaultInquiryForm),
};

export const getters = {
  getInquiries: (state) => (filters) => {
    const {
      status,
      paymentState,
      source,
      remainingToPay,
      syncedToPe,
      requisition,
    } = filters;

    let inquiries = [...state.inquiries];

    if (status) {
      inquiries = helpers.inquiriesByStatus(inquiries)(status);
    }

    if (paymentState) {
      inquiries = helpers.inquiriesByPaymentState(inquiries)(paymentState);
    }

    if (source) {
      inquiries = inquiries.filter((inquiry) => inquiry.source === source);
    }

    if (remainingToPay) {
      inquiries = inquiries.filter((inquiry) => {
        return inquiry.remaining_to_pay > 0;
      });
    }

    if (typeof syncedToPe === "boolean") {
      inquiries = inquiries.filter((inquiry) => {
        return inquiry.synced_to_pe === syncedToPe;
      });
    }

    if (typeof requisition === "boolean") {
      inquiries = inquiries.filter((inquiry) => {
        return inquiry.requisition === requisition;
      });
    }

    return inquiries;
  },

  getInquiryPayments: (state) => (filters) => {
    const { paymentState } = filters;

    let inquiryPayments = [...state.inquiryPayments];

    if (paymentState) {
      inquiryPayments =
        helpers.inquiryPaymentsByPaymentState(inquiryPayments)(paymentState);
    }

    return inquiryPayments;
  },

  getInquiriesWithItems: (state) => (filters) => {
    const { status, meeting } = filters;

    let inquiries = helpers.extractLatestInquiryItems([...state.inquiries]);

    if (status) {
      inquiries = helpers.inquiriesByStatusFromItem(inquiries)(status);
    }

    if (meeting) {
      inquiries = helpers.inquiriesForMeeting(inquiries)(meeting);
    }

    return inquiries;
  },

  getInquiry: (state) => state.inquiry,

  getSummary: (state) => (filters) => {
    const inquiries = getters.getInquiriesWithItems(state)(filters);
    return helpers.calculateSummary(inquiries);
  },

  getCurrencies: (state) => (filters) => {
    const inquiries = getters.getInquiriesWithItems(state)(filters);
    return helpers.getUniqueCurrencies(inquiries);
  },

  hasLoadedInquiries: (state) => state.hasLoadedInquiries,
  hasLoadedInquiryPayments: (state) => state.hasLoadedInquiryPayments,
  isLoadingInquiries: (state) => state.isLoadingInquiries,
  isLoadingInquiryPayments: (state) => state.isLoadingInquiryPayments,
  getNewInquiryForm: (state) => state.newInquiryForm,
};

export const actions = {
  async loadInquiry({ commit }, id) {
    if (!id) {
      return;
    }

    try {
      const { data: inquiry } = await axios.get(url(`/inquiries/${id}`));

      commit("setInquiry", inquiry);

      return inquiry;
    } catch (error) {
      handleError(error);
    }
  },

  async loadInquiries({ commit, state }) {
    if (state.isLoadingInquiries || state.hasLoadedInquiries) {
      return;
    }

    commit("setIsLoadingInquiries", true);

    try {
      const { data: inquiries } = await axios.get(url("/inquiries"));

      commit("setInquiries", inquiries);
      commit("setHasLoadedInquiries", true);
    } catch (error) {
      handleError(error);
    } finally {
      commit("setIsLoadingInquiries", false);
    }
  },

  async loadInquiryPayments({ commit, state }) {
    if (state.isLoadingInquiryPayments) {
      return;
    }

    commit("setIsLoadingInquiryPayments", true);

    try {
      const { data: inquiryPayments } = await axios.get(
        url("/inquiry_payments")
      );

      commit("setInquiryPayments", inquiryPayments);
      commit("setHasLoadedInquiryPayments", true);
    } catch (error) {
      handleError(error);
    } finally {
      commit("setIsLoadingInquiryPayments", false);
    }
  },

  async loadInquiriesAndGenerateMissingInquiryItems({ commit }, params) {
    commit("setIsLoadingInquiries", true);

    try {
      const { data: inquiries } = await axios.get(url("/inquiries"));

      commit("setInquiries", inquiries);
      commit("setHasLoadedInquiries", true);
      commit("generateMissingInquiryItems", params);
    } catch (error) {
      handleError(error);
    } finally {
      commit("setIsLoadingInquiries", false);
    }
  },

  async updateInquiry({ commit, state }) {
    if (!state.newInquiryForm) {
      return;
    }

    const inquiry = state.newInquiryForm.inquiry;
    const document = state.newInquiryForm.document;

    try {
      const response = await axios.patch(url(`/inquiries/${inquiry.id}`), {
        inquiry,
        document,
      });

      commit("setInquiry", response.data);

      return response;
    } catch (error) {
      handleError(error);
      commit("setNewInquiryFormErrors", error.response.data.errors);

      return error;
    }
  },

  async updateInquiryItem({ commit }, { url, inquiryItem }) {
    try {
      const { data } = await axios.patch(url, {
        item_id: inquiryItem.item_id,
        inquiry_id: inquiryItem.inquiry_id,
        inquiry_item: inquiryItem,
        meeting_id: inquiryItem.meeting_id,
      });

      commit("setInquiryItem", {
        ...data,
        meeting_id: inquiryItem.meeting_id,
        temp_id: inquiryItem.temp_id,
      });

      return data;
    } catch (error) {
      handleError(error);
    }
  },

  async deleteInquiry({ id, delete_comment }) {
    try {
      const { data } = await axios.delete(url(`/inquiries/${id}`), {
        data: {
          inquiry: {
            delete_comment: delete_comment,
          },
        },
      });

      return data;
    } catch (error) {
      handleError(error);
    }
  },

  async removeInquiryItem({ commit }, { url, inquiryItem }) {
    try {
      const { data } = await axios.delete(url, {
        data: {
          item_id: inquiryItem.item_id,
          inquiry_id: inquiryItem.inquiry_id,
        },
      });

      commit("removeInquiryItem", {
        ...data,
        meeting_id: inquiryItem.meeting_id,
        temp_id: inquiryItem.temp_id,
      });
    } catch (error) {
      handleError(error);
    }
  },

  setNewInquiryForm({ commit, state }, form) {
    if (state.newInquiryForm) {
      commit("resetNewInquiryForm");
    }

    commit("setNewInquiryForm", form);
  },

  async createInquiry({ commit, state }) {
    if (!state.newInquiryForm) {
      return;
    }

    const inquiry = state.newInquiryForm.inquiry;
    const document = state.newInquiryForm.document;

    try {
      const response = await axios.post(url("/inquiries"), {
        inquiry,
        document,
      });

      commit("resetNewInquiryForm");

      return response;
    } catch (error) {
      handleError(error);
    }
  },

  async validateInquiry({ commit }, data) {
    if (!data) {
      return;
    }

    try {
      const response = await axios.post(url("/inquiries/validations"), {
        inquiry: data,
      });

      return response;
    } catch (error) {
      if (error && error.response?.status === 422) {
        commit("setNewInquiryFormErrors", error.response.data.errors);
      }

      handleError(error);
    }
  },

  async validateInquiryPayment({ commit }, data) {
    async function callValidation(data) {
      if (typeof data.id !== "undefined") {
        return await axios.patch(
          url(
            `/inquiries/${data.inquiry_id}/inquiry_payments/validations/${data.id}`
          ),
          {
            inquiry_payment: data,
          }
        );
      }

      return await axios.post(
        url(`/inquiries/${data.inquiry_id}/inquiry_payments/validations`),
        {
          inquiry_payment: data,
        }
      );
    }

    if (!data) {
      return;
    }
    try {
      const response = await callValidation(data);

      return response;
    } catch (error) {
      if (error && error.response?.status === 422) {
        commit("setNewInquiryPaymentsFormErrors", error.response.data.errors);
      }
      handleError(error);
      return error.response;
    }
  },
};

export const mutations = {
  setInquiries(state, inquiries) {
    state.inquiries = inquiries;
  },

  setInquiryPayments(state, inquiryPayments) {
    state.inquiryPayments = inquiryPayments;
  },

  setInquiry(state, inquiry) {
    state.inquiry = inquiry;
  },

  setInquiryItem(state, inquiryItem) {
    const inquiry = state.inquiries.find(
      (inquiry) => inquiry.id === inquiryItem.inquiry_id
    );

    if (inquiry) {
      const index = inquiry.inquiry_items.findIndex(
        (item) =>
          item.id === inquiryItem.id || item.temp_id === inquiryItem.temp_id
      );

      if (index !== -1) {
        inquiry.inquiry_items[index] = inquiryItem;
      }
    }
  },

  removeInquiry(state, inquiry) {
    const index = state.inquiries.findIndex((item) => item.id === inquiry.id);

    if (index !== -1) {
      state.inquiries.splice(index, 1);
    }

    state.inquiry = null;
  },

  removeInquiryItem(state, inquiryItem) {
    const inquiry = state.inquiries.find(
      (inquiry) => inquiry.id === inquiryItem.inquiry_id
    );

    if (inquiry) {
      const index = inquiry.inquiry_items.findIndex(
        (item) => item.id === inquiryItem.id
      );

      if (index !== -1) {
        const resettedInquiryItem = {
          ...inquiryItem,
          status: null,
          id: null,
          granted_value: Number(inquiry.requested_value),
          temp_id: generateUuid(),
        };

        inquiry.inquiry_items[index] = resettedInquiryItem;
      }
    }
  },

  setHasLoadedInquiries(state, bool) {
    state.hasLoadedInquiries = bool;
  },

  setIsLoadingInquiries(state, bool) {
    state.isLoadingInquiries = bool;
  },

  setHasLoadedInquiryPayments(state, bool) {
    state.hasLoadedInquiryPayments = bool;
  },

  setIsLoadingInquiryPayments(state, bool) {
    state.isLoadingInquiryPayments = bool;
  },

  generateMissingInquiryItems(state, { itemId, meetingId }) {
    state.inquiries.forEach((inquiry) => {
      if (
        inquiry.status === "approved" ||
        inquiry.status === "rejected" ||
        inquiry.status === "completed"
      ) {
        return;
      }

      if (helpers.latestInquiryItemIsHandled(inquiry)) {
        return;
      }

      const existingInquiryItem = inquiry.inquiry_items.find(
        (item) => item.item_id === itemId
      );

      if (!existingInquiryItem) {
        const newInquiryItem = {
          item_id: itemId,
          inquiry_id: inquiry.id,
          granted_value: Number(inquiry.requested_value),
          meeting_id: meetingId,
          status: null,
          comment: null,
          temp_id: generateUuid(),
        };

        inquiry.inquiry_items[inquiry.inquiry_items.length] = newInquiryItem;
      }
    });
  },

  setNewInquiryForm(state, form) {
    state.newInquiryForm = form;
  },

  setNewInquiryFormErrors(state, errors) {
    if (!state.newInquiryForm) {
      return;
    }
    state.newInquiryForm.errors = errors;
  },

  resetNewInquiryForm(state) {
    state.newInquiryForm = cloneDeep(defaultInquiryForm);
  },

  setNewInquiryPaymentsFormErrors(state, errors) {
    if (!state.newInquiryForm) {
      return;
    }
    state.newInquiryForm.payment.errors = errors;
  },
};

export const helpers = {
  inquiriesForMeeting: (inquiries) => (meeting) => {
    return inquiries.filter((inquiry) => {
      if (!inquiry.latest_inquiry_item) {
        return false;
      }

      return inquiry.latest_inquiry_item.meeting_id === meeting.id;
    });
  },

  inquiriesByStatus: (inquiries) => (statuses) => {
    const statusArray = Array.isArray(statuses) ? statuses : [statuses];

    return inquiries.filter((inquiry) => {
      const inquiryStatus = inquiry.status || null;

      return statusArray.includes(inquiryStatus);
    });
  },

  inquiryPaymentsByPaymentState: (inquiryPayments) => (paymentState) => {
    const paymentStateArray = Array.isArray(paymentState)
      ? paymentState
      : [paymentState];

    return inquiryPayments.filter((inquiryPayment) => {
      const inquiryPaymentPaymentState = inquiryPayment.state || null;

      return paymentStateArray.includes(inquiryPaymentPaymentState);
    });
  },

  inquiriesByStatusFromItem: (inquiries) => (statuses) => {
    const statusArray = Array.isArray(statuses) ? statuses : [statuses];

    return inquiries.filter((inquiry) => {
      const inquiryStatus = inquiry.latest_inquiry_item?.status || null;

      return statusArray.includes(inquiryStatus);
    });
  },

  extractLatestInquiryItems(inquiries) {
    return inquiries.map((inquiry) => {
      const inquiry_items = inquiry.inquiry_items;
      const latestInquiryItem = inquiry_items[inquiry_items.length - 1] || {};

      return {
        ...inquiry,
        latest_inquiry_item: latestInquiryItem,
      };
    });
  },

  getUniqueCurrencies(inquiries) {
    const allInquiriesCurrencies = new Set();

    inquiries.forEach((inquiry) => {
      allInquiriesCurrencies.add(inquiry.currency || "SEK");
    });

    return [...allInquiriesCurrencies];
  },

  calculateSummary(inquiries) {
    const summary = {
      statuses: {},
      count: 0,
      handled: 0,
    };

    inquiries.forEach((inquiry) => {
      const latest_inquiry_item = inquiry.latest_inquiry_item;

      if (!latest_inquiry_item) return;

      let currency = inquiry.currency || "SEK";
      let granted_value = Number(latest_inquiry_item.granted_value) || 0;
      let requested_value = Number(inquiry.requested_value) || 0;
      let application_value = Number(inquiry.application_value) || 0;
      let status = latest_inquiry_item.status;

      if (status) {
        if (!summary.statuses[status]) {
          summary.statuses[status] = {
            count: 0,
            currencies: {},
          };
        }
        if (!summary.statuses[status].currencies[currency]) {
          summary.statuses[status].currencies[currency] = {
            total_granted_value: 0,
            total_requested_value: 0,
            total_application_value: 0,
          };
        }
        summary.statuses[status].currencies[currency].total_granted_value +=
          granted_value;
        summary.statuses[status].currencies[currency].total_requested_value +=
          requested_value;
        summary.statuses[status].currencies[currency].total_application_value +=
          application_value;
        summary.statuses[status].count++;
        summary.handled++;
      }
      summary.count++;
    });
    return summary;
  },

  latestInquiryItemIsHandled(inquiry) {
    if (inquiry.inquiry_items.length === 0) {
      return false;
    }

    const latestInquiryItem =
      inquiry.inquiry_items[inquiry.inquiry_items.length - 1];

    return (
      latestInquiryItem &&
      ["approved", "rejected", "completed"].includes(latestInquiryItem.status)
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  helpers,
};
