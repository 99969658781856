<template>
  <be-table-simple :class="{ 'border-spacing-0': depth > 0 }">
    <tbody>
      <tr>
        <td class="shrink-column pr-0" :class="{ 'border-0': depth > 0 }">
          <i
            class="fal fa-fw"
            :class="opened ? 'fa-folder-open' : 'fa-folder'"
          />
        </td>

        <td :class="{ 'border-0': depth > 0 }">
          <be-link @click="opened = !opened">
            {{ folder.title }}
          </be-link>
        </td>

        <td :class="{ 'border-0': depth > 0 }" />
      </tr>

      <tr v-if="folders.length > 0 && opened">
        <td colspan="3" class="p-0 pl-3">
          <folder-list
            v-for="subFolder in folders"
            :key="subFolder.id"
            :folder="subFolder"
            :subtree-documents="subtreeDocuments"
            :subtree-folders="subtreeFolders"
            :depth="depth + 1"
            :opened="false"
          />
        </td>
      </tr>

      <template v-if="documents.length > 0 && opened">
        <tr
          v-for="doc in documents"
          :key="doc.id"
          :class="{ 'pl-2': depth > 0 }"
        >
          <td>
            <i :class="`fal ${getFileIconClass(doc.filename || doc.title)}`" />
          </td>

          <td>
            <document-link
              :company-id="ownerId"
              :document-id="doc.id"
              :filename="doc.filename"
              :show-icon="false"
              :title="doc.title"
            />
          </td>

          <td class="col-shrink text-right">
            {{ doc.file_size }}
          </td>
        </tr>
      </template>
    </tbody>
  </be-table-simple>
</template>

<script>
import { getClassNameForFilename } from "@/vendor/font-awesome-filetypes";

export default {
  name: "FolderList",

  props: {
    folder: {
      type: Object,
      required: true,
    },

    subtreeDocuments: {
      type: Array,
      required: true,
      default: () => [],
    },

    subtreeFolders: {
      type: Array,
      required: true,
      default: () => [],
    },

    depth: {
      type: Number,
      required: false,
      default: 0,
    },

    initiallyOpened: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      opened: this.initiallyOpened,
    };
  },

  computed: {
    ownerId() {
      return this.folder?.owner_id;
    },

    folders() {
      return this.foldersByParentId[this.folder.id] || [];
    },

    foldersByParentId() {
      const folders = {};

      this.subtreeFolders.forEach((folder) => {
        if (!folders[folder.parent_id]) {
          folders[folder.parent_id] = [];
        }

        folders[folder.parent_id].push(folder);
      });

      return folders;
    },

    documents() {
      return this.documentsByFolderId[this.folder.id] || [];
    },

    documentsByFolderId() {
      const documents = {};

      this.subtreeDocuments.forEach((doc) => {
        if (!documents[doc.folder_id]) {
          documents[doc.folder_id] = [];
        }

        documents[doc.folder_id].push(doc);
      });

      return documents;
    },
  },

  methods: {
    getFileIconClass(filename) {
      return getClassNameForFilename(filename);
    },
  },
};
</script>
