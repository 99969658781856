<template>
  <div>
    <div class="d-flex">
      <h6
        v-if="!hideHeader"
        v-text="
          $t('components.meetings.tabs.components.meeting_documents.title')
        "
      />
    </div>

    <grouped-documents-list
      v-if="folder.id && hasDocuments"
      :folder="folder"
      :meeting="meeting"
    />

    <template v-else>
      {{
        $t("components.meetings.tabs.components.meeting_documents.no_documents")
      }}
    </template>
  </div>
</template>

<script>
import GroupedDocumentsList from "./GroupedDocumentsList.vue";

export default {
  components: {
    GroupedDocumentsList,
  },

  props: {
    folder: {
      type: Object,
      required: true,
    },

    meeting: {
      type: Object,
      required: true,
    },

    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    hasDocuments() {
      return (this.folder?.subtree_documents || []).length > 0;
    },
  },
};
</script>
