<template>
  <div>
    <div class="card">
      <div class="card-header d-md-flex justify-content-end">
        <be-button
          :href="url(`/template_packages/${templatePackage.id}/edit`)"
          variant="outline-secondary"
        >
          {{ $t("views.companies.template_packages.edit.title") }}
        </be-button>

        <be-button
          v-if="templatePackage.password_required"
          v-be-modal.password-modal
          variant="outline-primary"
        >
          {{
            $t(
              "components.template_packages.template_invitations.show_password"
            )
          }}
        </be-button>

        <be-dropdown v-if="templatePackage.policy.unpublish" ellipsis>
          <be-dropdown-item variant="danger" @click="unpublish">
            {{ $t("buttons.titles.unpublish") }}
          </be-dropdown-item>
        </be-dropdown>
      </div>

      <div class="card-body">
        <h3>
          {{
            $t("components.template_packages.template_invitations.shared_with")
          }}
        </h3>

        <be-table-simple>
          <thead>
            <tr>
              <th class="col-shrink" />

              <th>{{ translateAttribute("user", "name") }}</th>

              <th>{{ translateAttribute("user", "email") }}</th>

              <th>{{ translateAttribute("invitation", "created_at") }}</th>

              <th class="col-shrink" />
            </tr>
          </thead>

          <tbody>
            <tr v-for="(invitation, index) in invitations" :key="index">
              <td>
                <user-avatar v-if="invitation.id" :user="invitation.user_id" />
              </td>

              <td>
                <template v-if="invitation.id">
                  {{ invitation.user.name }}
                </template>

                <template v-else>
                  <be-form-group
                    :error="getErrors(invitation.user, 'name')"
                    class="mb-0"
                  >
                    <be-form-input
                      id="name"
                      v-model="invitation.user.name"
                      v-autofocus
                      :name="`template_package[invitations_attributes][${invitation.uuid}][name]`"
                      :placeholder="
                        $t(
                          'components.template_packages.template_invitations.name_placeholder'
                        )
                      "
                      @change="clearErrors(invitation.user, ['name'])"
                    />
                  </be-form-group>
                </template>
              </td>

              <td>
                <template v-if="invitation.id">
                  <be-link :href="`mailto:${invitation.user.email}`">
                    {{ invitation.user.email }}
                  </be-link>
                </template>

                <template v-else>
                  <be-form-group
                    :error="getErrors(invitation.user, 'email')"
                    class="mb-0"
                  >
                    <be-form-input
                      id="email"
                      v-model="invitation.user.email"
                      :placeholder="
                        $t(
                          'components.template_packages.template_invitations.email_placeholder'
                        )
                      "
                      type="email"
                      :name="`template_package[invitations_attributes][${invitation.uuid}][email]`"
                      @change="clearErrors(invitation.user, ['email'])"
                    />
                  </be-form-group>
                </template>
              </td>

              <td>
                {{
                  dateIfPresent(
                    !!invitation.deleted_at ? null : invitation.created_at
                  )
                }}
              </td>

              <td>
                <be-button
                  v-if="!invitation.id"
                  v-be-tooltip="$t('buttons.titles.remove')"
                  variant="danger"
                  size="sm"
                  icon="fa-times"
                  inline
                  @click="removeInvitation(invitation)"
                />

                <be-button
                  v-else-if="invitation.user_id !== templatePackage.user_id"
                  variant="primary"
                  size="sm"
                  @click="remind(invitation)"
                >
                  <template v-if="sendingReminderTo === invitation.id">
                    {{ $t("buttons.states.sending") }}
                  </template>

                  <span v-else>{{ $t("buttons.titles.remind") }}</span>
                </be-button>
              </td>
            </tr>
          </tbody>
        </be-table-simple>

        <be-button
          variant="outline-secondary"
          size="sm"
          @click="addInvitation()"
        >
          {{ $t("buttons.titles.add_more") }}
        </be-button>
      </div>

      <div class="card-footer text-right">
        <be-button
          variant="primary"
          :disabled="invitations.length == 1 || newInvitations.length == 0"
          @click="confirmSendInvites"
        >
          {{ $t("buttons.titles.send") }}
        </be-button>
      </div>
    </div>

    <be-modal
      v-if="templatePackage.password_required"
      id="password-modal"
      ref="password-modal"
      ok-only
      ok-variant="light"
      :ok-title="$t('buttons.titles.close')"
      :title="$t('components.template_packages.template_invitations.password')"
    >
      <be-form-group
        :description="
          $t(
            'components.template_packages.template_invitations.password_description'
          )
        "
      >
        <be-input-group>
          <be-form-input :model-value="templatePackage.password" />

          <be-input-group-append>
            <be-button variant="outline-secondary" @click="copyPassword">
              {{ $t("buttons.titles.copy") }}
            </be-button>
          </be-input-group-append>
        </be-input-group>
      </be-form-group>
    </be-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  props: {
    initialTemplatePackage: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sendingReminderTo: null,
    };
  },

  computed: {
    ...mapGetters({
      templatePackage: "template_packages/getTemplatePackage",
      invitations: "template_packages/getInvitations",
    }),

    fields() {
      return [
        { key: "avatar", label: "", class: "col-shrink" },
        {
          key: "name",
          label: this.translateAttribute("user", "name"),
          sortable: true,
        },
        {
          key: "email",
          label: this.translateAttribute("user", "email"),
        },
        {
          key: "created_at",
          label: this.translateAttribute("invitation", "created_at"),
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
        },
      ];
    },

    newInvitations() {
      return this.invitations.filter((invitation) => !invitation.id);
    },
  },

  created() {
    this.$store.dispatch(
      "template_packages/setTemplatePackage",
      this.initialTemplatePackage
    );
  },

  methods: {
    addInvitation() {
      this.$store.dispatch("template_packages/addInvitation");
    },

    async copyPassword() {
      try {
        await navigator.clipboard.writeText(this.templatePackage.password);

        EventBus.emit("new-notification", {
          message: this.$i18n.t(
            "components.template_packages.template_invitations.password_copied"
          ),

          status: "notice",
        });

        this.$refs["password-modal"].hide();
      } catch {
        EventBus.emit("new-notification", {
          message: this.$i18n.t(
            "components.template_packages.template_invitations.password_not_copied"
          ),

          status: "danger",
        });
      }
    },

    dateIfPresent(date) {
      if (date) {
        return this.$d(new Date(date), { format: "mini" });
      }
    },

    removeInvitation(invitation) {
      let idx = this.invitations.findIndex((existingInvitation) => {
        return (
          (invitation.id && existingInvitation.id === invitation.id) ||
          (invitation.uuid && existingInvitation.uuid === invitation.uuid)
        );
      });

      if (idx > -1) {
        this.invitations.splice(idx, 1);
      }
    },

    async confirmSendInvites() {
      const isConfirmed = await this.promptConfirm({
        confirmButtonText: this.$i18n.t("buttons.titles.send"),

        title: this.$i18n.t(
          "components.template_packages.template_invitations.confirm_send"
        ),

        description: this.$i18n.t(
          "components.template_packages.template_invitations.confirm_description"
        ),
      });

      if (isConfirmed) {
        this.sendInvites();
      }
    },

    async sendInvites() {
      let invitations = [];
      this.newInvitations.forEach((invitation) => {
        let invite = this.$store.dispatch("template_packages/inviteUser", {
          user: invitation.user,
        });
        invitations.push(invite);
      });

      await Promise.all(invitations);
    },

    async remind(invitation) {
      this.sendingReminderTo = invitation.id;

      try {
        await axios.patch(
          this.url(
            `/template_packages/${this.templatePackage.id}/invitations/${invitation.id}`
          )
        );
      } catch (error) {
        this.handleError(error);
      } finally {
        this.sendingReminderTo = null;
      }
    },

    async unpublish() {
      const description =
        this.templatePackage.template_package_items?.length > 1
          ? this.$t(
              "components.template_packages.confirm_unpublish_package_description",
              {
                title: this.templatePackage.title,
              }
            )
          : this.$t(
              "components.template_packages.confirm_unpublish_description",
              { title: this.templatePackage.title }
            );

      const isConfirmed = await this.promptRemovalConfirm({
        confirmButtonText: this.$t("buttons.titles.unpublish"),

        title: this.$t("components.template_packages.confirm_unpublish", {
          title: this.templatePackage.title,
        }),

        description: description,
      });

      if (isConfirmed) {
        await this.$store.dispatch(
          "template_packages/unpublish",
          this.templatePackage
        );

        document.location.href = this.url("/template_packages");
      }
    },
  },
};
</script>
