<template>
  <ol v-if="localBreadcrumbs.length > 0" class="breadcrumb d-print-none">
    <li
      v-for="(breadcrumb, index) in localBreadcrumbs"
      :key="`breadcrumb-${breadcrumb.title}`"
      class="breadcrumb-item"
      :class="{ active: index == localBreadcrumbs.length - 1 }"
    >
      <a
        v-if="breadcrumb.link"
        v-be-tooltip="titleTooltip(breadcrumb.title)"
        :href="breadcrumb.link"
      >
        {{ truncateTitle(breadcrumb.title) }}
      </a>

      <span v-else v-be-tooltip="titleTooltip(breadcrumb.title)">
        {{ truncateTitle(breadcrumb.title) }}
      </span>
    </li>
  </ol>
</template>

<script>
import { EventBus } from "@/event-bus";
import { truncateText } from "@/utils/text-utils";

export default {
  name: "BeBreadcrumbs",

  props: {
    values: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      localBreadcrumbs: this.cloneDeep(this.values),
    };
  },

  watch: {
    values: {
      handler() {
        this.localBreadcrumbs = this.cloneDeep(this.values);
      },

      deep: true,
    },
  },

  mounted() {
    EventBus.on("BREADCRUMBS_UPDATED", this.breadcrumbsUpdated);
  },

  methods: {
    breadcrumbsUpdated(value) {
      this.localBreadcrumbs = value;
    },

    truncateTitle(title) {
      return truncateText(title, 32);
    },

    titleTooltip(title) {
      return title.length > 32 ? title : "";
    },
  },
};
</script>
