<template>
  <div :class="computedWrapperClasses">
    <div :class="computedHeaderClasses">
      <be-nav tabs class="d-flex flex-column flex-md-row flex-grow-1">
        <be-nav-item
          v-for="(tab, index) in formattedTabs"
          :key="index"
          :active="tab.id === localActiveTab"
          :item-class="tab.class"
          :style="computedStyle(tab)"
          @click="changeTab(tab.id, tab.disabled)"
        >
          <i
            v-if="tab.warning"
            v-be-tooltip="tab.warning"
            class="fa-duotone fa-triangle-exclamation fa-fw text-warning"
          />

          {{ tab.title }}

          <be-badge v-if="tab.count" variant="light" class="badge-pill">
            {{ tab.count }}
          </be-badge>
        </be-nav-item>
      </be-nav>

      <div v-if="hasContentRightSlot" :class="computedContentRightClasses">
        <slot name="content-right" />
      </div>
    </div>

    <Teleport :to="teleport" :disabled="teleport === 'body'">
      <div :class="computedBodyClasses">
        <slot name="before-body" />

        <slot />
      </div>
    </Teleport>

    <div v-if="$slots.footer" :class="computedFooterClasses">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import RequestQueryMixin from "@/mixins/RequestQuery";

export default {
  name: "BeTabsNew",

  mixins: [RequestQueryMixin],

  provide() {
    return {
      activeTab: computed(() => this.localActiveTab),
      changeTab: this.changeTab,
      registerTab: this.registerTab,
      updateTab: this.updateTab,
      unregisterTab: this.unregisterTab,
    };
  },

  props: {
    activeTab: {
      type: String,
      required: false,
      default: "",
    },

    card: {
      type: Boolean,
      required: false,
      default: false,
    },

    cardBodyClass: {
      type: [String, Array, Object],
      required: false,
      default: "",
    },

    cardFooterClass: {
      type: [String, Array, Object],
      required: false,
      default: "",
    },

    ignoreQueryParams: {
      type: Boolean,
      required: false,
      default: false,
    },

    queryParam: {
      type: String,
      required: false,
      default: "tab",
    },

    teleport: {
      type: String,
      required: false,
      default: "body",
    },
  },

  emits: ["update:activeTab"],

  data() {
    return {
      localActiveTab: this.activeTab,
      internalTabs: [],
    };
  },

  computed: {
    computedWrapperClasses() {
      return [
        "be-tabs",
        "d-print-none",
        {
          card: this.card,
        },
      ];
    },

    computedHeaderClasses() {
      return [
        "d-lg-flex",
        "justify-content-between",
        "align-items-center",
        {
          "card-header py-0": this.card,
          "flex-wrap gap-4": this.hasContentRightSlot,
        },
      ];
    },

    computedBodyClasses() {
      return [
        {
          "card-body": this.card,
        },
        this.cardBodyClass,
      ];
    },

    computedFooterClasses() {
      return [
        {
          "card-footer": this.card,
        },
        this.cardFooterClass,
      ];
    },

    computedContentRightClasses() {
      return [
        "d-flex",
        "flex-column",
        "flex-md-row",
        "gap-1",
        { "mt-2 mt-md-0": this.hasContentRightSlot },
      ];
    },

    hasContentRightSlot() {
      return this.$slots["content-right"];
    },

    formattedTabs() {
      return this.internalTabs.map((tab) => {
        const translationKey = tab.title.replace("-", "_");

        let label = null;
        if (this.translationScope) {
          label = this.$t(`${this.translationScope}.${translationKey}`);
        }

        return {
          label: label,
          count: null,

          ...tab,
        };
      });
    },
  },

  watch: {
    activeTab(value) {
      this.localActiveTab = value;
    },

    internalTabs: {
      handler(value) {
        if (!this.localActiveTab) {
          // Set the first enabled tab as active if no tab is active
          this.localActiveTab = value.find((tab) => !tab.disabled)?.id || null;
        }
      },

      deep: true,
    },

    localActiveTab(value) {
      if (this.ignoreQueryParams) {
        return;
      }

      this.setQueryParam(this.queryParam, value);
    },
  },

  mounted() {
    if (this.ignoreQueryParams) {
      return;
    }

    this.localActiveTab = this.getQueryParam(this.queryParam);
  },

  methods: {
    changeTab(tabId, disabled) {
      if (disabled) {
        return;
      }

      this.localActiveTab = tabId;
      this.$emit("update:activeTab", tabId);
    },

    registerTab(tab) {
      this.internalTabs.push(tab);
    },

    updateTab(tab) {
      const index = this.internalTabs.findIndex((t) => t.id === tab.id);

      if (index !== -1) {
        this.internalTabs[index] = tab;
      }
    },

    unregisterTab(tabId) {
      this.internalTabs = this.internalTabs.filter((tab) => tab.id !== tabId);
    },

    // TODO: This is a hack, don't repeat this behavior.
    computedStyle(tab) {
      if (this.card || tab.id !== this.localActiveTab) {
        return {};
      }

      return {
        borderBottom: `3px solid ${window.getComputedStyle(document.documentElement).getPropertyValue("--tab-border")}`,
      };
    },
  },
};
</script>
