<template>
  <div v-if="card && warning" class="card">
    <div class="card-body">
      <be-alert :variant="variant">
        {{ warning }}
      </be-alert>
    </div>
  </div>

  <be-alert v-else-if="warning" :variant="variant" class="mb-3">
    {{ warning }}
  </be-alert>
</template>

<script>
const POLLING_INTERVAL = 2000;

export default {
  props: {
    reference: {
      type: String,
      required: true,
    },

    activityType: {
      type: String,
      required: true,
    },

    card: {
      type: Boolean,
      required: false,
      default: false,
    },

    variant: {
      type: String,
      required: false,
      default: "warning",
    },
  },

  data() {
    return {
      poller: null,
      allSessions: [],
      session: null,
    };
  },

  computed: {
    otherUserIds() {
      const ids = new Set();
      this.allSessions
        .filter((session) => session.user_id !== this.$currentUser.id)
        .forEach((session) => ids.add(session.user_id));

      return ids;
    },

    warning() {
      if (this.otherUserIds.size > 0) {
        if (this.variant === "info") {
          return this.$t(
            "components.shared.user_activity_session.info_multiple_users_visiting_w_users",
            { users: this.otherUserNames.join(", ") }
          );
        } else {
          return this.$t(
            "components.shared.user_activity_session.warning_multiple_users_edit_w_users",
            { users: this.otherUserNames.join(", ") }
          );
        }
      } else if (this.allSessions.length > 1) {
        return this.$t(
          "components.shared.user_activity_session.warning_multiple_tabs_edit"
        );
      }

      return null;
    },

    otherUserNames() {
      return [...this.otherUserIds].map((id) => this.getUserName(id));
    },
  },

  async mounted() {
    await this.createSession();
    this.startPolling();
    window.addEventListener("beforeunload", () => this.destroySession());
  },

  beforeUnmount() {
    this.stopPolling();
    this.destroySession();
    window.removeEventListener("beforeunload", () => this.destroySession());
  },

  methods: {
    startPolling() {
      this.poller = setInterval(this.updateSession, POLLING_INTERVAL);
    },

    stopPolling() {
      clearInterval(this.poller);
    },

    async createSession() {
      try {
        const { data } = await axios.post("/users/activity_sessions", {
          reference: this.reference,
          activity_type: this.activityType,
        });
        this.session = data.current;
        this.allSessions = data.sessions;
      } catch (error) {
        console.error(error);
      }
    },

    async updateSession() {
      if (!this.session) {
        return;
      }

      try {
        const { data } = await axios.patch(
          `/users/activity_sessions/${this.session.id}`
        );

        this.allSessions = data;
        this.session = data.find((session) => session.id === this.session.id);
      } catch (error) {
        if (error.response?.status === 404) {
          this.stopPolling();
        }
      }
    },

    async destroySession() {
      if (!this.session) {
        return;
      }

      try {
        await axios.delete(`/users/activity_sessions/${this.session.id}`);
      } catch {
        // Do nothing
      } finally {
        this.stopPolling();
        this.session = null;
      }
    },
  },
};
</script>
