import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import { url } from "@/url-helpers";
import { addHours } from "date-fns";
import { handleError } from "@/utils/error-handling";

export const state = {
  agendas: [],
  currentMeeting: {},
  folderForMeeting: [],
  haveLoadedMeetings: false,
  invitationsForMeeting: [],
  attendancesForMeeting: [],
  commentsRequestsForMeeting: [],
  meetings: [],
  minutes: [],
};

export const getters = {
  getCurrentMeeting: (state) => state.currentMeeting,

  invitationStatusForMeeting: (state) => (meetingId) => {
    const result = state.invitationsForMeeting.find(
      (row) => row.meeting_id === meetingId
    );
    return result?.status || "loading";
  },

  getInvitationsForMeeting: (state) => (meetingId) => {
    const result = state.invitationsForMeeting.find(
      (row) => row.meeting_id === meetingId
    );
    return result?.data || [];
  },

  attendanceStatusForMeeting: (state) => (meetingId) => {
    const result = state.attendancesForMeeting.find(
      (row) => row.meeting_id === meetingId
    );
    return result?.status || "loading";
  },

  getAttendancesForMeeting: (state) => (meetingId) => {
    const result = state.attendancesForMeeting.find(
      (row) => row.meeting_id === meetingId
    );
    return result?.data || [];
  },

  commentsRequestStatusForMeeting: (state) => (meetingId) => {
    const result = state.commentsRequestsForMeeting.find(
      (row) => row.meeting_id === meetingId
    );
    return result?.status || "loading";
  },

  getCommentsRequestsForMeeting: (state) => (meetingId) => {
    const result = state.commentsRequestsForMeeting.find(
      (row) => row.meeting_id === meetingId
    );
    return result?.data || [];
  },

  folderForMeeting: (state) => (meetingId) => {
    const result = state.folderForMeeting.find(
      (folder) => folder.meeting_id === meetingId
    );
    return result?.data || {};
  },

  folderStatusForMeeting: (state) => (meetingId) => {
    const result = state.folderForMeeting.find(
      (folder) => folder.meeting_id === meetingId
    );

    return result?.status || "loading";
  },

  getMeetings: (state) => state.meetings,

  getMeeting: (state) => (id) => {
    return state.meetings.find((meeting) => meeting.id === id);
  },

  getAgenda: (state) => (meeting) =>
    state.agendas.find((agenda) => agenda.meeting_id === meeting?.id),

  getMinutes: (state) => (meeting) =>
    state.minutes.find((minutes) => minutes.meeting_id === meeting?.id),

  getActiveMeetings: (state) =>
    state.meetings.filter((meeting) => !meeting.is_archived),

  getArchivedMeetings: (state) => () =>
    state.meetings.filter((meeting) => meeting.is_archived),

  getMeetingsByState: (state) => (meetingState) =>
    state.meetings.filter((meeting) => meeting.active_state == meetingState),

  haveLoadedMeetings: (state) => state.haveLoadedMeetings,
};

export const actions = {
  addMeetings({ commit }, meetings) {
    commit("addMeetings", meetings);
  },

  setCurrentMeeting({ commit }, meeting) {
    commit("setCurrentMeeting", meeting);
  },

  updateMeeting({ commit }, meeting) {
    commit("updateMeeting", meeting);
  },

  async pickDate(context, suggestion) {
    const meeting = context.getters.getCurrentMeeting();

    try {
      await axios.post(`${meeting.paths.base}/pick_date`, {
        suggestion_id: suggestion.id,
      });
      context.commit("pickDate", suggestion);
    } catch (error) {
      handleError(error);
    }
  },

  async sendSuggestions(context, { userIdList, meeting }) {
    try {
      await axios.post(`${meeting.paths.base}/send_suggestions`, {
        user_ids: userIdList,
      });
    } catch (error) {
      handleError(error);
    }
  },

  async removeMeeting(context, { meeting, sendMeetingCancellationEmail }) {
    await axios.delete(meeting.paths.base, {
      params: {
        send_meeting_cancellation_email: sendMeetingCancellationEmail,
      },
    });
    context.commit("removeMeeting", meeting.id);
  },

  async fetchInvitationsForMeeting(context, meeting) {
    try {
      let invitationsUrl = url(`/meetings/*/invitations`, {
        company: meeting.company_id,
      });

      if (meeting.paths?.base) {
        invitationsUrl = `${meeting.paths.base}/invitations`;
      }
      const response = await axios.get(invitationsUrl);

      context.commit("loadInvitationsForMeeting", {
        meeting: meeting,
        invitations: response.data || [],
        status: "loaded",
      });
    } catch (error) {
      context.commit("loadInvitationsForMeeting", {
        meeting: meeting,
        invitations: [],
        status: "error",
      });

      handleError(error);
    }
  },

  async reloadMeeting(context, { id }) {
    if (id) {
      let response = await axios.get(url(`/meetings/${id}`));
      context.commit("updateMeeting", response.data);
    }
  },

  async saveInvitationBatchUnhandled(context, { meeting, invitationBatch }) {
    if (meeting && meeting.id) {
      return await axios.post(`${meeting.paths.base}/invitations`, {
        invitation_batch: invitationBatch,
      });
    } else {
      throw new Error("invalid_meeting");
    }
  },

  async fetchAgenda(context, meeting) {
    try {
      const response = await axios.get(`${meeting.paths.base}/agenda`);
      const agenda = {
        ...response.data,
        status: "loaded",
      };

      context.commit("updateAgenda", agenda);
    } catch (error) {
      context.commit("updateAgenda", {
        meeting_id: meeting.id,
        status: "error",
      });

      handleError(error);
    }
  },

  async fetchMinutes(context, meeting) {
    try {
      const response = await axios.get(`${meeting.paths.base}/minutes`);
      const minutes = {
        ...response.data,
        status: "loaded",
      };

      context.commit("updateMinutes", minutes);
    } catch (error) {
      context.commit("updateMinutes", {
        meeting_id: meeting.id,
        status: "error",
      });

      handleError(error);
    }
  },

  async fetchAttendancesForMeeting(context, meeting) {
    try {
      const response = await axios.get(`${meeting.paths.base}/attendances`);

      context.commit("loadAttendancesForMeeting", {
        meetingId: meeting.id,

        attendanceData: {
          data: response.data,
          status: "loaded",
        },
      });
    } catch (error) {
      context.commit("loadAttendancesForMeeting", {
        meetingId: meeting.id,

        attendanceData: {
          data: [],
          status: "error",
        },
      });

      handleError(error);
    }
  },

  async fetchCommentsRequestsForMeeting(context, meeting) {
    try {
      const response = await axios.get(
        `${meeting.paths.base}/minutes/comments_request`
      );
      context.commit("loadCommentsRequestsForMeeting", {
        meetingId: meeting.id,

        commentsRequestsData: {
          data: response.data,
          status: "loaded",
        },
      });
    } catch (error) {
      context.commit("loadCommentsRequestsForMeeting", {
        meetingId: meeting.id,

        commentsRequestsData: {
          data: [],
          status: "error",
        },
      });

      handleError(error);
    }
  },

  async fetchFolderForMeeting(context, meeting) {
    try {
      const response = await axios.get(`${meeting.paths.base}/folder`);

      context.commit("loadFolderForMeeting", {
        meetingId: meeting.id,

        folderData: {
          data: response.data,
          status: "loaded",
        },
      });
    } catch (error) {
      context.commit("loadFolderForMeeting", {
        meeting_id: meeting.id,

        folderData: {
          data: {},
          status: "error",
        },
      });

      handleError(error);
    }
  },
};

export const mutations = {
  setCurrentMeeting(state, meeting) {
    if (meeting.title === null) {
      // need to fix some stuff
      meeting.title = "";
      const startAt = new Date(meeting.start_at);
      meeting.end_at = addHours(startAt, 1).toISOString();
    }
    state.currentMeeting = meeting;
    if (state.meetings.length == 0) {
      state.meetings.push(meeting);
    }
  },

  updateCurrentMeeting(state, meeting) {
    state.currentMeeting = meeting;
  },

  pickDate(state, suggestion) {
    const newSuggestions = cloneDeep(state.currentMeeting.suggestions);
    newSuggestions.forEach((newSuggestion) => {
      newSuggestion.selected_at =
        newSuggestion.id === suggestion.id ? new Date().toISOString() : null;
    });
    state.currentMeeting.start_at = suggestion.start_at;
    state.currentMeeting.end_at = suggestion.end_at;
    state.currentMeeting.suggestions = newSuggestions;
  },

  updateInvitation(state, invitation) {
    if (invitation.reference_type !== "Meeting") {
      return;
    }

    let index = state.invitationsForMeeting.findIndex(
      (row) => row.meeting_id === invitation.reference_id
    );

    if (index === -1) {
      state.invitationsForMeeting.push({
        meeting_id: invitation.reference_id,
        data: [],
      });
      index = state.invitationsForMeeting.length - 1;
    }

    const invitations = state.invitationsForMeeting[index].data;
    const invitationIndex = invitations.findIndex(
      (existing) => existing.user_id == invitation.user_id
    );
    if (invitationIndex === -1) {
      invitations.push(invitation);
    } else {
      invitations[invitationIndex] = invitation;
    }
  },

  loadInvitationsForMeeting(state, { meeting, invitations, status }) {
    const data = {
      meeting_id: meeting.id,
      status,
      data: [],
    };

    if (Array.isArray(invitations)) {
      data.data = invitations.map((invitation) => ({
        ...invitation,
        resend: false,
      }));
    }

    const index = state.invitationsForMeeting.findIndex(
      (row) => row.meeting_id == meeting.id
    );

    if (index === -1) {
      state.invitationsForMeeting.push(data);
    } else {
      state.invitationsForMeeting[index] = data;
    }
  },

  loadAttendancesForMeeting(state, { meetingId, attendanceData }) {
    const data = {
      meeting_id: meetingId,
      ...attendanceData,
    };

    const index = state.attendancesForMeeting.findIndex(
      (row) => row.meeting_id == meetingId
    );

    if (index === -1) {
      state.attendancesForMeeting.push(data);
    } else {
      state.attendancesForMeeting[index] = data;
    }
  },

  loadCommentsRequestsForMeeting(state, { meetingId, commentsRequestsData }) {
    const data = {
      meeting_id: meetingId,
      ...commentsRequestsData,
    };
    const index = state.commentsRequestsForMeeting.findIndex(
      (row) => row.meeting_id == meetingId
    );
    if (index === -1) {
      state.commentsRequestsForMeeting.push(data);
    } else {
      state.commentsRequestsForMeeting[index] = data;
    }
  },

  loadFolderForMeeting(state, { meetingId, folderData }) {
    const data = {
      meeting_id: meetingId,
      ...folderData,
    };
    const index = state.folderForMeeting.findIndex(
      (row) => row.meeting_id == meetingId
    );
    if (index === -1) {
      state.folderForMeeting.push(data);
    } else {
      state.folderForMeeting[index] = data;
    }
  },

  updateMeetingSuggestionChoice(state, { meetingId, suggestionId, choice }) {
    const suggestion = state.meetings
      .find((meeting) => meeting.id == meetingId)
      ?.suggestions.find((suggestion) => suggestion.id == suggestionId);

    if (!suggestion) {
      return;
    }

    const index = suggestion.suggestion_choices.findIndex(
      (existing) => existing.user_id == choice.user_id
    );

    if (index === -1) {
      suggestion.suggestion_choices.push(choice);
    } else {
      suggestion.suggestion_choices[index] = choice;
    }
  },

  updateCommentsRequestForMeeting(state, { meetingId, commentsRequest }) {
    const requestIndex = state.commentsRequestsForMeeting.findIndex(
      (row) => row.meeting_id == meetingId
    );

    if (requestIndex === -1) {
      state.commentsRequestsForMeeting.push({
        meeting_id: meetingId,
        data: [commentsRequest],
        status: "loaded",
      });
    } else {
      const request = state.commentsRequestsForMeeting[requestIndex];
      const index = request.data.findIndex(
        (row) => row.id == commentsRequest.id
      );

      if (index === -1) {
        request.data.push(commentsRequest);
      } else {
        request.data[index] = commentsRequest;
      }
    }
  },

  loadMeetings(state, meetings) {
    state.meetings = meetings;
    state.haveLoadedMeetings = true;
  },

  addMeetings(state, meetings) {
    meetings.forEach((meeting) => {
      let idx = state.meetings.findIndex(
        (stateMeeting) => stateMeeting.id == meeting.id
      );

      if (idx === -1) {
        state.meetings.push(meeting);
      }
    });

    state.haveLoadedMeetings = true;
  },

  removeMeeting(state, meetingId) {
    let index = state.meetings.findIndex(
      (existing) => existing.id == meetingId
    );

    if (index !== -1) {
      state.meetings.splice(index, 1);
    }
  },

  updateMeeting(state, meeting) {
    let index = state.meetings.findIndex(
      (existing) => existing.id == meeting.id
    );

    if (index === -1) {
      state.meetings.push(meeting);
    } else {
      state.meetings[index] = meeting;
    }
  },

  updateAgenda(state, agenda) {
    let index = state.agendas.findIndex(
      (existing) => existing.meeting_id == agenda.meeting_id
    );

    if (index === -1) {
      state.agendas.push(agenda);
    } else {
      state.agendas[index] = agenda;
    }
  },

  updateMinutes(state, minutes) {
    let index = state.minutes.findIndex(
      (existing) => existing.meeting_id == minutes.meeting_id
    );

    if (index === -1) {
      state.minutes.push(minutes);
    } else {
      state.minutes[index] = minutes;
    }
  },

  updateMinutesDocument(state, { minutes, document }) {
    const minutesIndex = state.minutes.findIndex(
      (existing) => existing.meeting_id == minutes.meeting_id
    );

    if (minutesIndex === -1) {
      return;
    }

    const stateMinutes = state.minutes[minutesIndex];
    const index = stateMinutes.archived_documents.findIndex(
      (existing) => existing.id && existing.id === document.id
    );

    if (index > -1) {
      stateMinutes.archived_documents[index] = document;
    } else {
      stateMinutes.archived_documents.push(document);
    }
  },

  updateMeetingFolder(state, { meetingId, folder }) {
    const index = state.meetings.findIndex(
      (existing) => existing.id == meetingId
    );
    if (index === -1) {
      console.log("Could not find meeting", meetingId);
    } else {
      const updatedMeeting = cloneDeep(state.meetings[index]);
      updatedMeeting.folder = folder;
      state.meetings[index] = updatedMeeting;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
