<template>
  <node-view-wrapper as="span" class="mention">{{ text }}</node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-3";
import materialType from "@/components/meetings/material/materialState";
import { toSentence } from "@/utils/text-utils";

export default {
  components: {
    NodeViewWrapper,
  },

  mixins: [materialType],

  props: nodeViewProps,

  computed: {
    hashtagId() {
      return this.node.attrs.id;
    },

    text() {
      const values = this.hashtagsToValues[this.hashtagId];
      if (values && values.length > 0) {
        return toSentence(values, this.$i18n.locale);
      } else {
        const value =
          this.$t(`models.phrase.tags.${this.hashtagId}.value`) ||
          this.hashtagId;
        return `#${value}`;
      }
    },
  },
};
</script>
