<template>
  <div>
    <transaction-types />

    <transaction-instructions />

    <be-form-group
      :label="$t('views.companies.inquiries.create.receiver')"
      label-for="receiver"
      :description="$t('views.companies.inquiries.create.receiver_description')"
      :error="getErrors(form.payment, 'inquiry_receiver.name')"
    >
      <be-form-input
        id="receiver"
        v-model="form.payment.inquiry_receiver.name"
        @input="debouncedValidation"
      />
    </be-form-group>

    <be-form-group
      :label="$t('views.companies.inquiries.create.receiver_identifier')"
      label-for="receiver-identifier"
      :error="getErrors(form.payment, 'inquiry_receiver.receiver_identifier')"
      required
    >
      <be-form-input
        id="receiver-identifier"
        v-model="form.payment.inquiry_receiver.receiver_identifier"
        required
        @input="debouncedValidation"
      />
    </be-form-group>

    <be-form-group
      :label="$t('views.companies.inquiry_payments.create.upload_pdf')"
      label-for="payment_document"
      :description="
        $t('views.companies.inquiry_payments.create.upload_pdf_description')
      "
      :error="getErrors(form.payment, 'documents')"
    >
      <div v-if="form.id">
        <be-alert variant="warning">{{
          $t(
            "views.companies.inquiry_payments.create.cant_change_document_after_create"
          )
        }}</be-alert>

        <document-link
          v-if="fileWasUploaded"
          :document-id="paymentDocument[0].id"
          :filename="paymentDocument[0].filename"
        />
      </div>

      <div v-else>
        <document-uploader
          id="payment_document"
          name="payment_document"
          attachment-name="payment_document"
          :accepted-types="['pdf']"
          :documents="paymentDocument"
          :max-files="1"
          support-select-from-archive
          @document-added="documentAdded"
          @document-removed="documentRemoved"
          @uploading="(value) => (uploading = value)"
        />
      </div>
    </be-form-group>

    <payment-planner v-if="form.inquiry.inquiry_id" />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import CreateInquiryMixin from "@/mixins/inquiries/createInquiry";
import TransactionTypes from "@/components/inquiry_payments/components/transactions/TransactionTypes.vue";
import TransactionInstructions from "@/components/inquiry_payments/components/transactions/TransactionInstructions.vue";
import PaymentPlanner from "@/components/inquiry_payments/components/PaymentPlanner.vue";
import debounce from "lodash/debounce";

export default {
  components: {
    TransactionTypes,
    TransactionInstructions,
    PaymentPlanner,
  },

  mixins: [CreateInquiryMixin],

  computed: {
    fileWasUploaded() {
      return this.form.payment?.document?.id;
    },

    paymentDocument() {
      if (this.form.payment?.document) {
        return [this.form.payment.document];
      } else {
        return [];
      }
    },
  },

  methods: {
    ...mapActions("inquiries", ["validateInquiryPayment"]),

    async handleValidateInquiryPayment() {
      this.clearStates();

      if (!this.form.inquiry.inquiry_id) {
        return;
      }

      const inquiryPayment = {
        inquiry_id: this.form.inquiry.inquiry_id,
        ...this.form.payment,
        id: this.form.id,
        skip_validation_field: this.skipValidationField,
      };

      await this.validateInquiryPayment(inquiryPayment);
    },

    clearStates() {
      this.clearErrors(
        this.form.payment,
        "inquiry_receiver.receiver_identifier"
      );
    },

    debouncedValidation: debounce(function () {
      this.handleValidateInquiryPayment();
    }, 1000),

    documentAdded(document) {
      this.form.payment.document = document;
    },

    documentRemoved() {
      this.form.payment.document = {};
    },
  },
};
</script>
