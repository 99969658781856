<template>
  <div>
    <be-alert
      v-if="
        shareholderRegister.uploaded_type_present &&
        !shareholderRegister.legal_types_present
      "
      variant="warning"
    >
      {{
        $t(
          "components.shareholder_registers.shareholder_register.uploaded_shareholder_list_exists"
        )
      }}
    </be-alert>

    <div v-if="transactions.length > 0">
      <div class="row">
        <div class="col-12 col-md-auto">
          <be-form-group
            :label="
              $t(
                'activerecord.attributes.shareholder_register_action.action_date'
              )
            "
            :description="
              $t(
                'components.shareholder_registers.shareholder_register_tabs.action_date_description'
              )
            "
          >
            <be-form-select
              v-model="selectedDate"
              :options="sortedSummaryDates"
              required
              @change="fetchSummary"
            />
          </be-form-group>
        </div>
      </div>

      <h6>
        {{
          $t("components.shareholder_registers.shareholder_register_tabs.pdf")
        }}
      </h6>

      <be-skeleton-table
        v-if="loading || loadingReport"
        :rows="1"
        :columns="1"
        :table-props="{ striped: true }"
        class="mb-3"
        hide-header
      />

      <div v-else class="pl-0 mb-2 d-print-none table-responsive">
        <table class="table align-top mb-3">
          <tbody>
            <tr>
              <td class="col-shrink">
                <document-link
                  v-if="registerDocument"
                  :document-id="registerDocument.id"
                  :filename="registerDocument.filename"
                  :title="`${$t('activerecord.attributes.shareholder_register.title')} (${selectedDate})`"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h6>{{ $t("activerecord.models.shareholder_register.one") }}</h6>

      <be-skeleton-table
        v-if="loading"
        :rows="5"
        :columns="3"
        :table-props="{ striped: true }"
        hide-header
      />

      <series-table
        v-for="(series, index) in summaries[selectedDate]"
        v-else
        :key="'series-table-series' + index"
        :selected-date="selectedDate"
        :series="series"
        :quota="series['quota']"
        class="mb-3"
      />

      <template v-if="actionDocuments.length > 0">
        <hr class="border-dashed mt-5 mb-4" />

        <h6 class="mt-3">
          {{
            $t(
              "components.shareholder_registers.shareholder_register.documents_from_actions"
            )
          }}
        </h6>

        <div class="col-12 pl-0 d-print-none">
          <table class="table table-bordered align-top">
            <tbody>
              <tr
                v-for="actionDocument in actionDocuments"
                :key="`action-document-${actionDocument.id}`"
                class="fields document_fields"
              >
                <td colspan="2">
                  <document-link
                    :document-id="actionDocument.id"
                    :filename="actionDocument.filename"
                    :title="actionDocument.title"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>

    <div v-else>
      <h2>{{ $t("activerecord.attributes.shareholder_register.title") }}</h2>

      <div v-if="shareholderRegister.policy.update" class="row">
        <div class="col-12 col-lg-6">
          <div class="mb-3">
            <p>
              {{
                $t(
                  "components.shareholder_registers.shareholder_register.description_1"
                )
              }}
            </p>

            <p>
              {{
                $t(
                  "components.shareholder_registers.shareholder_register.description_2"
                )
              }}
            </p>
          </div>

          <div>
            <be-button
              v-be-tooltip="{
                title: $t(
                  'components.shareholder_registers.new_actions_disabled'
                ),
                disabled: !disabled,
              }"
              :href="url('/shareholder_register/actions/new')"
              :disabled="disabled"
              variant="outline-primary"
            >
              {{ $t("models.shareholder_register_action.new") }}
            </be-button>
          </div>
        </div>

        <div class="d-none d-lg-block col-lg-6">
          <be-img
            :src="
              imageSrc(
                'onboarding/intro/shareholder_register/shareholder_register.png'
              )
            "
          />
        </div>
      </div>

      <div v-else class="row">
        <div class="col-12 col-lg-6">
          <div class="row action-row">
            <div class="col-12 mb-1">
              <p>
                {{
                  $t(
                    "components.shareholder_registers.shareholder_register.description_1"
                  )
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="d-none d-lg-block col-12 col-lg-6">
          <be-img
            :src="
              imageSrc(
                'onboarding/intro/shareholder_register/shareholder_register.png'
              )
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeriesTable from "./SeriesTable.vue";
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";

export default {
  components: {
    SeriesTable,
  },

  data() {
    return {
      loading: false,
      loadingReport: true,
      selectedDate: null,
      poller: null,
    };
  },

  computed: {
    ...mapGetters({
      shareholderRegister: "shareholder_register/shareholderRegister",
      summaries: "shareholder_register/summaries",
      transactions: "shareholder_register/transactions",
    }),

    actionsPresent() {
      return (
        this.shareholderRegister.shareholder_register_actions &&
        this.shareholderRegister.shareholder_register_actions.length > 0
      );
    },

    sortedSummaryDates() {
      return Object.keys(this.summaries).sort((a, b) => Number(a) - Number(b));
    },

    disabled() {
      return !this.shareholderRegister.policy.create_action;
    },

    actionDocuments() {
      let documents = [];
      this.shareholderRegister.shareholder_register_actions.forEach(
        (action) => {
          if (action.documents) {
            action.documents.forEach((actionDocument) => {
              documents.push(actionDocument);
            });
          }
        }
      );

      return documents;
    },

    registerDocument() {
      return this.shareholderRegister.documents.find((document) => {
        return (
          document.key &&
          document.key.startsWith(`shareholder_register-${this.selectedDate}`)
        );
      });
    },
  },

  created() {
    this.selectedDate = this.summaries ? this.sortedSummaryDates[0] : null;
  },

  mounted() {
    if (this.actionsPresent) {
      this.pollForReport();

      EventBus.on("shareholder-register-changed", this.pollForReport);
    }
  },

  methods: {
    async fetchSummary() {
      if (Object.keys(this.summaries[this.selectedDate]).length == 0) {
        this.loading = true;

        try {
          const response = await this.$store.dispatch(
            "shareholder_register/loadSummary",
            this.selectedDate
          );

          if (response) {
            Object.values(this.summaries[this.selectedDate]).forEach(
              (series) => {
                this.$store.dispatch(
                  "shareholder_register/loadTransactionHistory",
                  {
                    series: series,
                    selectedDate: this.selectedDate,
                  }
                );
              }
            );
          }
        } catch (error) {
          this.handleError(error);
        } finally {
          this.loading = false;
        }
      }
    },

    pollForReport() {
      if (!this.poller) {
        this.poller = setInterval(this.poll, 3000);
      }
    },

    async poll() {
      try {
        // actionsPresent needs to be checked in here as well since the event will trigger
        // even if it's in an if statement thats false
        if (!this.actionsPresent) {
          clearInterval(this.poller);
          this.poller = null;
          this.loadingReport = false;
        }

        this.loadingReport = true;

        let response = await axios.get(
          this.url("/shareholder_register/report_generation/status")
        );

        if (response.status === 204) {
          // Processing
          return;
        }

        if (response.status == 200) {
          this.$store.commit(
            "shareholder_register/updateDocument",
            response.data
          );

          clearInterval(this.poller);
          this.poller = null;
          this.loadingReport = false;
        }
      } catch (error) {
        if (error?.response?.status === "422") {
          clearInterval(this.poller);
          this.poller = null;
          this.loadingReport = false;
        } else {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
