import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    dimensions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      form: "inquiries/getNewInquiryForm",
    }),
  },

  methods: {
    ...mapActions({
      createInquiry: "inquiries/createInquiry",
      setNewInquiryForm: "inquiries/setNewInquiryForm",
      updateInquiry: "inquiries/updateInquiry",
    }),

    ...mapMutations("inquiries", ["resetNewInquiryForm"]),

    initInquiryForm(inquiry) {
      // If we dont get a provided object, set a default inquiry object
      if (!inquiry) {
        inquiry = {
          inquiry: {
            applicant_email: "",
            applicant_identifier: "",
            approved_at: "",
            title: "",
            granted_value: "",
            currency: "SEK",
            notes: "",
            requisition: "",
            dimension_entry_ids: [],

            inquiry_receiver: {
              name: "",
              receiver_identifier: "",
            },

            payment: {
              pay_at: "",
              amount_money: 0,
              reference: "",
              reference_type: "ocr",

              inquiry_receiver: {
                name: "",
                receiver_identifier: "",
                transaction_type: "unknown",
                bankgiro_number: "",
                plusgiro_number: "",
                iban: "",
                bic: "",
                bank_account_number: "",
              },
            },

            inquiry_id: "",
          },

          document: {},
        };
      }

      // Set the inquiry form state with inquiry object
      this.setNewInquiryForm(inquiry);
    },
  },

  data() {
    return {
      transaction_instructions_fields: {
        bankgiro: {
          bankgiro_number: "",
        },

        plusgiro: {
          plusgiro_number: "",
        },

        international_transfer: {
          iban: "",
          bic: "",
        },

        bank_transfer: {
          bank_account_number: "",
        },
      },

      formStepValidation: {
        informationStep: false,
        approvalStep: true,
      },
    };
  },
};
