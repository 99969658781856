/**
 * Hashtag and mention wrapper for the TextEditor component
 */
import "quill-mention";
import Quill from "quill";

import { mapGetters } from "vuex";
import materialState from "@/components/meetings/material/materialState";
import HashtagBlot from "@/vendor/quill/be-hashtag-blot";
Quill.register(HashtagBlot, true);

import Config from "@/config.js";

import { toSentence } from "@/utils/text-utils";

export default {
  mixins: [materialState],

  data() {
    return {
      hashtagRefreshKey: this.generateUuid(),
    };
  },

  computed: {
    ...mapGetters("material", ["editorType", "meeting"]),
    ...mapGetters("attendances", ["attendances"]),

    beMentionQuillOptions() {
      return {
        mention: {
          allowedChars: /^[A-Za-zÅÄÖåäö]*$/,
          mentionDenotationChars: ["#"],
          isolateCharacter: true,
          spaceAfterInsert: false,

          dataAttributes: [
            "id",
            "value",
            "placeholder",
            "denotationChar",
            "link",
            "target",
            "disabled",
          ],

          blotName: "hashtag-blot",

          renderItem(item) {
            return `#${item.placeholder}`;
          },

          source: (searchTerm, renderList) => {
            // Match against `id`, `sv` and `en`
            const matchedHashtag = Object.values(this.hashtagMap).filter(
              (hashtag) =>
                [
                  hashtag.id.toLowerCase(),
                  hashtag.sv.toLowerCase(),
                  hashtag.en.toLowerCase(),
                ].some((match) => match.includes(searchTerm.toLowerCase()))
            );

            renderList(matchedHashtag);
          },

          onSelect(item, insertItem) {
            item.replaced = item.value != item.placeholder;

            insertItem(item);
          },
        },
      };
    },

    hashtagMap() {
      const values = {};

      Config.MATERIAL_HASHTAGS.forEach((data) => {
        const hashtag = {
          ...data,
          placeholder: this.$currentCompany.locale == "en" ? data.en : data.sv,
        };

        hashtag.value = this.hashtagValue(hashtag);
        values[data.id] = hashtag;
      });

      return values;
    },

    chairmanHashtag() {
      return this.attendances.find(
        (attendance) => attendance.function === "chairman"
      )?.name;
    },

    secretaryHashtag() {
      return this.attendances.find((attendance) => attendance.secretary)?.name;
    },

    reviewerHashtag() {
      const results = this.attendances.filter(
        (attendance) =>
          attendance.reviewer && attendance.function !== "chairman"
      );
      if (results.length > 0) {
        return this.formatNames(results.map((reviewer) => reviewer.name));
      }
    },

    reviewerIncludingChairmanHashtag() {
      const results = this.attendances.filter(
        (attendance) => attendance.reviewer
      );
      if (results.length > 0) {
        return this.formatNames(results.map((reviewer) => reviewer.name));
      }
    },

    ceoHashtag() {
      const results = this.$currentCompany.memberships.filter(
        (membership) => membership.role === "ceo"
      );
      if (results.length > 0) {
        return this.formatNames(results.map((result) => result.user.name));
      }
    },

    cfoHashtag() {
      const results = this.$currentCompany.memberships.filter(
        (membership) => membership.role === "cfo"
      );
      if (results.length > 0) {
        return this.formatNames(results.map((result) => result.user.name));
      }
    },

    nextmeetingHashtag() {
      if (this.meeting?.next_start_at) {
        return this.$i18n.d(
          new Date(this.meeting?.next_start_at),
          "humanReadableWithTime"
        );
      }
    },
  },

  watch: {
    attendances: {
      handler() {
        this.refreshQuillHashtags();
      },

      deep: true,
    },

    hashtagRefreshKey() {
      this.refreshQuillHashtags(true);
    },
  },

  methods: {
    refreshQuillHashtags(force = false) {
      // Never replace in template or tiptap mode
      if (this.isTemplate || this.material.use_tiptap_text) {
        return;
      }
      // Don't replace in template mode or when minutes doesn't have any attendances
      if (this.isMinutes && this.attendances.length == 0 && !force) {
        return;
      }

      this.refreshQuillMentions();
    },

    isValid(tag) {
      return Config.MATERIAL_HASHTAGS.find((hashtag) => hashtag.id === tag);
    },

    hashtagValue(hashtag) {
      switch (hashtag.id) {
        case "chairman":
          return this.chairmanHashtag || hashtag.placeholder;
        case "secretary":
          return this.secretaryHashtag || hashtag.placeholder;
        case "reviewer":
          return this.reviewerHashtag || hashtag.placeholder;
        case "reviewer_including_chairman":
          return this.reviewerIncludingChairmanHashtag || hashtag.placeholder;
        case "ceo":
          return this.ceoHashtag || hashtag.placeholder;
        case "financialmanager":
          return this.cfoHashtag || hashtag.placeholder;
        case "next_meeting":
          return this.nextmeetingHashtag || hashtag.placeholder;
        default:
          return hashtag.id;
      }
    },

    formatNames(names) {
      const locale = this.$currentCompany.locale;

      return toSentence(names, locale);
    },

    refreshQuillMentions() {
      const parser = new DOMParser();
      let bodyHtml = parser.parseFromString(this.body, "text/html");
      bodyHtml.body.innerHTML = this.replaceLegacyHashtags(
        bodyHtml.body.innerHTML
      );
      const mentions = bodyHtml.querySelectorAll("span.mention");

      mentions.forEach((mention) => {
        const dataset = mention.dataset;
        const mentionDenotationChar = dataset.denotationChar;
        const mentionId = dataset.id;

        if (mentionDenotationChar === "#") {
          const hashtag = this.hashtagMap[mentionId];

          // Refresh Hashtags
          if (hashtag) {
            mention.dataset.value = hashtag.value;

            mention.dataset.replaced =
              mention.dataset.value != hashtag.placeholder;
          } else {
            console.warn(`Couldn't find hashtag with ID ${mentionId}`);
          }
        }
      });

      // Set the content for Quill
      let index = 0;
      if (this.quill) {
        index = this.quill.getSelection()?.index || 0;
      }
      this.body = bodyHtml.body.innerHTML;
      this.quill?.pasteHTML(bodyHtml.body.innerHTML);
      this.quill?.setSelection(index);
    },

    replaceLegacyHashtags(text) {
      return text.replace(/\B#[A-Za-zÅÄÖåäö]*\b/g, (match) => {
        const hashtagReference = match.replace("#", "");
        const hashtag = Object.values(this.hashtagMap).find(
          (hashtag) =>
            hashtag.id === hashtagReference ||
            hashtag.sv === hashtagReference ||
            hashtag.en === hashtagReference
        );

        if (hashtag) {
          return `<span class="mention" data-type="mention" data-denotation-char="#" data-id="${hashtag.id}" data-legacy="true">
              ${hashtag.value}
            </span>`;
        } else {
          // If a matched hashtag is not supported by the system,
          // return the hashtag as normal text.
          return match;
        }
      });
    },
  },
};
