/**
 * Load Rails Unobtrusive JavaScript (Rails UJS) in order to handle links
 * with attached data-methods.
 */
import Rails from "@rails/ujs";
import Config from "@/config.js";
import translations from "@/translations.js";
import ConfirmPlugin from "@/helpers/confirm.js";
import { handleFlashes } from "@/axios-flash-interceptor";
import { handleError } from "@/utils/error-handling";

const allowAction = (element) => {
  if (element.getAttribute("data-confirm") !== null) {
    promptConfirm(element);
    return false;
  } else {
    return true;
  }
};

const extractAttributes = (element) => {
  return {
    title: element.getAttribute("data-confirm"),
    description: element.getAttribute("data-description"),
    method: element.getAttribute("data-method"),
    variant: element.getAttribute("data-variant") || "primary",
    confirmButtonText: element.getAttribute("data-confirm-button-text"),
    cancelButtonText: element.getAttribute("data-cancel-button-text"),
    confirmWithInput: element.getAttribute("data-confirm-with-input"),
    stayOpenAfterConfirm: element.getAttribute("data-stay-open-after-confirm"),
  };
};

const getLocale = () => {
  return (
    document.getElementById(Config.ROOT_ELEMENT_ID).parentElement.lang ||
    Config.DEFAULT_LOCALE
  );
};

// Prompt the user to confirm an action
const promptConfirm = async (element) => {
  const options = extractAttributes(element);
  const locale = getLocale();

  if (options.method?.toLowerCase() === "delete") {
    options.variant = "danger";
    options.confirmButtonText =
      translations.messages[locale].buttons.titles.remove;
  }

  const isConfirmed = await ConfirmPlugin.mountConfirmModal(options);

  if (isConfirmed) {
    confirmed(element, isConfirmed);
  }
};

const confirmed = (element, response) => {
  if (response) {
    // User clicked confirm button
    element.removeAttribute("data-confirm");
    element.click();
  }
};

Rails.confirm = (message, element) => {
  allowAction(element);
};

Rails.start();

// Handle notices
document.addEventListener("ajax:complete", function (event) {
  let flashes = event.detail[0].getResponseHeader("X-Ajax-Flashes");

  try {
    handleFlashes(flashes);
  } catch (error) {
    handleError(error, { sentryLog: false });
  }
});
