<template>
  <ul
    v-bind="computedWrapperAttrs"
    :id="localId"
    :class="computedWrapperClasses"
    @keydown="onKeyDown"
  >
    <!-- First button -->
    <li
      v-if="!hideEndButtons && !firstButtonNumber"
      v-bind="firstButtonProps.li"
    >
      <component
        v-bind="firstButtonProps.button"
        :is="firstButtonProps.button.is"
        @click="onClick($event, 1)"
      >
        <slot name="first-button-text">
          <i v-if="!firstButtonText" class="fas fa-angle-double-left fa-sm" />
          {{ firstButtonText }}
        </slot>
      </component>
    </li>

    <!-- Previous button -->
    <li v-bind="previousButtonProps.li">
      <component
        v-bind="previousButtonProps.button"
        :is="previousButtonProps.button.is"
        @click="onClick($event, computedCurrentPage - 1)"
      >
        <slot name="previous-button-text">
          <i v-if="!firstButtonText" class="fas fa-angle-left fa-sm" />
          {{ previousButtonText }}
        </slot>
      </component>
    </li>

    <!-- First number button -->
    <li
      v-if="
        !hideEndButtons && paginationParams.showFirstDots && firstButtonNumber
      "
      v-bind="firstButtonProps.li"
    >
      <component
        v-bind="firstButtonProps.button"
        :is="firstButtonProps.button.is"
        data-page="1"
        @click="onClick($event, 1)"
      >
        <slot name="first-button-text">1</slot>
      </component>
    </li>

    <!-- Ellipsis -->
    <li v-if="paginationParams.showFirstDots" v-bind="ellipsisProps.li">
      <span v-bind="ellipsisProps.span">
        <slot name="ellipsis-text">{{ ellipsisText || "..." }}</slot>
      </span>
    </li>

    <!-- Main -->
    <li
      v-for="page in pages"
      :key="`page-${page.number}`"
      :class="[
        'page-item',
        {
          disabled,
          active: page.number === computedCurrentPage,
          'flex-fill': align === 'fill',
          'd-flex': align === 'fill' && !disabled,
        },
        pageButtonClass,
        page.classes,
      ]"
      role="presentation"
      :aria-hidden="disabled || undefined"
    >
      <component
        :is="disabled ? 'span' : 'button'"
        :class="[
          'page-link',
          'text-numeric',
          { 'flex-grow-1': !disabled && align === 'fill' },
        ]"
        :aria-controls="ariaControls || null"
        :aria-disabled="disabled ? 'true' : null"
        :aria-label="
          computedAriaLabelPage
            ? `${computedAriaLabelPage} ${page.number}`
            : null
        "
        :aria-posinset="page.number"
        :aria-checked="page.number === computedCurrentPage"
        :aria-setsize="localNumberOfPages"
        role="menuitemradio"
        :type="disabled ? null : 'button'"
        :tabindex="
          disabled ? null : page.number === computedCurrentPage ? '0' : '-1'
        "
        :data-page="page.number"
        @click="onClick($event, page.number)"
      >
        <slot
          name="page"
          :active="page.number === computedCurrentPage"
          :disabled="disabled"
          :page="page.number"
          :index="page.number - 1"
          :content="page.number"
        >
          {{ page.number }}
        </slot>
      </component>
    </li>

    <!-- Ellipsis -->
    <li v-if="paginationParams.showLastDots" v-bind="ellipsisProps.li">
      <span v-bind="ellipsisProps.span">
        <slot name="ellipsis-text">{{ ellipsisText || "..." }}</slot>
      </span>
    </li>

    <!-- Last number button -->
    <li
      v-if="
        !hideEndButtons && paginationParams.showLastDots && lastButtonNumber
      "
      v-bind="lastButtonProps.li"
    >
      <component
        v-bind="lastButtonProps.button"
        :is="lastButtonProps.button.is"
        :data-page="localNumberOfPages"
        @click="onClick($event, localNumberOfPages)"
      >
        <slot name="last-button-text">{{ localNumberOfPages }}</slot>
      </component>
    </li>

    <!-- Next button -->
    <li v-bind="nextButtonProps.li">
      <component
        v-bind="nextButtonProps.button"
        :is="nextButtonProps.button.is"
        @click="onClick($event, computedCurrentPage + 1)"
      >
        <slot name="next-button-text">
          <i v-if="!firstButtonText" class="fas fa-angle-right fa-sm" />
          {{ nextButtonText }}
        </slot>
      </component>
    </li>

    <!-- Last button -->
    <li v-if="!hideEndButtons && !lastButtonNumber" v-bind="lastButtonProps.li">
      <component
        v-bind="lastButtonProps.button"
        :is="lastButtonProps.button.is"
        @click="onClick($event, localNumberOfPages)"
      >
        <slot name="last-button-text">
          <i v-if="!firstButtonText" class="fas fa-angle-double-right fa-sm" />
          {{ lastButtonText }}
        </slot>
      </component>
    </li>
  </ul>
</template>

<script>
import {
  KEY_CODE_DOWN,
  KEY_CODE_ENTER,
  KEY_CODE_LEFT,
  KEY_CODE_RIGHT,
  KEY_CODE_SPACE,
  KEY_CODE_UP,
} from "@/constants/key-codes";
import { generateId } from "@/utils/id";

// Constants
const DEFAULT_LIMIT = 5;
const DEFAULT_PER_PAGE = 20;
const DEFAULT_TOTAL_ROWS = 0;
const ELLIPSIS_THRESHOLD = 3;

// Helper methods
const sanitizeCurrentPage = (value, numberOfPages) => {
  const page = Number(value) || 1;

  if (page > numberOfPages) {
    return numberOfPages;
  } else if (page < 1) {
    return 1;
  } else {
    return page;
  }
};
const sanitizeLimit = (value) => {
  const limit = Number(value) || 1;
  return limit < 1 ? DEFAULT_LIMIT : limit;
};
const sanitizePerPage = (value) =>
  Math.max(Number(value) || DEFAULT_PER_PAGE, 1);
const sanitizeTotalRows = (value) =>
  Math.max(Number(value) || DEFAULT_TOTAL_ROWS, 0);

const applyClassesToArray = ({
  array,
  classes,
  index,
  length,
  incrementing = true,
}) => {
  if (incrementing) {
    for (let i = index; i < length; i++) {
      if (array[i]) {
        array[i].classes = classes;
      }
    }
  } else {
    for (let i = index; i > length; i--) {
      if (array[i]) {
        array[i].classes = classes;
      }
    }
  }

  return array;
};

export default {
  name: "BePagination",

  props: {
    align: {
      type: String,
      required: false,
      default: "left",

      validator: (value) => {
        return ["left", "center", "right", "fill"].includes(value);
      },
    },

    ariaControls: {
      type: String,
      required: false,
      default: undefined,
    },

    ariaLabel: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.be_pagination.pagination")
    },

    ariaLabelFirstPage: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.be_pagination.go_to_first_page")
    },

    ariaLabelLastPage: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.be_pagination.go_to_last_page")
    },

    ariaLabelNextPage: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.be_pagination.go_to_next_page")
    },

    ariaLabelPage: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.be_pagination.go_to_page")
    },

    ariaLabelPreviousPage: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.be_pagination.go_to_previous_page")
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    ellipsisClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    ellipsisText: {
      type: String,
      required: false,
      default: "...",
    },

    firstButtonClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    firstButtonNumber: {
      type: Boolean,
      required: false,
      default: false,
    },

    firstButtonText: {
      type: String,
      required: false,
      default: undefined,
    },

    hideEllipsis: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideEndButtons: {
      type: Boolean,
      required: false,
      default: false,
    },

    lastButtonClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    lastButtonNumber: {
      type: Boolean,
      required: false,
      default: false,
    },

    lastButtonText: {
      type: String,
      required: false,
      default: undefined,
    },

    limit: {
      type: [Number, String],
      required: false,
      default: 5,
    },

    modelValue: {
      type: [Boolean, Number, String],
      required: true,
    },

    nextButtonClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    nextButtonText: {
      type: String,
      required: false,
      default: undefined,
    },

    pageButtonClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    perPage: {
      type: [Number, String],
      required: false,
      default: DEFAULT_PER_PAGE,
    },

    previousButtonClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    previousButtonText: {
      type: String,
      required: false,
      default: undefined,
    },

    size: {
      type: String,
      required: false,
      default: undefined,

      validator: (value) => {
        return ["sm", "lg"].includes(value);
      },
    },

    totalRows: {
      type: [Number, String],
      required: false,
      default: DEFAULT_TOTAL_ROWS,
    },
  },

  emits: ["change", "update:modelValue"],

  data() {
    // `-1` signified no page initially selected
    let currentPage = Number(this.modelValue);
    currentPage = currentPage > 0 ? currentPage : -1;

    return {
      currentPage,
      localNumberOfPages: 1,
      localLimit: DEFAULT_LIMIT,
      localId: generateId("pagination"),
    };
  },

  computed: {
    alignment() {
      const { align } = this;

      switch (align) {
        case "center":
          return "justify-content-center";

        case "right":
          return "justify-content-end";

        case "fill":
          // The page items will also have `flex-fill` added
          // We add text centering to make the button appearance better in fill mode
          return "text-center";

        default:
          return "";
      }
    },

    btnSize() {
      return this.size ? `pagination-${this.size}` : "";
    },

    computedAriaLabelPage() {
      return (
        this.ariaLabelPage ||
        this.$t("components.shared.be_pagination.go_to_page")
      );
    },

    computedCurrentPage() {
      return sanitizeCurrentPage(this.currentPage, this.localNumberOfPages);
    },

    computedWrapperAttrs() {
      return {
        role: "menubar",
        "aria-disabled": this.disabled ? "true" : null,

        "aria-label":
          this.ariaLabel ||
          this.$t("components.shared.be_pagination.pagination"),
      };
    },

    computedWrapperClasses() {
      return [
        "pagination",
        this.alignment,
        {
          [`pagination-${this.size}`]: this.size,
        },
      ];
    },

    ellipsisProps() {
      return {
        li: {
          class: [
            "page-item",
            "disabled",
            "d-none",
            "d-sm-block",
            this.align === "fill" ? "flex-fill" : "",
            this.ellipsisClass,
          ],

          role: "separator",
        },

        span: {
          class: ["page-link"],
        },
      };
    },

    firstButtonProps() {
      const disabled = this.disabled || this.currentPage <= 1;
      const ariaLabelFirstPage =
        this.ariaLabelFirstPage ||
        this.$t("components.shared.be_pagination.go_to_first_page");

      return this.buildButtonProps(
        disabled,
        this.firstButtonClass,
        ariaLabelFirstPage,
        "first"
      );
    },

    lastButtonProps() {
      const disabled =
        this.disabled || this.currentPage >= this.localNumberOfPages;
      const ariaLabelLastPage =
        this.ariaLabelLastPage ||
        this.$t("components.shared.be_pagination.go_to_last_page");

      return this.buildButtonProps(
        disabled,
        this.lastButtonClass,
        ariaLabelLastPage,
        "last"
      );
    },

    nextButtonProps() {
      const disabled =
        this.disabled || this.currentPage >= this.localNumberOfPages;
      const ariaLabelNextPage =
        this.ariaLabelNextPage ||
        this.$t("components.shared.be_pagination.go_to_next_page");

      return this.buildButtonProps(
        disabled,
        this.nextButtonClass,
        ariaLabelNextPage,
        "next"
      );
    },

    numberOfPages() {
      const result = Math.ceil(
        sanitizeTotalRows(this.totalRows) / sanitizePerPage(this.perPage)
      );
      return result < 1 ? 1 : result;
    },

    // Used for watching changes to `perPage` and `numberOfPages`
    pageSizeNumberOfPages() {
      return {
        perPage: sanitizePerPage(this.perPage),
        totalRows: sanitizeTotalRows(this.totalRows),
        numberOfPages: this.numberOfPages,
      };
    },

    // Generates the pages array
    pages() {
      const { numberOfLinks, startNumber } = this.paginationParams;
      const currentPage = this.computedCurrentPage;
      let pages = Array.from({ length: numberOfLinks }, (_, index) => ({
        number: startNumber + index,
        classes: "",
      }));

      // We limit to a total of 3 page buttons on XS screens,
      // by adding classes to page links to hide them for the XS breakpoint.
      // Note: Ellipsis will also be hidden on XS screens.
      if (pages.length > 3) {
        const index = currentPage - startNumber;
        const classes = "d-none d-sm-flex";

        if (index === 0) {
          // Keep leftmost 3 buttons visible when current page is first page
          pages = applyClassesToArray({
            array: pages,
            classes,
            index: 3,
            length: pages.length,
          });
        } else if (index === pages.length - 1) {
          // Keep rightmost 3 buttons visible when current page is last page
          pages = applyClassesToArray({
            array: pages,
            classes,
            index: 0,
            length: pages.length - 3,
          });
        } else {
          // Hide all but current page, current page - 1 and current page + 1
          pages = applyClassesToArray({
            array: pages,
            classes,
            index: 0,
            length: index - 1,
          });
          pages = applyClassesToArray({
            array: pages,
            classes,
            index: pages.length,
            length: index + 1,
            incrementing: false,
          });
        }
      }

      return pages;
    },

    // This logic is heavily inspired by the logic from BootstrapVue's pagination component.
    // We could probably refactor this in the future. I've tried to make it clearer with comments.
    paginationParams() {
      const {
        localLimit: limit,
        localNumberOfPages: numberOfPages,
        computedCurrentPage: currentPage,
        hideEllipsis,
        firstButtonNumber,
        lastButtonNumber,
      } = this;

      let showFirstDots = false;
      let showLastDots = false;
      let numberOfLinks = limit;
      let startNumber = 1;

      if (numberOfPages <= limit) {
        // Less pages available than the limit of displayed pages
        numberOfLinks = numberOfPages;
      } else if (currentPage < limit - 1 && limit > ELLIPSIS_THRESHOLD) {
        // We are on the first pages

        if (!hideEllipsis || lastButtonNumber) {
          // If we are not hiding the ellipsis or we are showing the last number
          showLastDots = true;
          numberOfLinks = limit - (firstButtonNumber ? 0 : 1);
        }

        numberOfLinks = Math.min(numberOfLinks, limit);
      } else if (
        numberOfPages - currentPage + 2 < limit &&
        limit > ELLIPSIS_THRESHOLD
      ) {
        // We are on the last pages

        if (!hideEllipsis || firstButtonNumber) {
          // If we are not hiding the ellipsis or we are showing the first number
          showFirstDots = true;
          numberOfLinks = limit - (lastButtonNumber ? 0 : 1);
        }

        startNumber = numberOfPages - numberOfLinks + 1;
      } else {
        // We are somewhere in the middle

        if (limit > ELLIPSIS_THRESHOLD) {
          numberOfLinks = limit - (hideEllipsis ? 0 : 2);
          showFirstDots = !!(!hideEllipsis || firstButtonNumber);
          showLastDots = !!(!hideEllipsis || lastButtonNumber);
        }

        startNumber = currentPage - Math.floor(numberOfLinks / 2);
      }

      // Ensure `startNumber` is a valid value
      if (startNumber < 1) {
        startNumber = 1;
        showFirstDots = false;
      } else if (startNumber > numberOfPages - numberOfLinks) {
        startNumber = numberOfPages - numberOfLinks + 1;
        showLastDots = false;
      }

      // Adjust `numberOfLinks` if we are showing the first or last number
      if (showFirstDots && firstButtonNumber && startNumber < 4) {
        numberOfLinks = numberOfLinks + 2;
        startNumber = 1;
        showFirstDots = false;
      }

      const lastPageNumber = startNumber + numberOfLinks - 1;
      if (
        showLastDots &&
        lastButtonNumber &&
        lastPageNumber > numberOfPages - 3
      ) {
        numberOfLinks =
          numberOfLinks + (lastPageNumber === numberOfPages - 2 ? 2 : 3);
        showLastDots = false;
      }

      // Handling for lower limits (where ellipsis are not shown)
      if (limit <= ELLIPSIS_THRESHOLD) {
        if (firstButtonNumber && startNumber === 1) {
          numberOfLinks = Math.min(numberOfLinks + 1, numberOfPages, limit + 1);
        } else if (
          lastButtonNumber &&
          numberOfPages === startNumber + numberOfLinks - 1
        ) {
          startNumber = Math.max(startNumber - 1, 1);
          numberOfLinks = Math.min(
            numberOfPages - startNumber + 1,
            numberOfPages,
            limit + 1
          );
        }
      }

      numberOfLinks = Math.min(numberOfLinks, numberOfPages - startNumber + 1);

      return {
        showFirstDots,
        showLastDots,
        numberOfLinks,
        startNumber,
      };
    },

    previousButtonProps() {
      const disabled = this.disabled || this.currentPage <= 1;
      const ariaLabelPreviousPage =
        this.ariaLabelPreviousPage ||
        this.$t("components.shared.be_pagination.go_to_previous_page");

      return this.buildButtonProps(
        disabled,
        this.previousButtonClass,
        ariaLabelPreviousPage,
        "previous"
      );
    },
  },

  watch: {
    currentPage(newValue, oldValue) {
      if (newValue !== oldValue) {
        // Emit `null` if no page is selected
        this.$emit("update:modelValue", newValue > 0 ? newValue : null);
      }
    },

    limit(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.localLimit = sanitizeLimit(newValue);
      }
    },

    pageSizeNumberOfPages(newValue, oldValue) {
      if (oldValue) {
        if (
          newValue.perPage !== oldValue.perPage &&
          newValue.totalRows === oldValue.totalRows
        ) {
          // If the page size changes, reset to page 1
          this.currentPage = 1;
        } else if (
          newValue.numberOfPages !== oldValue.numberOfPages &&
          this.currentPage > newValue.numberOfPages
        ) {
          // If `numberOfPages` changes and is less than
          // the `currentPage` number, reset to page 1
          this.currentPage = 1;
        }
      }

      this.localNumberOfPages = newValue.numberOfPages;
    },

    modelValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = sanitizeCurrentPage(
          newValue,
          this.localNumberOfPages
        );
      }
    },
  },

  created() {
    // Set the initial page count
    this.localNumberOfPages = this.numberOfPages;

    // Set the initial limit value
    this.localLimit = sanitizeLimit(this.limit);

    // Set the initial page value
    const currentPage = Number(this.modelValue);
    if (currentPage > 0) {
      this.currentPage = currentPage;
    } else {
      this.$nextTick(() => {
        // If this value parses to `NaN` or a value less than `1`
        // trigger an initial emit of `null` if no page specified
        this.currentPage = 0;
      });
    }

    // Sanity check
    this.$nextTick(() => {
      this.currentPage =
        this.currentPage > this.localNumberOfPages
          ? this.localNumberOfPages
          : this.currentPage;
    });
  },

  methods: {
    buildButtonProps(disabled, customClass, ariaLabel, direction) {
      return {
        li: {
          class: [
            "page-item",
            {
              disabled,
              "flex-fill": this.align === "fill",
              "d-flex": this.align === "fill" && !disabled, // This might not be needed
            },
            customClass,
          ],
        },

        button: {
          is: disabled ? "span" : "button",
          "aria-label": ariaLabel,
          "aria-controls": this.ariaControls || null,
          "aria-disabled": disabled ? "true" : null,
          role: "menuitem",
          type: disabled ? null : "button",
          tabindex: disabled ? "-1" : null,
          "data-direction": direction,

          class: [
            "page-link",
            { "flex-grow-1": !disabled && this.align === "fill" },
          ],
        },
      };
    },

    focusPageButton(pageNumber) {
      this.$nextTick(() => {
        const buttons = this.getButtons();
        const button = Array.from(buttons).find(
          (button) =>
            Number(button.getAttribute("aria-posinset")) === pageNumber
        );

        if (button) {
          button.focus();
        }
      });
    },

    // Focus the first pagination button
    focusFirst() {
      this.$nextTick(() => {
        const buttons = this.getButtons();
        if (buttons.length > 0) {
          buttons[0].focus();
        }
      });
    },

    // Focus the last pagination button
    focusLast() {
      this.$nextTick(() => {
        const buttons = this.getButtons();
        if (buttons.length > 0) {
          buttons[buttons.length - 1].focus();
        }
      });
    },

    // Focus the next pagination button
    focusNext() {
      const buttons = this.getButtons();
      const index = Array.from(buttons).findIndex(
        (button) => button === document.activeElement
      );

      if (buttons.length > 0 && index > -1 && index < buttons.length - 1) {
        buttons[index + 1].focus();
      }
    },

    // Focus the previous pagination button
    focusPrevious() {
      const buttons = this.getButtons();
      const index = Array.from(buttons).findIndex(
        (button) => button === document.activeElement
      );

      if (buttons.length > 0 && index > 0) {
        buttons[index - 1].focus();
      }
    },

    getButtons() {
      return document.querySelectorAll(
        `#${this.localId} .page-item:not(.disabled) .page-link`
      );
    },

    onClick(event, pageNumber) {
      if (pageNumber === this.currentPage) {
        return;
      }

      this.currentPage = pageNumber;
      this.$emit("change", this.currentPage);

      // If a goto button was clicked, focus the clicked button
      if (event.target.getAttribute("data-direction")) {
        // Focus the button that was clicked
        event.target.focus();
      } else {
        // Focus the page button
        this.focusPageButton(pageNumber);
      }
    },

    onKeyDown(event) {
      const { keyCode, shiftKey } = event;

      if (keyCode === KEY_CODE_LEFT || keyCode === KEY_CODE_UP) {
        event.preventDefault();

        if (shiftKey) {
          this.focusFirst();
        } else {
          this.focusPrevious();
        }
      } else if (keyCode === KEY_CODE_RIGHT || keyCode === KEY_CODE_DOWN) {
        event.preventDefault();

        if (shiftKey) {
          this.focusLast();
        } else {
          this.focusNext();
        }
      } else if (keyCode === KEY_CODE_SPACE || keyCode === KEY_CODE_ENTER) {
        event.preventDefault();
        this.onSpace(event);
      }
    },

    onSpace(event) {
      let pageNumber = Number(event.target.getAttribute("aria-posinset"));

      if (pageNumber === 0) {
        const direction = event.target.getAttribute("data-direction");

        if (direction === "first") {
          pageNumber = 1;
        } else if (direction === "last") {
          pageNumber = this.localNumberOfPages;
        } else if (direction === "previous") {
          pageNumber = this.currentPage - 1;
        } else if (direction === "next") {
          pageNumber = this.currentPage + 1;
        }
      }

      this.onClick(event, pageNumber);
    },
  },
};
</script>
