<template>
  <div v-show="!destroyed">
    <label>
      {{ $t("simple_form.labels.education_question_references.title") }}
    </label>

    <div class="row mb-2">
      <div class="col">
        <be-form-group :error="getErrors(reference, ['title'])">
          <be-form-input
            :model-value="title"
            :name="`education_question[references_attributes][${index}][title]`"
            @change="clearErrors(reference, ['title'])"
          />
        </be-form-group>

        <be-form-group
          :label="$t('simple_form.labels.education_question_references.url')"
          label-for="reference-url"
          :error="getErrors(reference, ['url'])"
        >
          <be-form-input
            id="reference-url"
            :model-value="referenceUrl"
            :name="`education_question[references_attributes][${index}][url]`"
            @change="clearErrors(reference, ['url'])"
          />
        </be-form-group>
      </div>

      <div class="col-auto d-flex justify-content-center">
        <be-button
          v-be-tooltip="$t('buttons.titles.remove')"
          variant="danger"
          size="sm"
          class="align-self-start"
          icon="fa-times"
          inline
          @click="removeReference()"
        />
      </div>
    </div>

    <input
      type="hidden"
      :value="destroyed"
      :name="`education_question[references_attributes][${index}][_destroy]`"
    />

    <input
      v-if="reference.id"
      type="hidden"
      :value="reference.id"
      :name="`education_question[references_attributes][${index}][id]`"
    />
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },

    reference: {
      type: Object,
      required: true,
    },

    question: {
      type: Object,
      required: true,
    },
  },

  emits: ["reference-removed"],

  data() {
    return {
      destroyed: 0,
      title: this.reference.title ? this.reference.title : null,
      referenceUrl: this.reference.url ? this.reference.url : null,
    };
  },

  methods: {
    removeReference() {
      this.destroyed = 1;
      this.$emit("reference-removed", this.reference);
    },
  },
};
</script>
