<template>
  <div class="mt-4">
    <div v-if="form.payment" class="card bg-gray mb-3">
      <div
        class="card-header bg-gray d-flex align-items-center justify-content-between"
      >
        <h5 class="card-title">
          {{ $t("views.companies.inquiry_payments.index.payout") }}
        </h5>
      </div>

      <div class="card-body">
        <div class="row mb-xl-3">
          <div class="col-12 col-xl">
            <be-form-group
              :label="
                $t('views.companies.inquiries.create.date_for_partial_payment')
              "
              class="mb-xl-0"
              required
              :error="getErrors(form.payment, 'pay_at')"
            >
              <be-form-datepicker
                v-model="form.payment.pay_at"
                required
                :min-date="new Date()"
                @input="debouncedValidation"
              />
            </be-form-group>
          </div>

          <div class="col-12 col-xl">
            <be-form-group
              :label="
                $t(
                  'views.companies.inquiries.create.amount_for_partial_payment'
                )
              "
              class="mb-xl-0"
              required
              :error="getErrors(form.payment, 'amount_money')"
            >
              <be-input-group :append="inquiry?.currency">
                <be-form-input
                  id="payout-amount"
                  v-model="form.payment.amount_money"
                  type="number"
                  step="0.01"
                  :formatter="formatMoney"
                  @input="debouncedValidation"
                />
              </be-input-group>
            </be-form-group>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="col-12 col-xl-auto mr-0 mb-2 mb-xl-0 mr-xl-3">
            <be-form-radio-group
              v-model="form.payment.reference_type"
              name="button-styled-radios"
              :options="referenceTypes"
              button-variant="outline-secondary"
              buttons
              class="w-100"
              @input="debouncedValidation"
            />
          </div>

          <div class="col-12 col-xl">
            <be-form-group
              class="mb-0"
              :description="
                $t(
                  `views.companies.inquiries.create.fill_in_payment_reference`,
                  {
                    payment_reference: $t(
                      `views.companies.inquiries.create.reference_type_${form.payment.reference_type}`
                    ),
                  }
                )
              "
              :error="getErrors(form.payment, 'reference')"
            >
              <be-form-input
                id="payout-reference"
                v-model="form.payment.reference"
                @input="debouncedValidation"
              />
            </be-form-group>
          </div>
        </div>
      </div>
    </div>

    <template v-if="inquiry && form.payment?.amount_money > 0">
      <h6>
        <money-format
          :currency="inquiry.currency"
          :value="Number(partialPaymentsPlanned)"
        />
        /
        <money-format
          :currency="inquiry.currency"
          :value="inquiry.granted_value"
        />
      </h6>

      <be-progress height="2rem" class="mb-3">
        <be-progress-bar
          :value="Number(partialPaymentsPlanned)"
          :max="inquiry.granted_value"
          :variant="progressVariant"
        >
        </be-progress-bar>
      </be-progress>
    </template>
  </div>
</template>

<script>
import CreateInquiryMixin from "@/mixins/inquiries/createInquiry";
import { mapGetters, mapActions } from "vuex";
import debounce from "lodash/debounce";

export default {
  mixins: [CreateInquiryMixin],

  props: {},

  computed: {
    ...mapGetters({
      getInquiry: "inquiries/getInquiry",
    }),

    inquiry() {
      return this.getInquiry;
    },

    partialPaymentsPlanned() {
      const initial = this.inquiry.total_payments_planned || 0;
      let total = 0 + initial;
      if (!this.form.payment) return total;
      total += parseFloat(this.form.payment.amount_money) || 0;

      // When updating payment, subtract the previous saved amount from the total
      if (this.form.id) {
        const paymentPlan = this.inquiry.payment_plan.find(
          (plan) => plan.id === this.form.id
        );
        total -= paymentPlan.amount_money;
      }

      return total;
    },

    progressVariant() {
      if (this.partialPaymentsPlanned > this.inquiry.granted_value) {
        return "danger";
      }

      return "success";
    },

    referenceTypes() {
      return [
        {
          text: this.$t("views.companies.inquiries.create.reference_type_ocr"),
          value: "ocr",
        },
        {
          text: this.$t(
            "views.companies.inquiries.create.reference_type_message"
          ),

          value: "message",
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      validateInquiryPayment: "inquiries/validateInquiryPayment",
    }),

    formatMoney(value) {
      if (value == "") {
        return;
      }
      return Intl.NumberFormat("en-US", {
        style: "decimal",
        maximumFractionDigits: 2,
        useGrouping: false,
      }).format(value);
    },

    async handleValidateInquiryPayment() {
      if (!this.form.inquiry.inquiry_id) {
        return;
      }

      const inquiryPayment = {
        inquiry_id: this.form.inquiry.inquiry_id,
        ...this.form.payment,
        id: this.form.id,
      };

      this.clearStates();
      await this.validateInquiryPayment(inquiryPayment);
    },

    clearStates() {
      const fields = ["reference", "reference_type", "pay_at", "amount_money"];
      this.clearErrors(this.form.payment, fields);
    },

    debouncedValidation: debounce(function () {
      this.handleValidateInquiryPayment();
    }, 1000),
  },
};
</script>
