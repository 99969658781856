<template>
  <div>
    <be-tabs
      v-if="Object.keys(tabs).length > 1"
      v-model="activeTab"
      :options="tabs"
      translation-scope="components.corporate_group"
    >
      <template #content-right>
        <div>
          <be-button
            v-if="canAccessConsolidation"
            :href="url('/corporate_groups/consolidation')"
            variant="outline-secondary"
          >
            {{ $t("views.companies.corporate_groups.consolidations.title") }}
          </be-button>

          <be-button
            v-if="isAdminOrCfo"
            :href="url('/group/invitations/new')"
            variant="outline-primary"
          >
            {{ $t("models.group.invite") }}
          </be-button>
        </div>
      </template>
    </be-tabs>

    <div
      class="card"
      :class="{ 'rounded-top-0': Object.keys(tabs).length === 0 }"
    >
      <div class="card-body">
        <corporate-group-tree
          v-if="activeTab === 'tree'"
          :in-client-manager="inClientManager"
          @click-remove-ownership="openRemoveOwnershipModal"
          @open-edit-state-modal="stateBeingEdited = $event"
          @confirm-remove-state="confirmRemoveState"
        />

        <group-history
          v-if="activeTab === 'history'"
          @open-edit-state-modal="stateBeingEdited = $event"
          @confirm-remove-state="confirmRemoveState"
        />
      </div>
    </div>

    <remove-ownership-modal
      :ownership="ownershipBeingRemoved.ownership"
      :resource="ownershipBeingRemoved.url"
      @ownership-updated="loadTree"
      @ownership-deleted="loadTree"
    />

    <ownership-state-modal
      v-if="stateBeingEdited"
      :state="stateBeingEdited"
      :in-client-manager="inClientManager"
      @state-updated="
        loadTree();
        stateBeingEdited = false;
      "
      @state-added="
        loadTree();
        stateBeingEdited = false;
      "
    />
  </div>
</template>

<script>
import GroupHistory from "./parts/GroupHistory.vue";
import OwnershipStateModal from "./parts/OwnershipStateModal.vue";
import RemoveOwnershipModal from "./parts/RemoveOwnershipModal.vue";
import CorporateGroupTree from "./parts/CorporateGroupTree.vue";

import { createNamespacedHelpers } from "vuex";
import { mapGetters } from "vuex";

const { mapState, mapActions } = createNamespacedHelpers(
  "corporate_group_tree"
);

export default {
  components: {
    GroupHistory,
    RemoveOwnershipModal,
    OwnershipStateModal,
    CorporateGroupTree,
  },

  props: {
    inClientManager: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeTab: "tree",
      stateBeingEdited: false,
      ownershipBeingRemoved: false,
    };
  },

  computed: {
    baseUrl() {
      return this.inClientManager
        ? "companies/group/ownerships"
        : "group/ownerships";
    },

    ...mapGetters({
      company: "company/getCompany",
      hasActiveFeature: "company/hasActiveFeature",
    }),

    ...mapState(["ownership_states"]),

    tabs() {
      if (this.inClientManager) {
        return {
          tree: { value: "tree" },
        };
      }
      if (this.ownership_states.length == 0) {
        return {};
      }
      return {
        tree: { value: "tree" },
        history: { value: "history" },
      };
    },

    canAccessConsolidation() {
      return (
        this.hasActiveFeature("economy") &&
        this.hasActiveFeature("economy-corporate-group-consolidation")
      );
    },

    isAdminOrCfo() {
      return !!this.company.memberships.find(
        (membership) =>
          membership.user.id === this.$currentUser.id &&
          (membership.policy_level === "admin" || membership.role === "cfo")
      );
    },
  },

  watch: {
    tabs(newValue) {
      if (!newValue[this.activeTab]) {
        this.activeTab = "tree";
      }
    },
  },

  methods: {
    ...mapActions(["loadTree"]),

    openRemoveOwnershipModal(data) {
      this.ownershipBeingRemoved = data;
      this.$beModal.show("remove-ownership-modal");
    },

    async confirmRemoveState(state) {
      const ownership = state.company_group_ownership;

      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("views.companies.group.ownership.delete_title", {
          start_at: this.$d(new Date(state.start_at), "date"),
        })
      );

      if (!isConfirmed) return;

      try {
        await axios.delete(
          this.url(`${this.baseUrl}/${ownership.id}/states/${state.id}`)
        );

        this.loadTree(false);
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
