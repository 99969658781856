<template>
  <div class="card mb-2">
    <div class="card-body p-3">
      <be-nav wizard class="flex-nowrap">
        <be-nav-item
          v-for="state in states"
          :key="`state-${state.key}`"
          :active="state.active"
          :disabled="state.disabled"
          :href="state.href"
        >
          {{ $t(`models.meeting.navigation.${state.key}`) }}
        </be-nav-item>
      </be-nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    states: {
      type: Array,
      required: true,
    },
  },
};
</script>
