<template>
  <div>
    <div
      class="row mb-3 justify-content-between align-items-md-center align-items-lg-end"
    >
      <div class="col-12 col-md-6 col-lg-4 mb-3 mb-lg-0">
        <be-form-group
          :label="$t('components.contracts.filter_form.search_title')"
          label-for="title"
          class="mb-0"
        >
          <be-input-group>
            <be-input-group-prepend>
              <be-input-group-text class="bg-transparent pr-0">
                <i class="search-icon fal fa-search" />
              </be-input-group-text>
            </be-input-group-prepend>

            <be-form-input
              id="title"
              v-model="searchParameters.title_and_number"
              type="search"
              :placeholder="$t('components.shared.be_table.type_to_search')"
              class="border-left-0"
            />
          </be-input-group>
        </be-form-group>
      </div>

      <div class="col-12 col-md-auto">
        <be-button
          variant="outline-secondary"
          icon="fa-sliders"
          @click="toggleSearchFields()"
        >
          {{
            showSearchField
              ? $t("components.contracts.filter_form.hide_filters")
              : $t("components.contracts.filter_form.show_filters")
          }}
        </be-button>

        <be-button variant="outline-secondary" @click="emptyAllFields()">
          <be-badge
            v-if="activeFilters.length > 0"
            class="mr-1"
            variant="secondary"
            pill
          >
            {{ activeFilters.length }}
          </be-badge>

          {{ $t("components.contracts.filter_form.clear_filters") }}
        </be-button>
      </div>
    </div>

    <div v-if="showSearchField" class="bg-light p-3 mb-2">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <be-form-group
            label-for="dp-input-start-date-start"
            :label="$t('activerecord.attributes.contract.start_date')"
          >
            <be-form-datepicker
              id="start-date-start"
              :model-value="[
                searchParameters.start_date_start || undefined,
                searchParameters.start_date_end || undefined,
              ]"
              type="range"
              @update:start-date="searchParameters.start_date_start = $event"
              @update:end-date="searchParameters.start_date_end = $event"
            />
          </be-form-group>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <be-form-group
            label-for="dp-input-end-date-start"
            :label="$t('activerecord.attributes.contract.end_date')"
          >
            <be-form-datepicker
              id="end-date-start"
              :model-value="[
                searchParameters.end_date_start || undefined,
                searchParameters.end_date_end || undefined,
              ]"
              type="range"
              @update:start-date="searchParameters.end_date_start = $event"
              @update:end-date="searchParameters.end_date_end = $event"
            />
          </be-form-group>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <be-form-group
            label-for="dp-input-termination-date-start"
            :label="$t('activerecord.attributes.contract.termination_date')"
          >
            <be-form-datepicker
              id="termination-date-start"
              :model-value="[
                searchParameters.termination_date_start || undefined,
                searchParameters.termination_date_end || undefined,
              ]"
              type="range"
              @update:start-date="
                searchParameters.termination_date_start = $event
              "
              @update:end-date="searchParameters.termination_date_end = $event"
            />
          </be-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <be-form-group
            :label="$t('activerecord.attributes.contract.total')"
            label-for="value-start"
            :label-after="fromToTranslation"
            label-after-class="small text-muted"
          >
            <be-input-group>
              <be-form-input
                id="value-start"
                v-model="searchParameters.value_start"
                type="number"
              />

              <be-input-group-separator is-text> - </be-input-group-separator>

              <be-form-input
                id="value-end"
                v-model="searchParameters.value_end"
                type="number"
              />
            </be-input-group>
          </be-form-group>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <be-form-group
            :label="$t('activerecord.attributes.contract.monthly')"
            label-for="monthly-value-start"
            :label-after="fromToTranslation"
            label-after-class="small text-muted"
          >
            <be-input-group>
              <be-form-input
                id="monthly-value-start"
                v-model="searchParameters.monthly_value_start"
                type="number"
              />

              <be-input-group-separator is-text> - </be-input-group-separator>

              <be-form-input
                id="monthly-value-end"
                v-model="searchParameters.monthly_value_end"
                type="number"
              />
            </be-input-group>
          </be-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4 col-lg-4">
          <be-form-group
            :label="$t('activerecord.models.sub_category.other')"
            label-for="categories"
          >
            <be-form-select
              id="categories"
              v-model="searchParameters.categories"
              :options="categoriesFormatted"
              group-selectable
              multiple
            />
          </be-form-group>
        </div>

        <div class="col-12 col-md-4 col-lg-4">
          <be-form-group
            :label="$t('activerecord.models.custom_field.other')"
            label-for="custom-fields"
          >
            <be-form-select
              id="custom-fields"
              v-model="searchParameters.custom_fields"
              :options="customFieldsFormatted"
              multiple
            />
          </be-form-group>
        </div>

        <div class="col-12 col-md-4 col-lg-4">
          <be-form-group
            :label="$t('models.contract.status')"
            label-for="status"
          >
            <be-form-select
              id="status"
              v-model="searchParameters.status"
              :options="statusFormatted"
            />
          </be-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    categories: {
      type: Array,
      required: true,
    },

    customFields: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      activeFilters: [],
      showSearchField: false,

      searchParameters: {
        title_and_number: null,
        value_start: null,
        value_end: null,
        monthly_value_start: null,
        monthly_value_end: null,
        start_date_start: null,
        start_date_end: null,
        end_date_start: null,
        end_date_end: null,
        termination_date_start: null,
        termination_date_end: null,
        categories: [],
        custom_fields: [],
        status: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      contracts: "contracts/getContracts",
    }),

    categoriesFormatted() {
      let formatted = [];
      this.categories.map((category) => {
        const subCategoryOptions = category.sub_categories.map(
          (subCategory) => {
            return {
              label: subCategory.title,
              value: subCategory.id,
            };
          }
        );

        formatted.push({
          label: category.title,
          options: subCategoryOptions,
        });
      });

      return formatted;
    },

    customFieldsFormatted() {
      return this.customFields.map((customField) => {
        return {
          label: customField.title,
          value: customField.id,
        };
      });
    },

    statusFormatted() {
      return [
        {
          label: this.$t("models.contract.statuses.created"),
          value: "created",
        },
        {
          label: this.$t("models.contract.statuses.in_revision"),
          value: "in_revision",
        },
        {
          label: this.$t("models.contract.statuses.in_verification"),
          value: "in_verification",
        },
        {
          label: this.$t("models.contract.statuses.monitored"),
          value: "monitored",
        },
        {
          label: this.$t("models.contract.statuses.archived"),
          value: "archived",
        },
      ];
    },

    fromToTranslation() {
      return `(${this.$t("components.contracts.filter_form.from")} - ${this.$t(
        "components.contracts.filter_form.to"
      )})`;
    },
  },

  watch: {
    activeFilters: {
      handler() {
        this.$store.commit("contracts/setFilters", this.activeFilters);
      },

      deep: true,
    },

    searchParameters: {
      handler() {
        this.filterContracts();
      },

      deep: true,
    },
  },

  methods: {
    toggleSearchFields() {
      this.showSearchField = !this.showSearchField;
    },

    filterContracts() {
      let contracts = this.cloneDeep(this.contracts);
      this.activeFilters = [];

      // Title and number
      if (this.searchParameters.title_and_number) {
        contracts = contracts.filter((contract) => {
          const titleMatch = contract.title
            .toLowerCase()
            .includes(this.searchParameters.title_and_number.toLowerCase());

          const numberMatch = ("" + contract.contract_number).includes(
            this.searchParameters.title_and_number
          );

          return titleMatch || numberMatch;
        });
      }

      // Categories
      if (Object.keys(this.searchParameters.categories).length > 0) {
        contracts = contracts.filter((contract) => {
          const subCategory = contract.category_owner?.sub_category;

          if (!subCategory) {
            return false;
          }

          return this.searchParameters.categories.includes(subCategory.id);
        });

        this.activeFilters.push("categories");
      }

      // Custom fields
      if (Object.keys(this.searchParameters.custom_fields).length > 0) {
        contracts = contracts.filter((contract) => {
          const customFieldIds = contract.custom_field_owners.map(
            (customFieldOwner) => customFieldOwner.custom_field.id
          );

          return this.searchParameters.custom_fields.some((id) =>
            customFieldIds.includes(id)
          );
        });

        this.activeFilters.push("custom_fields");
      }

      // Status
      if (this.searchParameters.status) {
        contracts = contracts.filter(
          (contract) => contract.status === this.searchParameters.status
        );

        this.activeFilters.push("status");
      }

      // Value
      if (this.searchParameters.value_start) {
        contracts = this.filterByValueLargerThan(
          contracts,
          "value_start",
          "total_value_cents"
        );

        if (!this.activeFilters.includes("value_end")) {
          this.activeFilters.push("value_start");
        }
      }

      if (this.searchParameters.value_end) {
        contracts = this.filterByValueSmallerThan(
          contracts,
          "value_end",
          "total_value_cents"
        );

        if (!this.activeFilters.includes("value_start")) {
          this.activeFilters.push("value_end");
        }
      }

      // Monthly value
      if (this.searchParameters.monthly_value_start) {
        contracts = this.filterByValueLargerThan(
          contracts,
          "monthly_value_start",
          "monthly_value_cents"
        );

        if (!this.activeFilters.includes("monthly_value_end")) {
          this.activeFilters.push("monthly_value_start");
        }
      }

      if (this.searchParameters.monthly_value_end) {
        contracts = this.filterByValueSmallerThan(
          contracts,
          "monthly_value_end",
          "monthly_value_cents"
        );

        if (!this.activeFilters.includes("monthly_value_start")) {
          this.activeFilters.push("monthly_value_end");
        }
      }

      // Start date
      if (this.searchParameters.start_date_start) {
        contracts = this.filterByDateLargerThan(
          contracts,
          "start_date_start",
          "start_date"
        );

        if (!this.activeFilters.includes("start_date_end")) {
          this.activeFilters.push("start_date_start");
        }
      }

      if (this.searchParameters.start_date_end) {
        contracts = this.filterByDateSmallerThan(
          contracts,
          "start_date_end",
          "start_date"
        );

        if (!this.activeFilters.includes("start_date_start")) {
          this.activeFilters.push("start_date_end");
        }
      }

      // End date
      if (this.searchParameters.end_date_start) {
        contracts = this.filterByDateLargerThan(
          contracts,
          "end_date_start",
          "end_date"
        );

        if (!this.activeFilters.includes("end_date_end")) {
          this.activeFilters.push("end_date_start");
        }
      }

      if (this.searchParameters.end_date_end) {
        contracts = this.filterByDateSmallerThan(
          contracts,
          "end_date_end",
          "end_date"
        );

        if (!this.activeFilters.includes("end_date_start")) {
          this.activeFilters.push("end_date_end");
        }
      }

      // Termination date
      if (this.searchParameters.termination_date_start) {
        contracts = this.filterByDateLargerThan(
          contracts,
          "termination_date_start",
          "termination_date"
        );

        if (!this.activeFilters.includes("termination_date_end")) {
          this.activeFilters.push("termination_date_start");
        }
      }

      if (this.searchParameters.termination_date_end) {
        contracts = this.filterByDateSmallerThan(
          contracts,
          "termination_date_end",
          "termination_date"
        );

        if (!this.activeFilters.includes("termination_date_start")) {
          this.activeFilters.push("termination_date_end");
        }
      }

      let contractIds = contracts.map((contract) => contract.id);

      if (contracts == this.contracts) {
        contractIds = null;
      }

      this.$store.dispatch("contracts/setFilteredContractIds", contractIds);
    },

    filterByDateSmallerThan(contracts, key, columnKey) {
      const filterDate = new Date(this.searchParameters[key]);

      return contracts.filter(
        (contract) => new Date(contract[columnKey]) <= filterDate
      );
    },

    filterByDateLargerThan(contracts, key, columnKey) {
      const filterDate = new Date(this.searchParameters[key]);

      return contracts.filter(
        (contract) => new Date(contract[columnKey]) >= filterDate
      );
    },

    filterByValueSmallerThan(contracts, key, columnKey) {
      return contracts.filter(
        (contract) => contract[columnKey] / 100.0 <= this.searchParameters[key]
      );
    },

    filterByValueLargerThan(contracts, key, columnKey) {
      return contracts.filter(
        (contract) => contract[columnKey] / 100.0 >= this.searchParameters[key]
      );
    },

    clearField(field) {
      this.searchParameters[field] = null;
      this.filterContracts();
    },

    emptyAllFields() {
      this.searchParameters.title_and_number = "";
      this.searchParameters.value_start = null;
      this.searchParameters.value_start = null;
      this.searchParameters.value_end = null;
      this.searchParameters.monthly_value_start = null;
      this.searchParameters.monthly_value_end = null;
      this.searchParameters.start_date_start = null;
      this.searchParameters.start_date_end = null;
      this.searchParameters.end_date_start = null;
      this.searchParameters.end_date_end = null;
      this.searchParameters.termination_date_start = null;
      this.searchParameters.termination_date_end = null;
      this.searchParameters.categories = [];
      this.searchParameters.custom_fields = [];
      this.searchParameters.status = null;

      this.filterContracts();
    },
  },
};
</script>
