import axios from "axios";
import { parseISO } from "date-fns/parseISO";
import { add } from "date-fns/add";
import { handleError } from "@/utils/error-handling";

export const MATERIAL_EDITOR_TYPE = {
  agenda: "agenda",
  minutes: "minutes",
  none: "none",
};

export const state = {
  editorType: MATERIAL_EDITOR_TYPE.none,

  mode: "show", // used to control fetching in pdf mode

  attachments: [],
  decisions: [],
  documents: [],
  dirty: [],
  failedToSaveItemIds: [],
  items: [],
  material: {},
  meeting: {},
  tasks: [],
  templates: [],

  followUp: {
    references: [],
  },

  template: {
    title: "",
    save: false,
  },

  loading: false,
};

export const getters = {
  material: (state) => state.material,
  items: (state) => state.items,
  meeting: (state) => state.meeting,
  attachments: (state) => state.attachments,
  mode: (state) => state.mode,

  getItem: (state) => (item) =>
    state.items.find((existingItem) => existingItem.id == item.id),

  getItemDecisions: (state) => (item) =>
    state.decisions.filter((decision) => decision.item_id === item.id),

  getItemDocuments: (state) => (item) =>
    state.documents.filter(
      (document) =>
        document.owner_type === "Item" && document.owner_id === item.id
    ),

  getItemTasks: (state) => (item) =>
    state.tasks.filter(
      (task) => task.owner_id === item.id && task.owner_type === "Item"
    ),

  persistedItems: (state) => state.items.filter((item) => item.id),
  templateSettings: (state) => state.template,

  totalDuration: (state) => {
    if (state.items.length === 0) {
      return 0;
    }
    return state.items.reduce((totalDuration, item) => {
      return totalDuration + item.duration_in_seconds + item.recess_in_seconds;
    }, 0);
  },

  itemStartTimes: (state) => {
    const result = {};
    let start = new Date(state.meeting.start_at);

    state.items.forEach((item) => {
      result[item.id] = start;
      start = add(start, {
        seconds: item.duration_in_seconds + item.recess_in_seconds,
      });
    });

    return result;
  },

  editorType: (state) => state.material?.material_type,
  loading: (state) => state.loading,
  isDirty: (state) => state.dirty.length > 0,

  itemDirty: (state) => (item) =>
    !!state.dirty.find((row) => row === `item-${item.id}`),

  formInvalid: (state, getters) => {
    const { itemInvalid, itemInvalidAssociations } = getters;
    return (
      state.items.filter(
        (item) => itemInvalid(item) || itemInvalidAssociations(item)
      ).length > 0
    );
  },

  itemInvalid: () => (item) => hasErrors(item),

  itemInvalidAssociations: (_state, getters) => (item) => {
    const { getItemDecisions, getItemTasks } = getters;
    return (
      getItemDecisions(item).filter(hasErrors).length > 0 ||
      getItemTasks(item).filter(hasErrors).length > 0
    );
  },

  hasErrors: () => (object) => {
    return hasErrors(object);
  },

  lastItemUpdatedAt: (state) => {
    let maxDate = null;

    if (state.items && state.items.length > 0) {
      state.items.forEach((item) => {
        const date = parseISO(item.updated_at);
        if (maxDate == null || date > maxDate) {
          maxDate = date;
        }
      });
    } else {
      maxDate = state.material.updated_at
        ? parseISO(state.material.updated_at)
        : parseISO(state.material.created_at);
    }

    return maxDate;
  },

  lastAssociationUpdatedAt: (state) => {
    const associations = [
      ...state.attachments,
      ...state.decisions,
      ...state.documents,
      ...state.tasks,
    ];

    return associations.reduce((maxDate, association) => {
      if (!association.updated_at) return maxDate;
      const date = parseISO(association.updated_at);
      return maxDate == null || date > maxDate ? date : maxDate;
    }, null);
  },

  lastUpdatedAt: (state, getters) => {
    const { lastItemUpdatedAt, lastAssociationUpdatedAt } = getters;

    return lastItemUpdatedAt > lastAssociationUpdatedAt
      ? lastItemUpdatedAt
      : lastAssociationUpdatedAt;
  },

  failedToSaveSomeItem: (state) => state.failedToSaveItemIds.length > 0,

  followUpReferences: (state) => state.followUp.references,

  emptyTasksOrDecisions: (state) => {
    return (
      state.tasks.some((task) => {
        return !task.description;
      }) ||
      state.decisions.some((decision) => {
        return !decision.description;
      })
    );
  },

  // Calculate decisionNumbers in frontend to avoid having to reload decisions.
  // In preparation for decision numbers to be static and updated in background.
  decisionNumbers: (state) => {
    const decisionsByItem = {};
    state.decisions.forEach((decision) => {
      if (!decisionsByItem[decision.item_id]) {
        decisionsByItem[decision.item_id] = [];
      }
      decisionsByItem[decision.item_id].push(decision);
    });

    const decisionNumbers = {};
    let number = 1;
    state.items.forEach((item) => {
      (decisionsByItem[item.id] || []).forEach((decision) => {
        decisionNumbers[decision.id] = number;
        number += 1;
      });
    });

    return decisionNumbers;
  },
};

const hasErrors = (entity) => Object.keys(entity?.errors || {}).length > 0;

export const actions = {
  async saveTemplate(context, params) {
    const meeting = context.getters.meeting;

    await axios.post(`${meeting.paths.base}/agenda_templates`, {
      material: params,
    });
  },

  async markAgendaAsDone(context) {
    const meeting = context.getters.meeting;
    const { material } = context.getters;

    context.commit("setLoadingStart");

    try {
      await axios.patch(`${meeting.paths.base}/agenda`, {
        agenda: {
          secretary_done: true,
        },
      });

      material.secretary_done = true;
      material.secretary_done_at = new Date().toISOString();
      context.commit("loadMaterial", material);
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      context.commit("setLoadingStop");
    }
    return true;
  },

  async markMinutesAsDone(context) {
    const meeting = context.getters.meeting;
    const { material } = context.getters;

    context.commit("setLoadingStart");

    try {
      await axios.patch(`${meeting.paths.base}/minutes`, {
        minutes: {
          secretary_done: true,
        },
      });

      material.secretary_done = true;
      material.secretary_done_at = new Date().toISOString();
      context.commit("loadMaterial", material);
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      context.commit("setLoadingStop");
    }
    return true;
  },

  async createItem(context, { item, skip_flash = false }) {
    const meeting = context.getters.meeting;
    const material = context.getters.material;

    try {
      const response = await axios.post(
        `${meeting.paths.base}/materials/${material.id}/items`,
        {
          item,
          skip_flash,
        }
      );
      context.commit("addItem", response.data);
      if (material.material_type === "agenda") {
        material.secretary_done = false;
        material.secretary_done_at = null;
      }
      context.commit("loadMaterial", material);
    } catch (error) {
      handleError(error);
      return false;
    }
    return true;
  },

  async updateItem(context, item) {
    context.commit("clearFailedToSave", item);
    context.commit("setDirty", `item-${item.id}`);
    context.commit("setLoadingStart");

    const meeting = context.getters.meeting;
    const material = context.getters.material;
    let result = true;

    try {
      const response = await axios.patch(
        `${meeting.paths.base}/materials/${material.id}/items/${item.id}`,
        {
          item,
        }
      );

      context.commit("loadItem", response.data);
      context.commit("setClean", `item-${item.id}`);
      if (material.material_type === "agenda") {
        material.secretary_done = false;
        material.secretary_done_at = null;
      }
      context.commit("loadMaterial", material);
    } catch (error) {
      if (error.response?.status === 422) {
        context.commit("loadItem", error.response.data);
        context.commit("clearFailedToSave", item);
      } else {
        context.commit("failedToSave", item);
        handleError(error);
      }
      result = false;
    } finally {
      context.commit("setLoadingStop");
    }
    return result;
  },

  async removeItem(context, item) {
    const meeting = context.getters.meeting;
    const material = context.getters.material;

    if (item.id) {
      try {
        await axios.delete(
          `${meeting.paths.base}/materials/${material.id}/items/${item.id}`
        );
        context.commit("removeItem", item);

        if (material.material_type === "minutes") {
          context.dispatch("fetchAttachments");
        }

        await context.dispatch("fetchItems");
        if (material.material_type === "agenda") {
          material.secretary_done = false;
          material.secretary_done_at = null;
        }
        context.commit("loadMaterial", material);
      } catch (error) {
        handleError(error);
      }
    } else {
      context.commit("removeItem", item);
    }
  },

  async createDecision(context, item) {
    context.commit("setLoadingStart");
    const meeting = context.getters.meeting;
    const material = context.getters.material;

    try {
      const { data } = await axios.post(
        `${meeting.paths.base}/materials/${material.id}/items/${item.id}/decisions`
      );
      data.fresh = true;
      context.commit("updateDecision", data);
      if (material.material_type === "agenda") {
        material.secretary_done = false;
        material.secretary_done_at = null;
        context.commit("loadMaterial", material);
      }
    } catch (error) {
      if (error.response?.status === 422) {
        context.commit("updateDecision", error.response.data);
      } else {
        handleError(error);
      }
    } finally {
      context.commit("setLoadingStop");
    }
  },

  async updateDecision(context, { item, decision }) {
    context.commit("setDirty", `decision-${decision.id}`);
    context.commit("setLoadingStart");

    const meeting = context.getters.meeting;
    const material = context.getters.material;

    let result = true;

    try {
      const { data } = await axios.patch(
        `${meeting.paths.base}/materials/${material.id}/items/${item.id}/decisions/${decision.id}`,
        {
          decision: decision,
        }
      );

      context.commit("updateDecision", data);
      context.commit("setClean", `decision-${decision.id}`);
      if (material.material_type === "agenda") {
        material.secretary_done = false;
        material.secretary_done_at = null;
        context.commit("loadMaterial", material);
      }
    } catch (error) {
      if (error.response?.status === 422) {
        context.commit("updateDecision", error.response.data);
      } else {
        handleError(error);
      }
      result = false;
    } finally {
      context.commit("setLoadingStop");
    }

    return result;
  },

  async removeDecision(context, { item, decision }) {
    context.commit("setLoadingStart");

    const meeting = context.getters.meeting;
    const material = context.getters.material;

    try {
      await axios.delete(
        `${meeting.paths.base}/materials/${material.id}/items/${item.id}/decisions/${decision.id}`
      );
      context.commit("removeDecision", decision);
      context.commit("setClean", `decision-${decision.id}`);
      if (material.material_type === "agenda") {
        material.secretary_done = false;
        material.secretary_done_at = null;
        context.commit("loadMaterial", material);
      }
    } catch (error) {
      handleError(error);
    } finally {
      context.commit("setLoadingStop");
    }
  },

  async createTask(context, item) {
    context.commit("setLoadingStart");
    const meeting = context.getters.meeting;
    const material = context.getters.material;

    try {
      const { data } = await axios.post(
        `${meeting.paths.base}/materials/${material.id}/items/${item.id}/tasks`
      );
      data.fresh = true;
      context.commit("updateTask", data);
      if (material.material_type === "agenda") {
        material.secretary_done = false;
        material.secretary_done_at = null;
        context.commit("loadMaterial", material);
      }
    } catch (error) {
      if (error.response?.status === 422) {
        context.commit("updateTask", error.response.data);
      } else {
        handleError(error);
      }
    } finally {
      context.commit("setLoadingStop");
    }
  },

  async updateTask(context, { item, task }) {
    context.commit("setDirty", `task-${task.id}`);
    context.commit("setLoadingStart");

    const meeting = context.getters.meeting;
    const material = context.getters.material;

    let result = true;

    try {
      const { data } = await axios.patch(
        `${meeting.paths.base}/materials/${material.id}/items/${item.id}/tasks/${task.id}`,
        {
          task,
        }
      );

      context.commit("updateTask", data);
      context.commit("setClean", `task-${task.id}`);
      if (material.material_type === "agenda") {
        material.secretary_done = false;
        material.secretary_done_at = null;
        context.commit("loadMaterial", material);
      }
    } catch (error) {
      if (error.response?.status === 422) {
        context.commit("updateTask", error.response.data);
      } else {
        handleError(error);
      }
      result = false;
    } finally {
      context.commit("setLoadingStop");
    }

    return result;
  },

  async removeTask(context, { item, task }) {
    context.commit("setLoadingStart");

    const meeting = context.getters.meeting;
    const material = context.getters.material;

    try {
      await axios.delete(
        `${meeting.paths.base}/materials/${material.id}/items/${item.id}/tasks/${task.id}`
      );
      context.commit("removeTask", task);
      context.commit("setClean", `task-${task.id}`);
      if (material.material_type === "agenda") {
        material.secretary_done = false;
        material.secretary_done_at = null;
        context.commit("loadMaterial", material);
      }
    } catch (error) {
      handleError(error);
    } finally {
      context.commit("setLoadingStop");
    }
  },

  async createItemDocument(context, { item, document }) {
    context.commit("setLoadingStart");

    const { meeting, material } = context.getters;

    try {
      // Add to list of documents to show pending upload
      context.commit("updateDocument", {
        ...document,
        owner_type: "Item",
        owner_id: item.id,
      });

      const { data } = await axios.post(
        `${meeting.paths.base}/materials/${material.id}/items/${item.id}/documents`,
        {
          document: document,
        }
      );

      // Remove the pending upload and add the finished uploaded document
      context.commit("removeDocument", document);
      context.commit("updateDocument", data);

      if (material.material_type === "agenda") {
        material.secretary_done = false;
        material.secretary_done_at = null;
        context.commit("loadMaterial", material);
      } else {
        context.dispatch("fetchAttachments");
      }
    } catch (error) {
      if (error.response?.status === 422) {
        // Remove the pending upload and add invalid upload
        context.commit("removeDocument", document);
        context.commit("updateDocument", error.response.data);
      } else {
        context.commit("removeDocument", document);
        handleError(error);
      }
    } finally {
      context.commit("setLoadingStop");
    }
  },

  async removeItemDocument(context, { item, document }) {
    context.commit("setLoadingStart");

    const { meeting, material } = context.getters;

    try {
      await axios.delete(
        `${meeting.paths.base}/materials/${material.id}/items/${item.id}/documents/${document.id}`
      );

      context.commit("removeDocument", document);

      if (material.material_type === "agenda") {
        material.secretary_done = false;
        material.secretary_done_at = null;
        context.commit("loadMaterial", material);
      } else {
        context.dispatch("fetchAttachments");
      }
    } catch (error) {
      handleError(error);
    } finally {
      context.commit("setLoadingStop");
    }
  },

  async changeItemStartNumber(context, options) {
    context.commit("setLoadingStart");
    const meeting = context.getters.meeting;

    try {
      await axios.patch(
        `${meeting.paths.base}/materials/${options.materialId}/item_numbering`,
        { item_number_start: options.itemNumberStart }
      );
      await context.dispatch("fetchItems");
    } catch (error) {
      handleError(error);
    } finally {
      context.commit("setLoadingStop");
    }
  },

  async useTemplate(context, template) {
    const meeting = context.getters.meeting;

    try {
      const response = await axios.post(
        `${meeting.paths.base}/agenda/templating`,
        {
          agenda_template_id: template.id,
        }
      );
      const { items, ...material } = response.data;
      context.commit("loadMaterial", material);
      context.commit("loadItems", items);
    } catch (error) {
      handleError(error);
    }
  },

  async updateItemNumber(context, { item, to }) {
    const meeting = context.getters.meeting;
    const material = context.getters.material;

    try {
      context.commit("setLoadingStart");
      await axios.patch(
        `${meeting.paths.base}/materials/${material.id}/items/${item.id}`,
        {
          item: {
            number: to,
          },
        }
      );
      await context.dispatch("fetchItems");
      if (material.material_type === "agenda") {
        material.secretary_done = false;
        material.secretary_done_at = null;
      }
      context.commit("loadMaterial", material);
    } catch (error) {
      context.commit("failedToSave", item);
      handleError(error);
    } finally {
      context.commit("setLoadingStop");
    }
  },

  async fetchItems(context) {
    const meeting = context.getters.meeting;
    const material = context.getters.material;

    try {
      const response = await axios.get(
        `${meeting.paths.base}/materials/${material.id}/items`
      );
      context.commit("loadItems", response.data);
    } catch (error) {
      handleError(error);
    }
  },

  async fetchAttachments(context) {
    const meeting = context.getters.meeting;
    try {
      const response = await axios.get(`${meeting.paths.base}/attachments`);
      context.commit("loadAttachments", response.data);
    } catch (error) {
      handleError(error);
    }
  },

  async updateAttachment(context, attachment) {
    const meeting = context.getters.meeting;
    const attachment_url = `${meeting.paths.base}/attachments/${attachment.id}`;

    try {
      const response = await axios.patch(attachment_url, {
        attachment: attachment,
      });
      context.commit("loadAttachment", response.data);
    } catch (error) {
      handleError(error);
    }
  },

  async fetchFollowUpReferences(context) {
    const mode = context.getters.mode;
    if (mode === "pdf") {
      return;
    }
    const meeting = context.getters.meeting;
    const material = context.getters.material;

    try {
      const { data } = await axios.get(
        `${meeting.paths.base}/materials/${material.id}/references`
      );

      context.commit("setFollowUpReferences", data);
    } catch (error) {
      handleError(error);
    }
  },

  async fetchItemFollowUpReferences(context, itemId) {
    const mode = context.getters.mode;
    if (mode === "pdf") {
      return;
    }

    const meeting = context.getters.meeting;
    const material = context.getters.material;

    try {
      const { data } = await axios.get(
        `${meeting.paths.base}/materials/${material.id}/items/${itemId}/references`
      );

      data.forEach((reference) => {
        context.commit("updateFollowUpReference", reference);
      });
    } catch (error) {
      handleError(error);
    }
  },

  async addFollowUpReference(
    context,
    { itemId, reference_type, reference_id }
  ) {
    const meeting = context.getters.meeting;
    const material = context.getters.material;

    try {
      const { data } = await axios.post(
        `${meeting.paths.base}/materials/${material.id}/items/${itemId}/references`,
        {
          item_reference: {
            reference_type,
            reference_id,
          },
        }
      );
      context.commit("updateFollowUpReference", data);
    } catch (e) {
      handleError(e);
    }
  },

  // This action is only used to refresh the metadata for the item_reference
  // No parameters needed or supported
  async updateFollowUpReference(context, reference) {
    const meeting = context.getters.meeting;
    const material = context.getters.material;

    try {
      const { data } = await axios.patch(
        `${meeting.paths.base}/materials/${material.id}/items/${reference.item_id}/references/${reference.id}`
      );

      context.commit("updateFollowUpReference", data);
    } catch (e) {
      handleError(e);
    }
  },

  async removeFollowUpReference(context, reference) {
    const meeting = context.getters.meeting;
    const material = context.getters.material;

    try {
      await axios.delete(
        `${meeting.paths.base}/materials/${material.id}/items/${reference.item_id}/references/${reference.id}`
      );

      context.commit("deleteFollowUpReference", reference);
    } catch (e) {
      handleError(e);
    }
  },
};

export const mutations = {
  loadMeeting(state, meeting) {
    state.meeting = meeting;
  },

  setLoadingStart(state) {
    state.loading = true;
  },

  setLoadingStop(state) {
    state.loading = false;
  },

  setDirty(state, reference) {
    const index = state.dirty.findIndex((row) => row === reference);

    if (index === -1) {
      state.dirty.push(reference);
    }
  },

  setClean(state, reference) {
    const index = state.dirty.findIndex((row) => row === reference);

    if (index > -1) {
      state.dirty.splice(index, 1);
    }
  },

  setPdfMode(state) {
    state.mode = "pdf";
  },

  setTemplate(state, template) {
    state.template = template;
  },

  loadItemsAndMaterial(state, { items, ...material }) {
    state.material = material;
    state.items = items || [];

    const decisions = [];
    const tasks = [];
    const documents = [];

    for (const item of state.items) {
      decisions.push(...item.decisions);
      documents.push(...item.documents);
      tasks.push(...item.tasks);
    }

    state.decisions = decisions;
    state.documents = documents;
    state.tasks = tasks;
    state.dirty = [];
  },

  loadMaterial(state, material) {
    state.material = material;
  },

  /**
   * Item
   */
  addItem(state, item) {
    let index = state.items.findIndex((el) => el.id === item.id);

    if (index > -1) {
      state.items[index] = item;
    } else {
      index = state.items.length;
      state.items.push(item);
    }
  },

  removeItem(state, item) {
    const index = state.items.findIndex(
      (el) =>
        (item.id && item.id === el.id) || (item.uuid && item.uuid === el.uuid)
    );

    if (index !== -1) {
      state.items.splice(index, 1);
    } else {
      console.warn("Unable to find item to remove");
    }

    state.decisions = state.decisions.filter(
      (decision) => decision.item_id != item.id
    );

    state.tasks = state.tasks.filter((task) => task.owner_id != item.id);
  },

  loadItems(state, items) {
    state.items = items || [];
    state.decisions = items.flatMap((item) => item.decisions);
    state.tasks = items.flatMap((item) => item.tasks);
    state.dirty = [];
  },

  // Does not update decisions and tasks, only the item itself
  loadItem(state, item) {
    let index = state.items.findIndex((existing) => existing.id === item.id);
    if (index > -1) {
      state.items[index] = item;
    } else {
      state.items.push(item);
    }
  },

  clearFailedToSave(state, item) {
    const index = state.failedToSaveItemIds.findIndex((id) => id === item.id);
    if (index > -1) {
      state.failedToSaveItemIds.splice(index, 1);
    }
  },

  failedToSave(state, item) {
    const index = state.failedToSaveItemIds.findIndex((id) => id === item.id);
    if (index === -1) {
      state.failedToSaveItemIds.push(item.id);
    }
  },

  setFollowUpReferences(state, references) {
    state.followUp.references = references;
  },

  updateFollowUpReference(state, reference) {
    const index = state.followUp.references.findIndex(
      (row) => row.id === reference.id
    );
    if (index === -1) {
      state.followUp.references.push(reference);
    } else {
      state.followUp.references[index] = reference;
    }
  },

  deleteFollowUpReference(state, reference) {
    const index = state.followUp.references.findIndex(
      (row) => row.id === reference.id
    );
    if (index > -1) {
      state.followUp.references.splice(index, 1);
    }
  },

  loadAttachments(state, attachments) {
    state.attachments = attachments;
  },

  loadAttachment(state, attachment) {
    const index = state.attachments.findIndex(
      (existing) => existing.id === attachment.id
    );
    if (index === -1) {
      state.attachments.push(attachment);
    } else {
      state.attachments[index] = attachment;
    }
  },

  removeDecision(state, decision) {
    const index = state.decisions.findIndex(
      (existing) => existing.id === decision.id
    );

    if (index > -1) {
      state.decisions.splice(index, 1);
    }
  },

  updateDecision(state, decision) {
    const index = state.decisions.findIndex(
      (existing) => existing.id === decision.id
    );

    if (index > -1) {
      state.decisions[index] = decision;
    } else {
      state.decisions.push(decision);
    }
  },

  removeTask(state, task) {
    const index = state.tasks.findIndex((existing) => existing.id === task.id);

    if (index > -1) {
      state.tasks.splice(index, 1);
    }
  },

  updateTask(state, task) {
    const index = state.tasks.findIndex((existing) => existing.id === task.id);

    if (index > -1) {
      state.tasks[index] = task;
    } else {
      state.tasks.push(task);
    }
  },

  removeDocument(state, document) {
    const index = state.documents.findIndex(
      (existing) =>
        (existing.id && existing.id === document.id) ||
        (existing.file_uuid && existing.file_uuid === document.file_uuid) ||
        (existing.copy_from_id &&
          existing.copy_from_id === document.copy_from_id)
    );

    if (index > -1) {
      state.documents.splice(index, 1);
    }
  },

  updateDocument(state, document) {
    const index = state.documents.findIndex(
      (existing) =>
        (existing.id && existing.id === document.id) ||
        (existing.file_uuid && existing.file_uuid === document.file_uuid)
    );

    if (index > -1) {
      state.documents[index] = document;
    } else {
      state.documents.push(document);
    }
  },

  addErrorsToEmptyTasksDecisions(state, message) {
    state.tasks.forEach((task) => {
      if (!task.description) {
        task.errors = {
          ...task.errors,
          description: message,
        };
      }
    });

    state.decisions.forEach((decision) => {
      if (!decision.description) {
        decision.errors = {
          ...decision.errors,
          description: message,
        };
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
