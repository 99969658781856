import axios from "axios";
import { sub, endOfMonth } from "date-fns";
import cloneDeep from "lodash/cloneDeep";
import sortBy from "lodash/sortBy";
import { url } from "@/url-helpers";
import { handleError } from "@/utils/error-handling";
import uuid from "@/mixins/uuid/index";

function reportUrl(context, resource) {
  const { report } = context.state;
  if (report.owner_type === "Company") {
    return reportUrlForCompany(context, resource);
  }
  if (report.owner_type === "AdminPanel") {
    return reportUrlForAdminpanel(context, resource);
  }
}

function reportUrlForCompany(context, resource) {
  const { report } = context.state;
  let path = url("/reports/new", {
    company: context.rootGetters["company/getCompany"],
  });
  if (report.id) {
    path = report.paths.base;
  }

  if (resource) {
    path += `/${resource}`;
  }

  return path;
}

function reportUrlForAdminpanel(context, resource) {
  const { report } = context.state;
  let path = url("/financials/dashboards", {
    admin_panel_id: report.owner_id,
  });

  if (report.id) {
    path = report.paths.base;
  }

  if (resource) {
    path += `/${resource}`;
  }

  return path;
}

export const state = {
  report: {},
  financial_attachments: [],
  elements: [],
  activeFeatures: [],
  versions: [],
  mode: "report",
  loadingIds: [],
  movingElement: null,

  pdfByReport: [],
};

export const getters = {
  getActiveFeatures: (state) => state.activeFeatures,
  getElements: (state) => state.elements,

  getElementsByParent: (state) => {
    const elements_by_parent = {};

    state.elements.forEach((element) => {
      const parent_id = element.parent_component_id || "root";
      if (elements_by_parent[parent_id] === undefined) {
        elements_by_parent[parent_id] = [];
      }

      elements_by_parent[parent_id].push(element);
    });

    for (const key of Object.keys(elements_by_parent)) {
      elements_by_parent[key] = sortBy(elements_by_parent[key], "position");
    }

    return elements_by_parent;
  },

  getFinancialAttachments: (state) => state.financial_attachments,
  getReport: (state) => state.report,
  getMovingElement: (state) => state.movingElement,

  getPdfForReport: (state) => (report) =>
    state.pdfByReport.find((row) => row.report_id === report.id),

  getReportWithElements: (state) => {
    return {
      ...state.report,
      report_components: state.elements,
      financial_attachments: state.financial_attachments,
    };
  },

  loading: (state) => state.loadingIds.length > 0,

  isCorporateGroupReport: (state) =>
    state.report?.report_type === "corporate_group" ||
    state.report?.report_type === "economy_corporate_group_dashboard" ||
    state.report?.corporate_group_data,

  getElementPositions: (_state, getters) => {
    const positions = {};

    Object.values(getters.getElementsByParent).forEach((elements) => {
      elements.forEach((element, index) => {
        positions[element.id] = {
          index: index,
          size: elements.length,
        };
      });
    });

    return positions;
  },

  getElementTypes: (state) => {
    const types = new Set();
    state.elements.forEach((element) => {
      types.add(element.component_type);
    });
    return Array.from(types);
  },
};

export const actions = {
  setActiveFeatures(context, features) {
    context.commit("setActiveFeatures", features);
  },

  // Update the report based on the given local data, does not request a new PDF
  async updateReport(context, report) {
    const reportData = {
      title: report.title,
      report_title: report.report_title,
      report_type: report.report_type,
      corporate_group_data: report.corporate_group_data,
      period_end: report.period_end,
      primary: report.primary,
      global: report.global,
      confidential: report.confidential,
      allow_drill_down: report.allow_drill_down,
      landscape: report.landscape,
      link_attachments_in_pdf: report.link_attachments_in_pdf,
      accent_color: report.accent_color,
      origin: report.origin,
      logo_source_type: report.logo_source_type,
      logo_source_id: report.logo_source_id,
      font_family: report.font_family,
    };

    context.commit("setLoading", "report");

    try {
      const { data } = await axios.patch(reportUrl(context), {
        report: reportData,
      });
      context.commit("setReport", data);
    } catch (error) {
      if (error.response?.status === 422) {
        context.commit("setReport", error.response.data);
      } else {
        handleError(error);
      }
    } finally {
      context.commit("stopLoading", "report");
    }
  },

  // Save what is already in the vuex-store and request a new PDF
  async saveReport(context, opts) {
    const options = {
      flash: true,
      ...(opts || {}),
    };
    const { dispatch, state, commit } = context;
    const report = {
      title: state.report.title,
      report_title: state.report.report_title,
      report_type: state.report.report_type,
      corporate_group_data: state.report.corporate_group_data,
      period_end: state.report.period_end,
      primary: state.report.primary,
      global: state.report.global,
      confidential: state.report.confidential,
      allow_drill_down: state.report.allow_drill_down,
      landscape: state.report.landscape,
      link_attachments_in_pdf: state.report.link_attachments_in_pdf,
      accent_color: state.report.accent_color,
      origin: state.report.origin,
      logo_source_type: state.report.logo_source_type,
      logo_source_id: state.report.logo_source_id,
      font_family: state.report.font_family,
    };

    try {
      commit("setLoading", "report");
      const { data } = await axios.patch(reportUrl(context), {
        report,
        flash: options.flash,
      });

      if (state.mode === "financial" || state.mode === "template") {
        data.period_end = report.period_end;
      }

      commit("setReportWithElementsAttachments", data);
      dispatch("requestReportPdfGeneration", { report: data, flash: false });
    } catch (error) {
      if (error.response?.status === 422) {
        commit("setReport", error.response.data);
      } else {
        handleError(error);
      }
    } finally {
      commit("stopLoading", "report");
    }
  },

  async addReference(context, { componentId, reference_type, reference_id }) {
    const url = `${reportUrl(context)}/components/${componentId}/references`;

    try {
      const { data } = await axios.post(url, {
        report_component_reference: {
          reference_type,
          reference_id,
        },
      });

      context.commit("updateComponentReference", data);
    } catch (error) {
      handleError(error);
    }
  },

  async removeReference(context, reference) {
    const url = `${reportUrl(context)}/components/${
      reference.report_component_id
    }/references/${reference.id}`;

    try {
      await axios.delete(url);

      context.commit("deleteComponentReference", reference);
    } catch (error) {
      handleError(error);
    }
  },

  /**
   * Elements
   */
  async createElement(context, element) {
    const tempUuid = uuid.methods.generateUuid();
    context.commit("setLoading", tempUuid);
    try {
      const { data } = await axios.post(reportUrl(context, "components"), {
        report_component: element,
      });
      // Split out positions from the data
      const { positions, children, ...component } = data;

      context.commit("updateElement", component);
      for (const [element_id, position] of Object.entries(positions)) {
        context.commit("updateElementPosition", {
          element_id: parseInt(element_id),
          position,
        });
      }
      (children || []).forEach((child) => {
        context.commit("updateElement", child);
      });
      context.commit("setReport", {
        ...context.state.report,
        updated_at: component.updated_at,
      });

      if (element.component_type === "report_columns") {
        context.dispatch("updateColumnsElement", {
          element: component,
          column_sizes: element.column_sizes,
        });
      }
    } catch (error) {
      if (error.response?.status === 422) {
        context.commit("updateElement", error.response.data);
      } else {
        handleError(error);
      }
    } finally {
      context.commit("stopLoading", tempUuid);
    }
  },

  async updateElement(context, element) {
    try {
      context.commit("setLoading", element.id);
      const nilImage =
        element.component_type === "image" &&
        (element.remove_image || element.image?.upload_state === "saved");
      const { data } = await axios.patch(
        reportUrl(context, `components/${element.id}`),
        {
          report_component: {
            ...element,
            image: nilImage ? null : element.image,
          },
        }
      );

      // Split out positions from the data
      const { positions, ...component } = data;

      context.commit("updateElement", component);
      for (const [element_id, position] of Object.entries(positions)) {
        context.commit("updateElementPosition", {
          element_id: parseInt(element_id),
          position,
        });
      }

      context.commit("setReport", {
        ...context.state.report,
        updated_at: component.updated_at,
      });
    } catch (error) {
      if (error.response?.status === 422) {
        context.commit("updateElement", error.response.data);
      } else {
        handleError(error);
      }
    } finally {
      context.commit("stopLoading", element.id);
    }
  },

  async updateColumnsElement(context, { element, column_sizes }) {
    context.commit("setLoading", element.id);
    try {
      const { data } = await axios.patch(
        reportUrl(context, `components/${element.id}/columns`),
        {
          column_sizes: column_sizes,
        }
      );

      const { children, ...component } = data;

      context.commit("updateElement", component);

      const updatedChildrenIds = (children || []).map((child) => {
        context.commit("updateElement", child);
        return child.id;
      });

      context.getters.getElementsByParent[element.id].forEach((child) => {
        if (!updatedChildrenIds.includes(child.id)) {
          context.commit("removeElement", child);
        }
      });

      context.commit("setReport", {
        ...context.state.report,
        updated_at: component.updated_at,
      });
    } catch (error) {
      if (error.response?.status === 422) {
        context.commit("updateElement", error.response.data);
      } else {
        handleError(error);
      }
    } finally {
      context.commit("stopLoading", element.id);
    }
  },

  async deleteElement(context, element) {
    context.commit("setLoading", element.id);
    try {
      const { data } = await axios.delete(
        reportUrl(context, `components/${element.id}`)
      );

      context.commit("removeElement", element);

      // Remove all child elements for columns
      if (element.component_type === "report_columns") {
        const childElements = findChildElements(state.elements, element);

        childElements.forEach((child) =>
          context.commit("removeElement", child)
        );
      }

      for (const [element_id, position] of Object.entries(data)) {
        context.commit("updateElementPosition", {
          element_id: parseInt(element_id),
          position,
        });
      }

      context.commit("setReport", {
        ...context.state.report,
        updated_at: new Date().toISOString(),
      });
    } catch (error) {
      handleError(error);
    } finally {
      context.commit("stopLoading", element.id);
    }
  },

  async addComponentToLibrary(context, element) {
    context.commit("setLoading", element.id);

    try {
      const { data } = await axios.post(
        reportUrl(context, `components/${element.id}/library`)
      );

      context.commit("updateElement", data);
    } catch (error) {
      handleError(error);
    } finally {
      context.commit("stopLoading", element.id);
    }
  },

  async publish(context, { report, parameters }) {
    const response = await axios.post(`${report.paths.base}/publish`, {
      report: parameters,
    });
    context.commit("setReport", response.data);
  },

  async fetchReportPdf(context, report) {
    if (!report?.id) {
      return;
    }

    try {
      const { data } = await axios.get(`${report.paths.base}/pdf`);
      context.commit("setPdfDocument", { report, document: data });
    } catch (error) {
      if (error?.response?.status !== 404) {
        handleError(error);
      } else {
        context.commit("setPdfStatus", { report, status: "not_found" });
      }
    }
  },

  async requestReportPdfGeneration(context, { report, flash }) {
    if (report.type !== "Report") {
      return;
    }

    try {
      const { data } = await axios.patch(`${report.paths.base}/pdf`, {
        flash: flash,
      });

      context.commit("job_callbacks/updateCallback", data, { root: true });
    } catch (error) {
      handleError(error);
    }
  },
};

export const mutations = {
  setReportWithElementsAttachments(state, report) {
    const { report_components, financial_attachments, ...reportAttributes } =
      report;

    state.report = reportAttributes;
    state.financial_attachments = cloneDeep(financial_attachments) || [];
    if (!state.report.period_end) {
      state.report.period_end = endOfMonth(sub(new Date(), { months: 1 }));
    }

    const elements = cloneDeep(report_components) || [];

    state.elements = elements;
  },

  setReport(state, report) {
    state.report = report;
  },

  updateFinancialAttachment(state, financial_attachment) {
    const index = state.financial_attachments.findIndex(
      (existing) =>
        (existing.id && existing.id === financial_attachment.id) ||
        (existing.uuid && existing.uuid === financial_attachment.uuid)
    );

    if (index === -1) {
      state.financial_attachments.push(financial_attachment);
    } else {
      state.financial_attachments[index] = financial_attachment;
    }
  },

  removeFinancialAttachment(state, financial_attachment) {
    const index = state.financial_attachments.findIndex(
      (existing) =>
        (existing.id && existing.id === financial_attachment.id) ||
        (existing.uuid && existing.uuid === financial_attachment.uuid)
    );

    if (index > -1) {
      state.financial_attachments.splice(index, 1);
    } else {
      console.warn("Could not find financial attachment to remove");
    }
  },

  updateDocument(state, document) {
    const index = state.report.attachments.findIndex(
      (existing) =>
        (existing.id && existing.id === document.id) ||
        (existing.file_uuid && existing.file_uuid === document.file_uuid)
    );

    if (index === -1) {
      state.report.attachments.push(document);
    } else {
      state.report.attachments[index] = document;
    }
  },

  removeDocument(state, document) {
    const index = state.report.attachments.findIndex(
      (existing) =>
        (existing.id && existing.id === document.id) ||
        (existing.file_uuid && existing.file_uuid === document.file_uuid)
    );

    if (index > -1) {
      state.report.attachments.splice(index, 1);
    } else {
      console.warn("Could not find document to remove");
    }
  },

  setActiveFeatures(state, features) {
    state.activeFeatures = features;
  },

  /**
   * Elements
   */
  updateElement(state, element) {
    const index = findElementIndex(state.elements, element);
    if (index > -1) {
      state.elements[index] = element;
    } else {
      state.elements.push(element);
    }
  },

  updateElementPosition(state, { element_id, position }) {
    const index = state.elements.findIndex(
      (existing) => existing.id === element_id
    );
    if (index > -1) {
      const element = state.elements[index];
      state.elements[index] = {
        ...element,
        position: position,
      };
    }
  },

  removeElement(state, element) {
    const index = findElementIndex(state.elements, element);
    if (index > -1) {
      state.elements.splice(index, 1);
    }
  },

  setMode(state, mode) {
    state.mode = mode || "report";
  },

  setPdfDocument(state, { report, document }) {
    const index = state.pdfByReport.findIndex(
      (row) => row.report_id === report.id
    );

    if (index === -1) {
      state.pdfByReport.push({
        report_id: report.id,
        document: document,
        status: "loaded",
      });
    } else {
      state.pdfByReport[index].document = document;
      state.pdfByReport[index].status = "loaded";
    }
  },

  setPdfStatus(state, { report, status }) {
    const index = state.pdfByReport.findIndex(
      (row) => row.report_id === report.id
    );

    if (index === -1) {
      state.pdfByReport.push({
        report_id: report.id,
        document: null,
        status: status,
      });
    } else {
      state.pdfByReport[index].status = status;
    }
  },

  setMovingElement(state, element) {
    state.movingElement = element;
  },

  updateComponentReference(state, component_reference) {
    const componentIndex = state.elements.findIndex(
      (element) => element.id === component_reference.report_component_id
    );

    if (componentIndex === -1) {
      return;
    }

    const references =
      state.elements[componentIndex].report_component_references;
    const index = references.findIndex(
      (reference) => reference.id === component_reference.id
    );

    if (index === -1) {
      references.push(component_reference);
    } else {
      references[index] = component_reference;
    }
  },

  deleteComponentReference(state, component_reference) {
    const componentIndex = state.elements.findIndex(
      (element) => element.id === component_reference.report_component_id
    );

    if (componentIndex === -1) {
      return;
    }

    const references =
      state.elements[componentIndex].report_component_references;
    const index = references.findIndex(
      (reference) => reference.id === component_reference.id
    );

    if (index > -1) {
      references.splice(index, 1);
    }
  },

  setLoading(state, id) {
    state.loadingIds.push(id);
  },

  stopLoading(state, id) {
    state.loadingIds = state.loadingIds.filter((loadingId) => loadingId !== id);
  },
};

function findElementIndex(elements, element) {
  if (!Array.isArray(elements) || !element) {
    return -1;
  }

  return elements.findIndex((existing) => existing.id === element.id);
}

function findChildElements(elements, element) {
  const children = elements.filter((existingElement) => {
    return existingElement.parent_component_id === element.id;
  });

  const grandChildren = elements.filter((existingElement) => {
    return children
      .map((child) => child.id)
      .includes(existingElement.parent_component_id);
  });

  return children.concat(grandChildren);
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
