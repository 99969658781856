<template>
  <be-list-group-item class="p-4">
    <task-container
      action-key="profile_picture_uploaded"
      :section-type="sectionType"
      :onboarding-object="localUser"
      :title="
        $t(
          'components.onboarding.introduction.user_section.upload_profile_picture'
        )
      "
    >
      <template #text>
        <div>
          {{
            $t(
              "components.onboarding.introduction.user_section.upload_profile_picture_description_w_platform_name",
              { platform_name: $platform.theme.translated_platform_name }
            )
          }}
        </div>

        <i18n-t
          v-if="supportUserSettingsUrl && boardeaserBrand"
          keypath="components.onboarding.introduction.user_section.update_settings_description"
          tag="p"
          class="text-muted mt-3"
        >
          <template #link>
            <a :href="supportUserSettingsUrl" target="_blank">
              {{
                $t(
                  "components.onboarding.introduction.user_section.support_guide"
                )
              }}
              <i class="fal fa-external-link small" />
            </a>
          </template>
        </i18n-t>
      </template>

      <template #link>
        <div class="d-none d-md-inline-block">
          <be-link v-be-modal="'upload-profile-picture'" class="p-1">
            {{ uploadImageText }}
          </be-link>
        </div>

        <be-button
          v-be-modal="'upload-profile-picture'"
          class="d-md-none mt-3"
          variant="outline-secondary"
          block
        >
          {{ uploadImageText }}
        </be-button>

        <be-modal
          id="upload-profile-picture"
          :title="uploadImageText"
          :ok-disabled="uploadingFiles || !logoSelected"
          :ok-title="fileUploadButtonTitle"
          @ok="uploadProfilePicture({ skip_flash: true })"
        >
          <single-file-uploader
            :key="`user-${localUser.id}-image-${localUser.updated_at}`"
            v-model:remove="localUser.remove_image"
            :aws-url="url('/aws')"
            :accepted-types="acceptedFileTypes"
            :file="localUser.image_attachment || {}"
            name="user[image]"
            @file-removed="fileRemoved"
            @file-updated="fileUpdated"
            @uploading="(value) => (uploadingFiles = value)"
          />
        </be-modal>
      </template>
    </task-container>
  </be-list-group-item>
</template>

<script>
import TaskContainer from "@/components/onboarding/TaskContainer.vue";
import { IMAGE_TYPES } from "@/constants/file-types";

export default {
  components: {
    TaskContainer,
  },

  props: {
    sectionType: {
      type: String,
      required: true,
    },

    supportUserSettingsUrl: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      localUser: {},
      uploadingFiles: false,
      logoSelected: false,
    };
  },

  computed: {
    boardeaserBrand() {
      return ["boardeaser", "boardeaser_new_brand"].includes(
        this.$platform.theme.theme_name
      );
    },

    acceptedFileTypes() {
      return IMAGE_TYPES.concat(["pdf"]);
    },

    fileUploadButtonTitle() {
      return this.uploadingFiles
        ? this.$i18n.t("buttons.states.loading")
        : this.$i18n.t("buttons.titles.upload");
    },

    uploadImageText() {
      let uploaded = false;
      if (this.$platform.theme.theme_name === "fortnox") {
        uploaded =
          this.localUser?.progress?.user_info_fortnox?.profile_picture_uploaded;
      } else {
        uploaded =
          this.localUser?.progress?.user_info?.profile_picture_uploaded;
      }

      if (uploaded) {
        return this.$t("components.onboarding.change_image");
      } else {
        return this.$t("buttons.titles.upload");
      }
    },
  },

  mounted() {
    this.localUser = this.cloneDeep(this.$currentUser);
    this.logoSelected = !!this.localUser.image_attachment;

    // Handle Fortnox users who uploaded a profile picture
    // before it was moved to user_info_fortnox
    if (
      this.$platform.theme.theme_name === "fortnox" &&
      this.localUser.image_attachment &&
      this.localUser.progress?.user_info?.profile_picture_uploaded &&
      !this.localUser.progress?.user_info_fortnox?.profile_picture_uploaded
    ) {
      this.uploadProfilePicture({ skip_flash: true });
    }
  },

  methods: {
    fileUpdated(file) {
      this.localUser.image_attachment = file;
      this.localUser.image = file;

      this.logoSelected = true;
      this.uploadingFiles = false;
    },

    async updateUser() {
      const { data } = await axios.patch("/users/profile", {
        user: this.localUser,
        skip_flash: true,
      });

      this.localUser = this.cloneDeep(data);
      this.$store.commit("current_user/setUser", this.localUser);
    },

    fileRemoved() {
      this.localUser.image = null;
      this.localUser.image_attachment = null;

      try {
        this.updateUser();
        this.logoSelected = false;
      } catch (error) {
        this.handleError(error);
      }
    },

    uploadProfilePicture() {
      try {
        this.updateUser();
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
