<template>
  <div>
    <template
      v-if="
        !annualReportDocument &&
        !annualReportIxbrlDocument &&
        canUploadDocument('annual_report')
      "
    >
      <template
        v-if="
          ixbrlAllowed &&
          hasActiveFeature('consolidated_annual_reports') &&
          !showPdfGenerating
        "
      >
        <h4 v-if="!annualReportDocument">
          {{ $t("components.annual_reports.upload_tab.fetch") }}
        </h4>

        <visualby-annual-report-fetcher
          v-if="showAnnualReportUploader && !annualReportDocument"
          :annual-report="annualReport"
        />
      </template>

      <h4 v-if="!annualReportDocument">
        {{ $t("components.annual_reports.upload_tab.upload_label") }}
      </h4>

      <p>
        {{ $t("components.annual_reports.upload_tab.upload_instructions") }}
      </p>

      <annual-report-documents
        v-if="showAnnualReportUploader"
        document-key="annual_report"
      />
    </template>

    <table v-if="showAnnualReportDocumentTable" class="table table-hover">
      <thead>
        <tr>
          <th colspan="3">
            {{ $t("models.annual_report.documents.annual_report") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-if="showPdfGenerating">
          <td colspan="3">
            <be-spinner>
              {{ $t("components.annual_reports.generating_pdf") }}
            </be-spinner>
          </td>
        </tr>

        <tr v-else-if="annualReportDocument">
          <td colspan="2">
            <document-link
              :document-id="annualReportDocument.id"
              :filename="filenameWithSize(annualReportDocument)"
            />
          </td>

          <td class="text-right">
            <template v-if="annualReportSortedSignatures.length">
              <be-button
                v-be-modal="`annual-report-signatures-${annualReport.id}`"
                variant="outline-secondary"
                size="sm"
              >
                {{ $t("models.document.e_signing") }}
                {{ signatureProgressText(annualReportSortedSignatures) }}
              </be-button>

              <be-modal
                :id="`annual-report-signatures-${annualReport.id}`"
                :title="$t('models.document.e_signing')"
                ok-only
                :ok-title="$t('buttons.titles.close')"
              >
                <signatures-table
                  :signatures="annualReportSortedSignatures"
                  :document="annualReportDocument"
                />
              </be-modal>
            </template>

            <be-button
              v-if="
                !annualReportIxbrlDocument &&
                canRemoveDocument(annualReportDocument)
              "
              v-be-tooltip="$t('buttons.titles.remove')"
              variant="danger"
              size="sm"
              icon="fa-times"
              @click="confirmRemoveDocument(annualReportDocument)"
            />
          </td>
        </tr>

        <tr v-if="annualReportIxbrlDocument">
          <td colspan="2">
            <document-link
              :document-id="annualReportIxbrlDocument.id"
              :filename="filenameWithSize(annualReportIxbrlDocument)"
            />
          </td>

          <td class="text-right">
            <be-button
              v-if="canRemoveDocument(annualReportIxbrlDocument)"
              v-be-tooltip="$t('buttons.titles.remove')"
              variant="danger"
              size="sm"
              icon="fa-times"
              @click="confirmRemoveDocument(annualReportIxbrlDocument)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <be-alert
      v-else-if="!annualReportIxbrlDocument"
      variant="info"
      class="mt-2"
    >
      {{ $t("components.annual_reports.upload_tab.not_uploaded_info") }}
    </be-alert>

    <div v-if="showIxbrlSignatureSettings" class="d-flex justify-content-end">
      <be-button
        v-if="annualReportIxbrlDocument"
        v-be-modal="`ixbrl-signature-modal-${annualReport.id}`"
        size="sm"
        class="mb-3"
      >
        <template v-if="can('sign_manually', 'annual_report')">
          {{ $t("components.annual_reports.upload_tab.edit_ixbrl_signatures") }}
        </template>

        <template v-else>
          {{ $t("components.annual_reports.upload_tab.set_ixbrl_signatures") }}
        </template>
      </be-button>
    </div>

    <ixbrl-signatures-modal
      :id="`ixbrl-signature-modal-${annualReport.id}`"
      :annual-report="annualReport"
    />

    <ixbrl-audit-statement-form v-if="showIxbrlAuditStatementSettings" />

    <template v-if="showSignatureActions">
      <p
        v-if="
          annualReportIxbrlDocument &&
          can('sign_manually', 'annual_report') &&
          can('request_signatures', 'annual_report')
        "
      >
        {{ $t("components.annual_reports.ixbrl_any_signature_info") }}
      </p>

      <p
        v-else-if="
          annualReportIxbrlDocument &&
          can('request_signatures', 'annual_report')
        "
      >
        {{ $t("components.annual_reports.ixbrl_only_digital_signature_info") }}
      </p>

      <p
        v-else-if="
          can('request_signatures', 'annual_report') ||
          can('sign_manually', 'annual_report')
        "
      >
        {{ $t("components.annual_reports.pdf_signature_info") }}
      </p>

      <be-alert v-if="showMissingCeoWarning" variant="warning">
        {{ $t("components.annual_reports.upload_tab.no_ceo_warning") }}
      </be-alert>

      <div class="d-flex justify-content-end">
        <be-button
          v-if="can('sign_manually', 'annual_report', 'update')"
          variant="outline-primary"
          @click="confirmTriggerEvent('sign_manually', 'annual_report')"
        >
          <template v-if="annualReportIxbrlDocument">
            {{ $t("components.annual_reports.ixbrl_signed") }}
          </template>

          <template v-else>
            {{ $t("components.annual_reports.manually_signed") }}
          </template>
        </be-button>

        <be-button
          v-if="
            can('request_signatures', 'annual_report', 'update') &&
            !currentUserIsAuditor
          "
          :variant="
            requestSignaturesIsBestOption ? 'primary' : 'outline-primary'
          "
          :disabled="disableSendForSignatures"
          class="ml-2"
          @click="sendForSigning"
        >
          {{ $t("documents.document_options.e_sign") }}
        </be-button>
      </div>

      <annual-report-signature-form
        v-if="signatureDocument"
        :available-memberships="$currentCompany.memberships"
        :annual-report="annualReport"
        :doc="signatureDocument"
        @closed="afterSignaturesRequested"
      />
    </template>
  </div>
</template>

<script>
import AnnualReportTabBase from "./mixins/AnnualReportTabBase.vue";
import VisualbyAnnualReportFetcher from "./VisualbyAnnualReportFetcher.vue";
import IxbrlAuditStatementForm from "./IxbrlAuditStatementForm.vue";
import SignaturesTable from "./SignaturesTable.vue";
import IxbrlSignaturesModal from "./IxbrlSignaturesModal.vue";

export default {
  components: {
    VisualbyAnnualReportFetcher,
    IxbrlAuditStatementForm,
    SignaturesTable,
    IxbrlSignaturesModal,
  },

  mixins: [AnnualReportTabBase],

  data() {
    return {
      saving: false,
      signatureDocument: {}, // This is used to show SignatureForm
    };
  },

  computed: {
    annualReportState() {
      return this.annualReport.annual_report_state;
    },

    disableResponsibleUser() {
      return !(
        this.policy.update &&
        this.annualReport.state_events &&
        this.annualReport.state_events.length > 0
      );
    },

    disableSendForSignatures() {
      return !this.can("request_signatures", "annual_report");
    },

    showAnnualReportDocumentTable() {
      return this.annualReportDocument || this.annualReportIxbrlDocument;
    },

    showAnnualReportUploader() {
      return !this.annualReportDocument && !this.annualReportIxbrlDocument;
    },

    showPdfGenerating() {
      return this.annualReportIxbrlDocument && this.isPdfGenerating;
    },

    showMissingCeoWarning() {
      return (
        !this.isFoundation &&
        this.companyCeos.length == 0 &&
        !this.annualReport.ceo_id
      );
    },

    showSignatureActions() {
      return (
        this.can("sign_manually", "annual_report", "update") ||
        this.can("request_signatures", "annual_report", "update")
      );
    },

    showIxbrlSignatureSettings() {
      return this.annualReportIxbrlDocument && this.showSignatureActions;
    },

    showIxbrlAuditStatementSettings() {
      return this.hasAuditorSignature && this.showIxbrlSignatureSettings;
    },

    signatories() {
      return this.companyUsers || [];
    },

    signatureUrl() {
      return this.annualReport.paths.signature_requests;
    },

    signManuallyIsBestOption() {
      return this.can("sign_manually", "annual_report") && !this.hasEsign;
    },

    requestSignaturesIsBestOption() {
      return (
        this.can("request_signatures", "annual_report") &&
        (!this.annualReportIxbrlDocument || !!this.annualReport.audit_place)
      );
    },
  },

  watch: {
    "annualReport.annual_report_document": function (value) {
      if (!value) {
        this.signatureDocument = {};
      }
    },
  },

  methods: {
    async sendForSigning() {
      if (this.isFoundation && !this.annualReport.auditor_needed) {
        // Trigger confirm and ask if the user wants to send for signing without auditors
        const isConfirmed = await this.promptConfirm({
          title: this.$t(
            "components.annual_reports.upload_tab.foundation.send_for_signing_without_auditors"
          ),

          description: this.$t(
            "components.annual_reports.upload_tab.foundation.send_for_signing_without_auditors_text"
          ),

          confirmButtonText: this.$t("buttons.titles.continue"),
        });

        if (!isConfirmed) {
          return;
        }
      }

      this.signatureDocument = this.annualReportDocument;
    },
  },
};
</script>
