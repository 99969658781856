<template>
  <div class="card">
    <div class="card-body">
      <be-alert v-if="attachments.length === 0" variant="info">
        {{ $t("components.meetings.material.attendances.no_attachments") }}
      </be-alert>

      <template v-else>
        <div class="d-md-flex justify-content-end">
          <be-button
            v-if="attachments.length > 1"
            class="mb-2"
            size="sm"
            @click="includeAllAttachments"
          >
            {{ includeAllButtonText }}
          </be-button>
        </div>

        <be-table :items="attachments" :fields="fields">
          <template #title="{ item }">
            <document-link :document-id="item.id" :filename="item.title" />
          </template>

          <template #item="{ item }">
            {{ item.item_description }}
          </template>

          <template #selected="{ item }">
            <be-spinner v-if="locked[item.id]" />

            <template v-else>
              <be-form-group
                :label="$t('activerecord.attributes.document_title.selected')"
                :label-for="inputId(item, 'selected')"
                label-sr-only
                class="m-0"
              >
                <be-form-checkbox
                  :id="inputId(item, 'selected')"
                  v-model="item.selected"
                  size="lg"
                  class="d-inline-block"
                  @change="update(item)"
                />
              </be-form-group>
            </template>
          </template>

          <template #linked="{ item }">
            <be-spinner v-if="locked[item.id]" />

            <template v-else>
              <be-form-group
                :label="$t('activerecord.attributes.document_title.linked')"
                :label-for="inputId(item, 'linked')"
                label-sr-only
                class="m-0"
              >
                <be-form-checkbox
                  :id="inputId(item, 'linked')"
                  v-model="item.linked"
                  v-be-tooltip="{
                    title: $t(
                      'components.meetings.material.attachments.has_to_be_included'
                    ),
                    disabled: item.selected,
                  }"
                  :disabled="!item.selected"
                  size="lg"
                  class="d-inline-block"
                  @change="update(item)"
                />
              </be-form-group>
            </template>
          </template>
        </be-table>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      locked: {},
    };
  },

  computed: {
    ...mapGetters({
      items: "material/items",
      meeting: "material/meeting",
      material: "material/material",
      attachments: "material/attachments",
    }),

    fields() {
      const fields = [
        {
          key: "title",
          label: this.translateAttribute("document_title", "title"),
        },
        {
          key: "item",
          label: this.translateAttribute("document_title", "item"),
        },
        {
          key: "selected",
          label: this.translateAttribute("document_title", "selected"),
          class: "col-shrink",
        },
      ];

      if (!this.material.external_uploaded_at) {
        fields.push({
          key: "linked",
          label: this.translateAttribute("document_title", "linked"),
          class: "col-shrink",
        });
      }

      return fields;
    },

    allSelected() {
      return this.attachments.every((attachment) => attachment.selected);
    },

    includeAllButtonText() {
      if (this.allSelected) {
        return this.$t(
          "components.meetings.material.attachments.deselect_included"
        );
      } else {
        return this.$t(
          "components.meetings.material.attachments.include_all_in_minutes"
        );
      }
    },
  },

  watch: {
    attachments: {
      handler() {
        this.locked = {};
      },

      deep: true,
    },
  },

  mounted() {
    this.fetchAttachments();
  },

  methods: {
    ...mapActions("material", ["fetchAttachments", "updateAttachment"]),

    inputId(attachment, field) {
      let label = `attachment-${attachment.id}`;
      if (field) {
        label = `${label}-${field}`;
      }
      return label;
    },

    update(attachment) {
      this.locked[attachment.id] = true;
      this.updateAttachment(attachment);
    },

    includeAllAttachments() {
      let selected = false;

      if (this.allSelected) {
        selected = false;
      } else {
        selected = true;
      }

      this.attachments.forEach((attachment) => {
        attachment.selected = selected;
        this.update(attachment);
      });
    },
  },
};
</script>
