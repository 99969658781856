<template>
  <be-modal
    :id="modalId"
    :title="modalTitle"
    size="xl"
    :dialog-class="{
      'modal-fullscreen': fullscreen,
    }"
    :body-class="`${fullscreen ? 'p-0' : 'bg-light'}`"
    :no-footer="fullscreen"
    @show="fetchDocument"
    @hidden="fullscreen = false"
  >
    <template #header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="text-break">
          <i :class="`fal ${iconClass} mx-2`" />

          <span class="d-none d-lg-inline-block">{{ modalTitle }}</span>

          <span class="d-lg-none">{{ truncatedModalTitle }}</span>
        </div>

        <div>
          <be-button
            v-if="enableFullscreen && previewable"
            :icon="fullscreen ? 'fa-compress-alt' : 'fa-expand-alt'"
            size="sm"
            class="text-muted text-decoration-none"
            @click="toggleFullscreen"
          >
            {{
              fullscreen
                ? $t("buttons.titles.minimize")
                : $t("buttons.titles.fullscreen")
            }}
          </be-button>
        </div>
      </div>
    </template>

    <single-document
      v-if="localDocument"
      :document="localDocument"
      :fullscreen="fullscreen"
    />

    <div v-else class="d-flex align-items-center justify-content-center p-6">
      <be-spinner>
        {{ $t("buttons.states.loading") }}
      </be-spinner>
    </div>

    <template #footer="{ cancel }">
      <be-button variant="light" @click="cancel">
        {{ $t("buttons.titles.close") }}
      </be-button>

      <be-button
        v-if="localDocument"
        variant="primary"
        :href="localDocument.paths.base"
      >
        {{ $t("components.documents.document_modal.open_in_document_archive") }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
import { getClassNameForFilename } from "@/vendor/font-awesome-filetypes";
import { truncateText } from "@/utils/text-utils";
import { contentTypeIsSupported } from "@/utils/file-preview";
import { mapMutations } from "vuex";

import SingleDocument from "@/components/documents/SingleDocument.vue";

export default {
  components: {
    SingleDocument,
  },

  props: {
    modalId: {
      type: String,
      default: `document-preview-modal`,
    },

    documentId: {
      type: Number,
      required: true,
    },

    document: {
      type: Object,
      required: false,
      default: null,
    },

    companyId: {
      type: Number,
      default: null,
    },

    enableFullscreen: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ["reading-updated"],

  data() {
    return {
      localDocument: this.document ? this.cloneDeep(this.document) : null,
      fullscreen: false,
    };
  },

  computed: {
    modalTitle() {
      let title = this.localDocument?.title_with_filename || "";

      if (this.localDocument?.owner_description) {
        title += ` (${this.localDocument.owner_description})`;
      }

      return title;
    },

    truncatedModalTitle() {
      return truncateText(this.modalTitle, 40);
    },

    iconClass() {
      return getClassNameForFilename(this.localDocument?.filename);
    },

    previewable() {
      return (
        this.localDocument &&
        contentTypeIsSupported(this.localDocument.preview.content_type)
      );
    },

    documentUrl() {
      if (this.localDocument?.paths.base) {
        return this.localDocument.paths.base;
      }

      if (this.companyId) {
        return this.url(`/documents/${this.documentId}`, {
          nanoid: this.companyId,
        });
      } else if (this.$currentAdminPanel?.id) {
        return this.url(`/documents/${this.documentId}`);
      } else {
        return this.url(`/documents/${this.documentId}`, {
          nanoid: this.$currentCompany.nanoid,
        });
      }
    },
  },

  methods: {
    ...mapMutations("readings", ["updateReading"]),

    async fetchDocument() {
      if (this.documentId) {
        try {
          const response = await axios.get(this.documentUrl);
          this.localDocument = response.data;
          if (this.localDocument.user_reading) {
            this.updateReading(this.localDocument.user_reading);
            this.$emit("reading-updated", this.localDocument.user_reading);
          }
        } catch (error) {
          this.handleError(error);
        }
      }
    },

    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
  },
};
</script>
