<template>
  <component
    :is="computedTag"
    v-bind="computedAttrs"
    :class="computedClasses"
    @click="onClick"
  >
    <slot />

    <span class="text-decoration-none">
      <i v-if="isExternal" class="fal fa-external-link fa-sm ml-1" />
    </span>
  </component>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  name: "BeLink",

  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    href: {
      type: String,
      required: false,
      default: "",
    },

    noExternalIcon: {
      type: Boolean,
      required: false,
      default: false,
    },

    rel: {
      type: String,
      required: false,
      default: null,
    },

    small: {
      type: Boolean,
      required: false,
      default: false,
    },

    tag: {
      type: String,
      required: false,
      default: null,
    },

    target: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["click"],

  computed: {
    computedAttrs() {
      const {
        disabled,
        href,
        computedRel,
        computedTag,
        computedTarget,
        dataAttrs,
      } = this;

      const isLink = computedTag === "a";
      const linkAttrs = isLink
        ? {
            href,
            rel: computedRel,
            target: computedTarget,
          }
        : {};

      return {
        disabled: disabled || null,
        "aria-disabled": disabled ? "true" : null,
        ...linkAttrs,
        ...dataAttrs,
      };
    },

    computedClasses() {
      const { active, disabled, computedTag, small } = this;
      const isButton = computedTag === "button";

      return [
        "d-inline-flex align-items-center",
        {
          active,
          disabled,
          small,

          "btn btn-link p-0 shadow-none border-0 text-left text-reset-line-height":
            isButton,
        },
      ];
    },

    computedRel() {
      if (this.target === "_blank") {
        return "noopener noreferrer";
      } else if (this.rel) {
        return this.rel;
      } else {
        return null;
      }
    },

    computedTag() {
      if (this.tag) {
        return this.tag;
      }

      return this.href ? "a" : "button";
    },

    computedTarget() {
      if (this.target) {
        return this.target;
      }

      return this.isExternal ? "_blank" : "_self";
    },

    dataAttrs() {
      return Object.keys(this.$attrs).reduce((attrs, key) => {
        if (key.startsWith("data-")) {
          attrs[key] = this.$attrs[key];
        }

        return attrs;
      }, {});
    },

    isExternal() {
      if (this.noExternalIcon) {
        return false;
      }

      // If the href does not start with a slash and the host is not the same as the current host
      // then it is an external link and we should show the external link icon
      try {
        return (
          this.href &&
          !this.href.startsWith("/") &&
          !this.href.startsWith("#") &&
          !this.href.startsWith("mailto:") &&
          !this.href.startsWith("tel:") &&
          new URL(this.href).host !== window.location.host
        );
      } catch {
        return false;
      }
    },
  },

  methods: {
    onClick(event) {
      if (this.disabled) {
        event.preventDefault();
        return;
      }

      if (this.href === "#" || !this.href) {
        // Prevent browser from scrolling to top
        event.preventDefault();
        event.stopPropagation();

        // Remove focus from clicked element
        event.target.blur();
      }

      // Emit to parent
      this.$emit("click", event);

      // Emit to global event bus
      EventBus.emit("be::link::clicked", event);
    },
  },
};
</script>
