<template>
  <div class="tab-pane active">
    <div class="row">
      <div class="col-12 col-xl-6 mb-4 mb-xl-0">
        <h3>
          {{ $t("components.annual_reports.upload_tab.settings_title") }}
        </h3>

        <be-form-group
          v-if="ixbrlAllowed"
          :label="
            $t('components.annual_reports.upload_tab.delivery_method.title')
          "
          label-for="delivery_method"
          :error="getErrors(annualReport, ['delivery_method'])"
          required
        >
          <be-form-select
            id="delivery_method"
            v-model="annualReport.delivery_method"
            name="annual_report[delivery_method]"
            :options="deliveryMethodOptions"
            :disabled="!enableSettingsFields"
            required
            :state="validationState(annualReport, ['delivery_method'])"
            @change="(event) => handleSettingsChange('delivery_method', event)"
          />
        </be-form-group>

        <be-alert
          v-if="
            annualReport.delivery_method == 'digital' &&
            !annualReport.organization_number
          "
          variant="warning"
        >
          {{
            $t("components.annual_reports.upload_tab.org_without_org_number")
          }}
        </be-alert>

        <template v-if="annualReport.delivery_method === 'digital'">
          <template v-if="enableSettingsFields">
            <be-form-group
              :label="
                $t(
                  'components.annual_reports.upload_tab.certificate_signee_id.title'
                )
              "
              label-for="certificate-signee"
              :error="getErrors(annualReport, ['certificate_signee_id'])"
              :description="
                $t('components.annual_reports.hints.certificate_signee_id')
              "
              required
            >
              <be-form-select
                id="certificate-signee"
                v-model="annualReport.certificate_signee_id"
                name="annual_report[certificate_signee_id]"
                :options="certificateSigneeOptions"
                :disabled="!enableSettingsFields"
                required
                :state="
                  validationState(annualReport, ['certificate_signee_id'])
                "
                @change="
                  (event) =>
                    handleSettingsChange('certificate_signee_id', event)
                "
              />
            </be-form-group>

            <be-form-group
              v-if="annualReport.certificate_signee_id == null"
              label-for="certificate-signee-name"
              :label="
                $t(
                  'components.annual_reports.upload_tab.certificate_signee_name.title'
                )
              "
              :error="getErrors(annualReport, ['certificate_signee_name'])"
              required
              class="ml-xl-2"
            >
              <be-form-input
                id="certificate-signee-name"
                v-model="annualReport.certificate_signee_name"
                name="annual_report[certificate_signee_name]"
                :state="
                  validationState(annualReport, ['certificate_signee_name'])
                "
                @change="
                  (event) =>
                    handleSettingsChange('certificate_signee_name', event || '')
                "
              />
            </be-form-group>

            <be-form-group
              v-if="annualReport.certificate_signee_id == null"
              label-for="certificate-signee-identification-number"
              :label="
                $t(
                  'components.annual_reports.upload_tab.certificate_signee_identification_number.title'
                )
              "
              :error="
                getErrors(annualReport, [
                  'certificate_signee_identification_number',
                ])
              "
              required
              class="ml-xl-2"
            >
              <be-form-input
                id="certificate-signee-identification-number"
                v-model="annualReport.certificate_signee_identification_number"
                name="annual_report[certificate_signee_identification_number]"
                :state="
                  validationState(annualReport, [
                    'certificate_signee_identification_number',
                  ])
                "
                @change="
                  (event) =>
                    handleSettingsChange(
                      'certificate_signee_identification_number',
                      event || ''
                    )
                "
              />
            </be-form-group>
          </template>

          <template v-else>
            <be-form-group
              label-for="certificate-signee"
              :label="
                $t(
                  'components.annual_reports.upload_tab.certificate_signee_id.title'
                )
              "
              required
            >
              <be-form-input
                id="certificate-signee"
                :value="`${annualReport.certificate_signee_name} (${annualReport.certificate_signee_identification_number})`"
                disabled
              />
            </be-form-group>
          </template>

          <be-alert v-if="showNoIdentificationNumberWarning" variant="warning">
            {{
              $t(
                annualReport.certificate_signee_id === $currentUser.id
                  ? "components.annual_reports.upload_tab.missing_identification_number_self"
                  : "components.annual_reports.upload_tab.missing_identification_number"
              )
            }}
          </be-alert>
        </template>

        <be-form-group
          v-if="isFoundation"
          :label="$t('activerecord.attributes.annual_report.county_code')"
          label-for="county_code"
          :error="getErrors(annualReport, ['county_code'])"
          required
        >
          <be-form-select
            id="county_code"
            v-model="annualReport.county_code"
            name="annual_report[county_code]"
            :options="countyOptions"
            required
            :state="validationState(annualReport, ['county_code'])"
            @change="(event) => handleSettingsChange('county_code', event)"
          />
        </be-form-group>

        <be-form-group
          label-for="responsible_id"
          :label="$t('components.annual_reports.upload_tab.responsible_id')"
          :error="getErrors(annualReport, ['responsible_id'])"
          :description="$t('components.annual_reports.hints.responsible_id')"
          required
        >
          <be-form-select
            id="responsible_id"
            v-model="annualReport.responsible_id"
            name="annual_report[responsible_id]"
            :options="responsibleUserOptions"
            :disabled="disableResponsibleUser"
            required
            :state="validationState(annualReport, ['responsible_id'])"
            @change="(event) => handleSettingsChange('responsible_id', event)"
          />
        </be-form-group>

        <be-form-group
          v-if="!isFoundation"
          label-for="ceo_id"
          :label="$t('components.annual_reports.upload_tab.ceo_id')"
          :error="getErrors(annualReport, ['ceo_id'])"
          :description="$t('components.annual_reports.hints.ceo_id')"
          required
        >
          <be-form-select
            id="ceo_id"
            v-model="annualReport.ceo_id"
            name="annual_report[ceo_id]"
            :options="ceoOptions"
            :disabled="!enableSettingsFields"
            required
            :state="validationState(annualReport, ['ceo_id'])"
            @change="(event) => handleSettingsChange('ceo_id', event)"
          />
        </be-form-group>

        <be-form-checkbox
          id="auditor_needed"
          v-model="annualReport.auditor_needed"
          name="annual_report[auditor_needed]"
          class="mb-3"
          :disabled="!enableSettingsFields"
          :state="validationState(annualReport, ['auditor_needed'])"
          @change="(event) => handleSettingsChange('auditor_needed', event)"
        >
          {{ $t("components.annual_reports.upload_tab.auditor_needed") }}
        </be-form-checkbox>

        <div class="d-lg-flex justify-content-between">
          <div v-if="showCompanyConfiguration">
            <be-button
              v-if="platformEnabled('company_self_administration')"
              :href="url(`/settings`)"
              variant="outline-secondary"
            >
              {{
                $t("components.annual_reports.upload_tab.organization_settings")
              }}
            </be-button>

            <be-button :href="url(`/memberships`)" variant="outline-secondary">
              {{ $t("application.members") }}
            </be-button>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-6">
        <h3>{{ $t("components.annual_reports.documents.title") }}</h3>

        <annual-report-upload />

        <board-statement-upload v-if="isFoundation" />
      </div>
    </div>
  </div>
</template>

<script>
import AnnualReportTabBase from "./mixins/AnnualReportTabBase.vue";
import AnnualReportUpload from "./AnnualReportUpload.vue";
import BoardStatementUpload from "./BoardStatementUpload.vue";

export default {
  components: {
    AnnualReportUpload,
    BoardStatementUpload,
  },

  mixins: [AnnualReportTabBase],

  data() {
    return {
      auditors: [],
      saving: false,
    };
  },

  computed: {
    ceoOptions() {
      let ceos = [
        {
          text: this.$i18n.t("components.annual_reports.include_blanks.ceo_id"),
          value: null,
        },
      ];

      if (this.can("upload", "annual_report")) {
        this.companyCeos.map(({ user }) => {
          ceos.push({
            text: user.name,
            value: user.id,
          });
        });
      } else if (this.annualReport.ceo_name) {
        ceos.push({
          text: this.annualReport.ceo_name,
          value: this.annualReport.ceo_id,
        });
      }

      return ceos;
    },

    deliveryMethodOptions() {
      return [
        {
          text: this.$i18n.t(
            "components.annual_reports.upload_tab.delivery_method.manual"
          ),

          value: "manual",
        },
        {
          text: this.$i18n.t(
            "components.annual_reports.upload_tab.delivery_method.digital"
          ),

          value: "digital",
        },
      ];
    },

    countyOptions() {
      return Object.entries(this.annualReport.county_collection || {}).map(
        ([value, text]) => ({
          value,
          text,
        })
      );
    },

    disableResponsibleUser() {
      return !(
        this.policy.update &&
        this.annualReport.state_events &&
        this.annualReport.state_events.length > 0
      );
    },

    enableSettingsFields() {
      return this.can("upload", "annual_report", "update");
    },

    showCompanyConfiguration() {
      return this.can("upload", "annual_report");
    },

    showSettingsSubmit() {
      return (
        this.policy.update &&
        (!this.disableResponsibleUser || this.enableSettingsFields)
      );
    },

    responsibleUserOptions() {
      return this.companyUsers.map((user) => ({
        text: user.name,
        value: user.id,
      }));
    },

    certificateSigneeOptions() {
      const options = this.companyUsers.map((user) => ({
        text: user.name,
        value: user.id,
      }));
      options.push({
        text: this.$t(
          "components.annual_reports.upload_tab.certificate_signee_id.other"
        ),

        value: null,
      });
      return options;
    },

    showNoIdentificationNumberWarning() {
      if (
        this.annualReport.delivery_method !== "digital" ||
        this.annualReport.certificate_signee_id === null
      ) {
        return false;
      }

      const signee = this.companyUsers.find(
        (user) => user.id === this.annualReport.certificate_signee_id
      );
      return !signee.identification_number;
    },
  },

  mounted() {
    // Clear responsible user if not found as user
    const userOptionsIndex = this.responsibleUserOptions.findIndex(
      (option) => option.value === this.annualReport.responsible_id
    );
    if (userOptionsIndex < 0) {
      this.annualReport.responsible_id = null;
    }

    // Set default responsible user
    let responsible_id = this.annualReport.responsible_id;
    if (!responsible_id) {
      responsible_id = this.responsibleUserOptions[0]?.value;
    }
    if (responsible_id) {
      this.annualReport.responsible_id = responsible_id;
    }

    // Clear ceo if not found as user
    if (this.ceoOptions.filter((option) => option.value).length == 0) {
      this.annualReport.ceo_id = null;
    }
  },

  methods: {
    async saveSettings(changedAnnualReport) {
      this.saving = true;
      if (
        changedAnnualReport.delivery_method !== "digital" ||
        changedAnnualReport.certificate_signee_id !== null
      ) {
        changedAnnualReport.certificate_signee_name = null;
        changedAnnualReport.certificate_signee_identification_number = null;
      }
      await this.updateAnnualReport(changedAnnualReport);
      this.saving = false;
    },

    handleSettingsChange(attribute, value) {
      const changedAnnualReport = { ...this.annualReport };
      changedAnnualReport[attribute] = value;
      this.saveSettings(changedAnnualReport);
      this.clearErrors(this.annualReport, [attribute]);
    },
  },
};
</script>
