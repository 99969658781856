import {
  TAnalyticsWindowObject,
  initWebAnalytics,
  trackPageView,
} from "@/vendor/fortnox-web-analytics/web-analytics-script.es.js";
import { errorLoggerCallback } from "./fortnox-web-analytics-error-logger";

declare global {
  interface Window {
    fortnoxWebAnalyticsDebug?: boolean;
    fortnoxWebAnalyticsLoaded?: boolean;
    fortnoxWebAnalytics: TAnalyticsWindowObject;
    piwikApplicationId?: string;
  }
}

export const initFortnoxWebAnalytics = (): void => {
  if (window.fortnoxWebAnalyticsLoaded) {
    console.debug("FortnoxWebAnalytics already loaded");
    return;
  }

  if (!window.piwikApplicationId) {
    console.warn(
      "No application ID for Piwik found in window object. Web analytics will not be loaded"
    );
    return;
  }

  if (window.fortnoxWebAnalytics) window.fortnoxWebAnalytics = {};

  initWebAnalytics({
    washViewCallback,
    errorLoggerCallback,
    applicationId: window.piwikApplicationId,
    //cookieDomain: '',
    // piwik: {
    //   translateCustomDimensionKeyCallback: (key) => null,
    // }
  });

  window.fortnoxWebAnalyticsLoaded = true;
};

export const trackFortnoxWebAnalyticsPageView = (): void => {
  // The web-analytics-script always adds the current url as view.
  trackPageView({
    data: {
      component: "Boardeaser",
    },
  });
};

const washViewCallback = (view: string): string => {
  const url = new URL(view);
  const protocol = url.protocol;
  const hostname = url.hostname;
  const port = url.port ? `:${url.port}` : ""; // Only add port if it exists
  const fullHostName = `${protocol}//${hostname}${port}`;

  const washedUrl = fullHostName + url.pathname;

  return washedUrl;
};
