/**
 * Array of file types that are supported by the file preview component.
 */
const supportedContentTypes = [
  "application/javascript",
  "application/json",
  "application/pdf",
  "application/xml",
  "audio/aac",
  "audio/mpeg",
  "audio/ogg",
  "audio/opus",
  "audio/wav",
  "image/avif",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/vnd.microsoft.icon",
  "image/webp",
  "text/css",
  "text/html",
  "text/javascript",
  "text/plain",
  "video/mp4",
  "video/ogg",
  "video/webm",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

/**
 * Returns boolean indicating whether the file is a valid content type to preview.
 * @param {*} contentType {string} "image/png"
 * @returns {boolean}
 */
export const contentTypeIsSupported = (contentType) => {
  return supportedContentTypes.includes(contentType);
};
