<template>
  <be-modal
    :id="id"
    :title="$t('components.meetings.availability_modal.title')"
    :ok-title="$t('buttons.titles.close')"
    ok-variant="light"
    ok-only
    size="xl"
  >
    <be-table-simple>
      <thead>
        <tr>
          <th />

          <th v-for="user in otherUsers" :key="user.id" class="text-center">
            <user-avatar :user="user" />
          </th>

          <th class="text-center">
            <user-avatar :user="currentUser" />
          </th>

          <th />
        </tr>
      </thead>

      <tbody>
        <tr v-for="suggestion in savedSuggestions" :key="suggestion.id">
          <td>
            {{ formatSuggestionTime(suggestion) }}
          </td>

          <td v-for="user in otherUsers" :key="user.id" class="text-center">
            <div :class="getAvailabilityClass(suggestion, user)" />
          </td>

          <td class="col-shrink">
            <availability-choices
              :meeting-sealed="meeting.is_sealed"
              :suggestion="suggestion"
              button-size="sm"
              @mark-availability="$emit('mark-availability', $event)"
            />
          </td>

          <td v-if="suggestion.selected_at" class="text-center text-muted">
            <i class="fas fa-check-circle mr-1 text-success" />
            {{ $t("components.meetings.availability_modal.selected") }}
          </td>

          <td v-else class="text-center">
            <be-button
              variant="outline-secondary"
              size="sm"
              inline
              @click="onPickSuggestionDate(suggestion)"
            >
              {{ $t("components.meetings.suggestions.select_date") }}
            </be-button>
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <th class="col-shrink">
            <be-button
              size="sm"
              variant="outline-secondary"
              inline
              @click="onCheckAll"
            >
              {{ checkAllButtonText }}
            </be-button>
          </th>

          <th v-for="user in otherUsers" :key="user.id" class="text-center">
            <be-form-checkbox
              :checked="checked.includes(user.id)"
              @input="
                ($event) => {
                  if ($event) {
                    checked.push(user.id);
                  } else {
                    checked = checked.filter((id) => id !== user.id);
                  }
                }
              "
            />
          </th>

          <th />

          <th class="col-shrink">
            <be-button
              size="sm"
              variant="primary"
              :disabled="checked.length == 0"
              icon="fa-envelope"
              inline
              @click="onSendSuggestions"
            >
              {{
                $t("components.meetings.availability_modal.send_suggestions")
              }}
            </be-button>
          </th>
        </tr>
      </tfoot>
    </be-table-simple>
  </be-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

const AVAILABILITY_ENUM = {
  NOT_RESPONDED: "not_responded",
  AVAILABLE: "available",
  INCONVENIENT: "inconvenient",
  UNAVAILABLE: "unavailable",
};

const availabilityClasses = {
  [AVAILABILITY_ENUM.NOT_RESPONDED]: "bg-light",
  [AVAILABILITY_ENUM.AVAILABLE]: "bg-success",
  [AVAILABILITY_ENUM.INCONVENIENT]: "bg-warning",
  [AVAILABILITY_ENUM.UNAVAILABLE]: "bg-danger",
};

const availabilityButtonVariants = {
  [AVAILABILITY_ENUM.NOT_RESPONDED]: "outline-secondary",
  [AVAILABILITY_ENUM.AVAILABLE]: "outline-success",
  [AVAILABILITY_ENUM.INCONVENIENT]: "outline-warning",
  [AVAILABILITY_ENUM.UNAVAILABLE]: "outline-danger",
};

// TODO: this should have a confirm before popup:
// Check if unsaved changes and alert that it must be saved before changing availability

export default {
  props: {
    id: {
      type: String,
      required: true,
    },

    meeting: {
      type: Object,
      required: true,
    },
  },

  emits: ["update:meeting", "select-suggestion", "mark-availability"],

  data: () => ({
    checked: [],
  }),

  computed: {
    ...mapGetters({
      currentUser: "current_user/getUser",
      users: "company/users",
    }),

    suggestions() {
      return this.meeting?.suggestions || [];
    },

    allSelected() {
      return this.checked.length === this.users.length - 1;
    },

    checkAllButtonText() {
      return this.allSelected
        ? this.$t("buttons.toggle_all_selection.deselect_all")
        : this.$t("buttons.toggle_all_selection.select_all");
    },

    savedSuggestions() {
      return this.suggestions.filter((suggestion) => suggestion.created_at);
    },

    otherUsers() {
      return this.users.filter((user) => user.id !== this.currentUser.id);
    },
  },

  mounted() {
    this.checked = this.otherUsers.map((user) => user.id);
  },

  methods: {
    ...mapActions("meetings", ["sendSuggestions"]),

    formatSuggestionTime(suggestion) {
      const startAtDate = new Date(suggestion.start_at);
      const endAtDate = new Date(suggestion.end_at);

      // replicate rails
      const dateTimesFormatted = {
        start_date: this.$d(startAtDate, "short"),
        start_time: this.$d(startAtDate, "time"),
        end_date: this.$d(endAtDate, "short"),
        end_time: this.$d(endAtDate, "time"),
      };

      if (dateTimesFormatted.start_date === dateTimesFormatted.end_date) {
        return `${dateTimesFormatted.start_date} ${dateTimesFormatted.start_time} - ${dateTimesFormatted.end_time}`;
      }
      return this.$t("date_range.different_dates", dateTimesFormatted);
    },

    getAvailabilityClass(suggestion, user) {
      const availability = this.getAvailability(suggestion, user);
      return `btn rounded-circle ${availabilityClasses[availability]}`;
    },

    getUserButtonText(suggestion) {
      const availability = this.getAvailability(suggestion, this.currentUser);
      return this.$t(`models.suggestion.availabilities.${availability}`);
    },

    getUserButtonVariant(suggestion) {
      const availability = this.getAvailability(suggestion, this.currentUser);
      return availabilityButtonVariants[availability];
    },

    async onAvailabilityClick(suggestion) {
      let availability = this.getAvailability(suggestion, this.currentUser);

      if (
        availability === AVAILABILITY_ENUM.NOT_RESPONDED ||
        availability === AVAILABILITY_ENUM.UNAVAILABLE
      ) {
        availability = "available";
      } else if (availability === AVAILABILITY_ENUM.AVAILABLE) {
        availability = "inconvenient";
      } else if (availability === AVAILABILITY_ENUM.INCONVENIENT) {
        availability = AVAILABILITY_ENUM.UNAVAILABLE;
      }

      const response = await axios.put(
        this.url(`/suggestions/${suggestion.id}/choices`),
        {
          availability: availability,
        }
      );

      const updatedSuggestionChoice = response.data;

      const newSuggestions = this.cloneDeep(this.suggestions);
      const suggestionIdx = this.suggestions.findIndex(
        (suggestionEl) => suggestionEl.id == suggestion.id
      );
      const newSuggestion = this.cloneDeep(suggestion);

      if (newSuggestion.suggestion_choices) {
        const idx = newSuggestion.suggestion_choices.findIndex(
          (choice) => choice.user_id == this.currentUser.id
        );
        newSuggestion.suggestion_choices.splice(
          idx,
          1,
          updatedSuggestionChoice
        );
      } else {
        newSuggestion.suggestion_choices = [updatedSuggestionChoice];
      }
      newSuggestions.splice(suggestionIdx, 1, newSuggestion);

      const localMeeting = this.cloneDeep(this.meeting);
      localMeeting.suggestions = newSuggestions;
      this.$emit("update:meeting", localMeeting);
    },

    async onSendSuggestions() {
      // TODO: show notice
      await this.sendSuggestions({
        userIdList: this.checked,
        meeting: this.meeting,
      });
      this.checked = [];
    },

    onCheckAll() {
      if (this.allSelected) {
        this.checked = [];
      } else {
        this.checked = this.otherUsers.map((user) => user.id);
      }
    },

    async onPickSuggestionDate(suggestion) {
      // check if suggestion is ok
      if (suggestion.suggestion_choices) {
        const unavailable = suggestion.suggestion_choices.filter(
          (suggestionChoice) =>
            suggestionChoice.availability == AVAILABILITY_ENUM.UNAVAILABLE
        );

        if (unavailable.length > 0) {
          const userIds = unavailable.map((choice) => choice.user_id);
          const userNames = this.otherUsers
            .filter((user) => userIds.includes(user.id))
            .map((user) => user.name);

          if (userNames.length == 0) {
            // the currentUser have been reporting unavailable.
            return this.pickDate(suggestion);
          }

          // show confirm dialog
          const isConfirmed = await this.promptConfirm(
            this.$t(
              "components.meetings.suggestions.confirm_pick_unavailable",
              {
                user_names: userNames.join(", "),
              }
            )
          );

          if (isConfirmed) {
            this.pickDate(suggestion);
          }
        } else {
          this.pickDate(suggestion);
        }
      } else {
        this.pickDate(suggestion);
      }
    },

    pickDate(suggestion) {
      this.$emit("select-suggestion", suggestion);
    },

    getAvailability(suggestion, user) {
      if (suggestion.suggestion_choices) {
        const suggestionChoice = suggestion.suggestion_choices.find(
          (suggestionChoice) => suggestionChoice.user_id === user.id
        );
        return suggestionChoice && suggestionChoice.availability
          ? suggestionChoice.availability
          : AVAILABILITY_ENUM.NOT_RESPONDED;
      } else {
        return AVAILABILITY_ENUM.NOT_RESPONDED;
      }
    },
  },
};
</script>
