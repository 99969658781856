<template>
  <div>
    <payment-integration-template
      integration="pe_accounting_payments"
      :enabled-payment-integrations="enabledPaymentIntegrations"
      :verification-started="verificationStarted"
      job-key="save_pe_accounting_access_token"
      @activate="activate"
      @deactivate="deactivate"
    >
      <template #activate>
        <div class="align-self-center">
          <be-button variant="primary" @click="openModal">
            {{ $t("buttons.titles.activate") }}
          </be-button>
        </div>
      </template>

      <template #deactivate>
        <div class="align-self-center">
          <be-button variant="danger" @click="deactivate">
            {{ $t("buttons.titles.deactivate") }}
          </be-button>
        </div>
      </template>

      <template #notifications>
        <be-alert variant="info">
          {{ $t("models.available_integration.notifications.pe_accounting") }}
        </be-alert>
      </template>

      <template #modal>
        <be-form-group
          :label="
            $t(
              'models.available_integration.modals.pe_accounting.company_id.label'
            )
          "
          label-for="pe_company_id"
          :error="getErrors(form, 'pe_company_id')"
        >
          <be-form-input id="pe_company_id" v-model="form.pe_company_id" />
        </be-form-group>

        <be-form-group
          :label="
            $t(
              'models.available_integration.modals.pe_accounting.api_key.label'
            )
          "
          label-for="pe_api_key"
          :error="getErrors(form, 'pe_api_key')"
        >
          <be-form-input id="pe_api_key" v-model="form.pe_api_key" />
        </be-form-group>
      </template>
    </payment-integration-template>
  </div>
</template>

<script>
import PaymentIntegrationTemplate from "../components/PaymentIntegrationTemplate.vue";
import { url } from "@/url-helpers";

export default {
  components: {
    PaymentIntegrationTemplate,
  },

  props: {
    enabledPaymentIntegrations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      verificationStarted: false,

      form: {
        pe_company_id: "",
        pe_api_key: "",
      },
    };
  },

  methods: {
    async activate() {
      this.loading = true;
      this.verificationStarted = true;

      try {
        await axios.post(
          url("/payment_integrations/pe_accounting_payments"),
          this.form
        );
      } catch {
        // Do nothing
      }
      this.loading = false;
      this.closeModal();
    },

    async deactivate() {
      this.loading = true;

      try {
        await axios.delete(url("/payment_integrations/pe_accounting_payments"));
      } catch {
        // Do nothing
      }
      this.loading = false;

      // Deactivate is not handled by job callback, lets do a reload
      window.location.reload();
    },

    openModal() {
      this.$beModal.show("pe_accounting_payments-modal");
    },

    closeModal() {
      this.$beModal.hide("pe_accounting_payments-modal");
    },
  },
};
</script>
