import axios from "axios";
import { handleError } from "@/utils/error-handling";

export const state = {
  invitations: [],
};

export const getters = {
  invitationsForReference: (state) => (referenceType, referenceId) => {
    return state.invitations.filter(
      (invitation) =>
        invitation.reference_type === referenceType &&
        invitation.reference_id === referenceId
    );
  },
};

export const actions = {
  async fetchInvitations({ commit }, resource) {
    if (!resource?.paths?.invitations) {
      throw new Error("Resource does not have paths.invitations");
    }

    try {
      const { data } = await axios.get(resource.paths.invitations);

      if (data instanceof Array) {
        data.forEach((invitation) => {
          commit("updateInvitation", invitation);
        });
      }
    } catch (error) {
      handleError(error);
    }
  },

  async createInvitation({ commit }, { resource, invitation }) {
    if (!resource?.paths?.invitations) {
      throw new Error("Resource does not have paths.invitations");
    }

    try {
      const { data } = await axios.post(resource.paths.invitations, {
        invitation: {
          message: invitation.message,
          user_id: invitation.user_id,
        },
      });

      commit("updateInvitation", data);
      return data;
    } catch (error) {
      handleError(error);
    }
  },

  async deleteInvitation({ commit }, { resource, invitation }) {
    if (!resource?.paths?.invitations) {
      throw new Error("Resource does not have paths.invitations");
    }

    try {
      await axios.delete(`${resource.paths.invitations}/${invitation.id}`);
      commit("removeInvitation", invitation);
    } catch (error) {
      handleError(error);
    }
  },
};

export const mutations = {
  updateInvitation(state, invitation) {
    const index = state.invitations.findIndex(
      (existing) =>
        (existing.id && existing.id === invitation.id) ||
        (!existing.id &&
          existing.reference_type === invitation.reference_type &&
          existing.reference_id === invitation.reference_id &&
          existing.user_id === invitation.user_id)
    );

    if (index === -1) {
      state.invitations.push(invitation);
    } else {
      state.invitations[index] = invitation;
    }
  },

  removeInvitation(state, invitation) {
    state.invitations = state.invitations.filter(
      (existing) => existing.id !== invitation.id
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
