<template>
  <tr>
    <td class="col-shrink text-center">
      <user-avatar
        :key="`attendance-avatar-${localAttendance.id}`"
        :user="
          localAttendance.user_id ||
          localAttendance.user_name ||
          localAttendance.name
        "
      />
    </td>

    <td>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-block">
          <div>
            {{ localAttendance.name }}
          </div>

          <small>
            {{ attendanceSubtitle }}
          </small>
        </div>

        <be-link
          v-if="localAttendance.notes"
          v-be-tooltip="$t('activerecord.attributes.attendance.notes')"
          @click="editAttendanceNotes()"
        >
          <i class="fal fa-user-edit fa-fw text-muted" />
        </be-link>
      </div>
    </td>

    <td>
      <be-form-group
        :label="$t('activerecord.attributes.attendance.attending')"
        :label-for="inputId('attending')"
        label-sr-only
        required
        class="m-0"
      >
        <be-form-select
          :id="inputId('attending')"
          v-model="localAttendance.attending"
          :options="attendingOptions"
          :disabled="loadingAttendance"
          @change="update"
        />
      </be-form-group>
    </td>

    <td class="col-shrink">
      <be-form-group
        v-be-tooltip="{
          title: $t(
            'components.meetings.material.attendances.cannot_be_absent'
          ),
          disabled: !disableAbsence,
        }"
        :label="$t('activerecord.attributes.attendance.reported_absence')"
        :label-for="inputId('reported_absence')"
        label-sr-only
        class="m-0"
      >
        <be-form-checkbox
          v-model="localAttendance.reported_absence"
          :disabled="loadingAttendance || !localAttendance.id || disableAbsence"
          size="lg"
          @change="update"
        />
      </be-form-group>
    </td>

    <td>
      <be-form-group
        :label="$t('activerecord.attributes.attendance.function')"
        :label-for="inputId('function')"
        label-sr-only
        required
        class="m-0"
      >
        <be-form-select
          :id="inputId('function')"
          v-model="localAttendance.function"
          :options="attendanceFunctions"
          :disabled="anythingLoading"
          @change="updateFunction()"
        />
      </be-form-group>
    </td>

    <td class="col-shrink">
      <be-spinner v-if="loadingSecretary" />

      <be-form-group
        v-else
        :key="`${formState}-${localAttendance.id}-secretary`"
        v-be-tooltip="{
          title: secretaryTooltip,
          disabled: !disableSecretary,
        }"
        :label="$t('activerecord.attributes.attendance.secretary')"
        :label-for="inputId('secretary')"
        label-sr-only
        class="m-0"
      >
        <be-form-radio
          :id="inputId('secretary')"
          v-model="localAttendance.secretary"
          name="secretary"
          :disabled="anythingLoading || !localAttendance.id || disableSecretary"
          size="lg"
          @change="updateSecretary"
        />
      </be-form-group>
    </td>

    <td class="col-shrink">
      <be-form-group
        v-be-tooltip="{
          title: reviewerTooltip,
          disabled: !disableReviewer,
        }"
        :label="$t('activerecord.attributes.attendance.reviewer')"
        :label-for="inputId('reviewer')"
        label-sr-only
        class="m-0"
      >
        <be-form-checkbox
          :id="inputId(localAttendance, 'reviewer')"
          v-model="localAttendance.reviewer"
          size="lg"
          :disabled="
            loadingAttendance || !localAttendance.id || disableReviewer
          "
          @change="updateReviewer()"
        />
      </be-form-group>
    </td>

    <td class="col-shrink">
      <be-form-group
        v-be-tooltip="{
          title: disableSignatoryTitle,
          disabled: !disableSignatory,
        }"
        :label="$t('activerecord.attributes.attendance.signatory')"
        :label-for="inputId('signatory')"
        label-sr-only
        class="m-0"
      >
        <be-form-checkbox
          :id="inputId('signatory')"
          v-model="localAttendance.signatory"
          size="lg"
          :disabled="
            loadingAttendance || !localAttendance.id || disableSignatory
          "
          @change="update"
        />
      </be-form-group>
    </td>

    <td class="col-shrink text-center">
      <be-spinner v-if="localAttendance.id && loadingAttendance" />

      <be-dropdown v-else size="sm" ellipsis>
        <be-dropdown-item
          v-if="!localAttendance.user_id"
          @click="$emit('edit-attendance', localAttendance)"
        >
          {{ $t("buttons.titles.edit") }}
        </be-dropdown-item>

        <be-dropdown-item @click="editAttendanceNotes()">
          {{ $t("activerecord.attributes.attendance.notes") }}
        </be-dropdown-item>

        <be-dropdown-divider />

        <be-dropdown-item
          variant="danger"
          @click="
            removeAttendance({
              attendance: localAttendance,
              meeting: meeting,
            })
          "
        >
          {{ $t("buttons.titles.remove") }}
        </be-dropdown-item>
      </be-dropdown>
    </td>
  </tr>

  <be-modal
    :id="inputId(null, 'notes')"
    :title="localAttendance.name"
    :ok-title="$t('buttons.titles.save')"
    @ok="submitAttendanceNotes"
    @cancel="cancelAttendanceNotes"
  >
    <be-form-textarea v-model="attendanceNotes" rows="3" max-rows="10" />
  </be-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    attendance: {
      type: Object,
      required: true,
    },
  },

  emits: ["edit-attendance"],

  data() {
    return {
      localAttendance: this.cloneDeep(this.attendance),
      formState: this.generateUuid(),
      edit: false,
    };
  },

  computed: {
    ...mapGetters({
      meeting: "material/meeting",
      loading: "attendances/loading",
      anythingLoading: "attendances/anythingLoading",
    }),

    loadingChairman() {
      return this.loading.some((loading) => loading.lock === "chairman");
    },

    loadingSecretary() {
      return this.loading.some((loading) => loading.lock === "secretary");
    },

    loadingAttendance() {
      return this.loading.some(
        (loading) => loading.identifier === this.attendance.id
      );
    },

    attendingOptions() {
      return [
        {
          value: "attending_irl",
          text: this.$t("models.attendance.attending_options.attending_irl"),
        },
        {
          value: "attending_online",
          text: this.$t("models.attendance.attending_options.attending_online"),
        },
        {
          value: "absent",
          text: this.$t("models.attendance.attending_options.absent"),
        },
      ];
    },

    attendanceFunctions() {
      if (this.meeting.meeting_series_type === "annual") {
        return [
          {
            value: "chairman",
            text: this.$i18n.t("models.attendance.functions.chairman"),
          },
          {
            value: "external",
            text: this.$i18n.t("models.attendance.function_other"),
          },
        ];
      }

      return this.$config.ATTENDANCE_FUNCTIONS.map((attendanceFunction) => {
        return {
          value: attendanceFunction,

          text: this.$i18n.t(
            `models.attendance.functions.${attendanceFunction}`
          ),
        };
      });
    },

    disableAbsence() {
      // Always allowed to uncheck reported absence
      if (this.localAttendance.reported_absence) {
        return false;
      }

      return this.localAttendance.secretary || this.localAttendance.reviewer;
    },

    disableSecretary() {
      return !this.localAttendance.user_id || this.localAttendance.reviewer;
    },

    disableSignatory() {
      if (!this.localAttendance.user_id && !this.localAttendance.email)
        return true;

      // Do not disable the checkbox until the attendance is signatory
      // This allows someone already marked as secretary or reviewer to be
      // marked as signatory but then they cannot unmark it.
      return (
        this.localAttendance.signatory &&
        (this.localAttendance.secretary || this.localAttendance.reviewer)
      );
    },

    disableSignatoryTitle() {
      if (this.localAttendance.user_id) {
        if (this.localAttendance.secretary) {
          return this.$t(
            "components.meetings.material.attendances.cannot_change_signatory_for_secretary"
          );
        } else if (this.localAttendance.reviewer) {
          return this.$t(
            "components.meetings.material.attendances.cannot_change_for_reviewer"
          );
        }
      } else {
        return this.$t(
          "components.meetings.material.attendances.cannot_be_done_without_email"
        );
      }
      return null;
    },

    disableReviewer() {
      return !this.localAttendance.user_id || this.localAttendance.secretary;
    },

    secretaryTooltip() {
      if (!this.localAttendance.user_id) {
        return this.$t(
          "components.meetings.material.attendances.cannot_be_done_without_user"
        );
      } else if (this.localAttendance.reviewer) {
        return this.$t(
          "components.meetings.material.attendances.cannot_change_secretary_for_reviewer"
        );
      }
      return null;
    },

    reviewerTooltip() {
      if (!this.localAttendance.user_id) {
        return this.$t(
          "components.meetings.material.attendances.cannot_be_done_without_user"
        );
      } else if (this.localAttendance.secretary) {
        return this.$t(
          "components.meetings.material.attendances.cannot_change_for_secretary"
        );
      }
      return null;
    },

    removeAttendanceIfEmptyName() {
      if (this.localAttendance.name.length == 0) {
        this.removeAttendance({
          attendance: this.localAttendance,
          meeting: this.meeting,
        });
        return true;
      }
      return false;
    },

    attendanceSubtitle() {
      const parts = [];
      if (this.localAttendance.user_id) {
        parts.push(this.getUserEmail(this.localAttendance.user_id));
      } else {
        parts.push(this.$t("models.attendance.external"));
        parts.push(this.localAttendance.email);
      }

      return parts.filter((part) => part).join(" • ");
    },
  },

  watch: {
    attendance: {
      handler(attendance) {
        this.localAttendance = this.cloneDeep(attendance);
      },

      deep: true,
    },
  },

  methods: {
    ...mapMutations("attendances", ["setLoading"]),

    ...mapActions("attendances", [
      "updateAttendance",
      "removeAttendance",
      "setNotLoading",
    ]),

    update(lock) {
      this.setLoading({ identifier: this.localAttendance.id, lock: lock });
      this.updateAttendance({
        attendance: this.localAttendance,
        meeting: this.meeting,
      });
    },

    // Handle disabling all function fields if chairman is selected
    updateFunction() {
      let lock = null;

      if (this.localAttendance.function === "chairman") {
        lock = "chairman";
        this.localAttendance.signatory = true;
      }

      this.update(lock);
    },

    updateReviewer() {
      if (this.localAttendance.reviewer) {
        this.localAttendance.reported_absence = false;
        this.localAttendance.signatory = true;
      }

      this.update();
    },

    async updateSecretary() {
      if (
        this.localAttendance.secretary &&
        this.$currentMembership.policy_level !== "admin"
      ) {
        const isConfirmed = await this.promptConfirm(
          this.$t(
            "components.meetings.material.attendances.confirm_secretary_change_for_limited"
          )
        );

        if (!isConfirmed) {
          this.localAttendance.secretary = false;
          this.formState = this.generateUuid(); //Trigger radio buttons reset
          return;
        }
      }

      this.localAttendance.reported_absence = false;
      this.localAttendance.signatory = true;
      this.update("secretary");
    },

    async submitAttendanceNotes() {
      this.localAttendance.notes = this.attendanceNotes;
      await this.updateAttendance({
        attendance: this.localAttendance,
        meeting: this.meeting,
      });

      this.cancelAttendanceNotes();
    },

    cancelAttendanceNotes() {
      this.attendanceNotes = null;
      this.edit = false;
      this.$beModal.hide(this.inputId(null, "notes"));
    },

    editAttendanceNotes() {
      this.edit = true;
      this.attendanceNotes = this.localAttendance.notes;
      this.$beModal.show(this.inputId(null, "notes"));
    },

    inputId(field) {
      let identifier = "local";

      if (this.localAttendance) {
        if (this.localAttendance.id) {
          identifier = this.localAttendance.id;
        } else if (this.localAttendance.user_id) {
          identifier = `user-${this.localAttendance.user_id}`;
        }
      }

      return `attendance-${identifier}-${field}`;
    },
  },
};
</script>
