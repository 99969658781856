<template>
  <be-form-group
    :label="$t('views.companies.inquiries.create.transaction_type')"
    label-for="payment-method"
    required
  >
    <be-form-select
      id="payment-method"
      v-model="form.payment.inquiry_receiver.transaction_type"
      :options="transaction_types"
      required
    />
  </be-form-group>
</template>

<script>
import CreateInquiryMixin from "@/mixins/inquiries/createInquiry";

export default {
  mixins: [CreateInquiryMixin],

  data() {
    return {
      transaction_types: [
        {
          value: "unknown",

          text: this.$t(
            "views.companies.inquiries.create.choose_transaction_type"
          ),
        },
        {
          value: "bankgiro",

          text: this.$t("activerecord.attributes.inquiry.bankgiro"),
        },
        {
          value: "plusgiro",
          text: this.$t("activerecord.attributes.inquiry.plusgiro"),
        },
        {
          value: "international_transfer",

          text: this.$t(
            "activerecord.attributes.inquiry.international_transfer"
          ),
        },
        {
          value: "bank_transfer",

          text: this.$t("activerecord.attributes.inquiry.bank_transfer"),
        },
      ],
    };
  },
};
</script>
