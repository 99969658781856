<template>
  <div class="mt-3">
    <template v-if="showUploader">
      <h4>
        {{ $t("components.annual_reports.upload_tab.upload_board_statement") }}
      </h4>

      <annual-report-documents document-key="board_statement" />
    </template>

    <table
      v-if="showDocumentTable"
      :key="`preparation-document-${boardStatementDocument.id}`"
      class="table table-hover"
    >
      <thead>
        <tr>
          <th colspan="3">
            {{ $t("models.annual_report.documents.board_statement") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td colspan="2">
            <document-link
              :document-id="boardStatementDocument.id"
              :filename="filenameWithSize(boardStatementDocument)"
            />
          </td>

          <td class="text-right">
            <template v-if="boardStatementSignatures.length">
              <be-button
                v-be-modal="`board-statement-signatures-${annualReport.id}`"
                variant="outline-secondary"
                size="sm"
                inline
              >
                {{ $t("models.document.e_signing") }}
                {{ signatureProgressText(boardStatementSignatures) }}
              </be-button>

              <be-modal
                :id="`board-statement-signatures-${annualReport.id}`"
                :title="$t('models.document.e_signing')"
                ok-only
                :ok-title="$t('buttons.titles.close')"
              >
                <signatures-table
                  :document="boardStatementDocument"
                  :signatures="boardStatementSignatures"
                />
              </be-modal>
            </template>

            <be-button
              v-if="canRemoveDocument(boardStatementDocument)"
              v-be-tooltip="$t('buttons.titles.remove')"
              variant="danger"
              size="sm"
              icon="fa-times"
              @click="confirmRemoveDocument(boardStatementDocument)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <be-alert v-else class="mt-2" variant="info">
      {{
        $t("components.annual_reports.upload_tab.board_statement_not_uploaded")
      }}
    </be-alert>

    <template v-if="showRequestSignatures">
      <div class="d-flex justify-content-end">
        <be-button
          v-if="can('manually_sign', 'board_statement', 'update')"
          variant="outline-primary"
          @click="confirmTriggerEvent('manually_sign', 'board_statement')"
        >
          {{ $t("components.annual_reports.manually_signed") }}
        </be-button>

        <be-button
          :variant="
            requestSignaturesIsBestOption ? 'primary' : 'outline-primary'
          "
          :disabled="disableSendForSignatures"
          @click="signatureDocument = boardStatementDocument"
        >
          {{ $t("documents.document_options.e_sign") }}
        </be-button>
      </div>

      <annual-report-signature-form
        v-if="signatureDocument"
        :available-memberships="$currentCompany.memberships"
        :annual-report="annualReport"
        doc-type="board_statement"
        :doc="signatureDocument"
        @closed="afterSignaturesRequested"
        @reset="signatureDocument = {}"
      />
    </template>
  </div>
</template>

<script>
import AnnualReportTabBase from "./mixins/AnnualReportTabBase.vue";
import SignaturesTable from "./SignaturesTable.vue";

export default {
  components: {
    SignaturesTable,
  },

  mixins: [AnnualReportTabBase],

  data() {
    return {
      saving: false,
      signatureDocument: {}, // This is used to show SignatureForm
    };
  },

  computed: {
    boardStatementState() {
      return this.annualReport.board_statement_state;
    },

    disableSendForSignatures() {
      return !this.can("request_signatures", "board_statement");
    },

    showDocumentTable() {
      return (
        this.boardStatementDocument && this.boardStatementState !== "initial"
      );
    },

    showUploader() {
      return !this.boardStatementDocument;
    },

    showRequestSignatures() {
      return (
        this.can("request_signatures", "board_statement", "update") &&
        this.boardStatementState != "signed_by_organization"
      );
    },

    goodTimeToSignThis() {
      return [
        "signatures_requested",
        "signed_by_organization",
        "completely_signed",
      ].includes(this.annualReport.annual_report_state);
    },

    requestSignaturesIsBestOption() {
      return this.showRequestSignatures && this.goodTimeToSignThis;
    },

    signatories() {
      return this.companyUsers || [];
    },

    signatureUrl() {
      return this.annualReport.paths.signature_requests;
    },

    selectedUserIds() {
      return this.annualReport.board_statement_signatory_ids || [];
    },
  },

  watch: {
    "annualReport.board_statement_document": function (value) {
      if (!value) {
        this.signatureDocument = {};
      }
    },
  },
};
</script>
