import Mention from "@tiptap/extension-mention";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import hashtagSuggestion from "@/utils/material_hashtag_suggestion.js";

import HashtagDisplay from "./HashtagDisplay.vue";

export const HashtagMention = Mention.configure({
  HTMLAttributes: { class: "mention" },
  suggestion: hashtagSuggestion,
}).extend({
  addNodeView() {
    return VueNodeViewRenderer(HashtagDisplay);
  },
});
