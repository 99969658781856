<template>
  <div class="card bg-light border-bottom-1" :class="{ 'mt-2': index !== 0 }">
    <div
      class="card-header bg-light d-flex justify-content-between align-items-center"
    >
      <div>
        <template v-if="isAgenda">
          {{ $t("models.item.task_suggestion") }}
        </template>

        <template v-else>
          {{ $t("activerecord.models.task.one") }}
        </template>
      </div>

      <div>
        <be-button
          v-be-tooltip="$t('buttons.titles.remove')"
          variant="danger"
          size="sm"
          icon="fa-times"
          @click="handleRemove"
        />
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <be-form-group
            :label-for="inputId('description')"
            :label="translateAttribute('task', 'description')"
            :error="getErrors(localTask, 'description')"
            required
          >
            <be-form-textarea
              :id="inputId('description')"
              v-model="localTask.description"
              :rows="9"
              :max-rows="20"
              :autofocus="localTask.fresh"
              :state="validationState(localTask, ['description'])"
              @input="updateAttribute('description', true)"
            />
          </be-form-group>

          <be-form-checkbox
            v-if="!hideIncludeInMaterial"
            :id="inputId('include_in_material')"
            v-model="localTask.include_in_material"
            :state="validationState(localTask, 'include_in_material')"
            @change="updateAttribute('include_in_material')"
          >
            {{
              isAgenda
                ? translateAttribute("task", "include_in_agenda")
                : translateAttribute("task", "include_in_minutes")
            }}
          </be-form-checkbox>
        </div>

        <div class="col-12 col-md-6">
          <be-form-group
            :label-for="inputId('user_id')"
            :label="$t('components.meetings.material.tasks.assign_task')"
            :error="getErrors(localTask, 'user_id')"
          >
            <be-form-select
              :id="inputId('user_id')"
              v-model="localTask.user_id"
              :state="validationState(localTask, ['user_id'])"
              :options="userOptions"
              :include-blank-option="
                $t('components.meetings.material.decisions.unassigned')
              "
              @change="updateAttribute('user_id')"
            />
          </be-form-group>

          <be-form-group
            :label-for="inputId('due_at')"
            :label="translateAttribute('task', 'due_at')"
            :error="getErrors(localTask, 'due_at')"
          >
            <datepicker-future-buttons
              :id="inputId('due_at')"
              v-model="localTask.due_at"
              :state="validationState(localTask, ['due_at'])"
              display-inline
              @input="updateAttribute('due_at', true)"
            />
          </be-form-group>

          <be-form-group
            v-if="platformEnabledAndSubscribed('projects')"
            :label-for="inputId('project_id')"
            :label="translateAttribute('task', 'project_id')"
            :error="getErrors(localTask, 'project_id')"
          >
            <be-form-select
              :id="inputId('project_id')"
              v-model="localTask.project_id"
              :state="validationState(localTask, ['project_id'])"
              :options="projectOptions"
              :include-blank-option="$t('beta_shared.please_select_an_option')"
              @change="updateAttribute('project_id')"
            />
          </be-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
import debounce from "lodash/debounce";

import materialType from "./materialState";
import TextUtilities from "@/mixins/textUtilities";
import { EventBus } from "@/event-bus";

const materialHelpers = createNamespacedHelpers("material");
const AUTOSAVE_INTERVAL = 1000;

export default {
  mixins: [materialType, TextUtilities],

  props: {
    task: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },

    hideIncludeInMaterial: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localTask: this.cloneDeep(this.task),
      updateDebounce: debounce(this.update, AUTOSAVE_INTERVAL),
    };
  },

  computed: {
    ...mapGetters({
      users: "company/users",
      projects: "company/projects",
      hasActiveFeature: "company/hasActiveFeature",
    }),

    ...materialHelpers.mapGetters({
      meeting: "meeting",
      hasErrors: "hasErrors",
    }),

    projectOptions() {
      return this.projects.map((project) => ({
        text: project.display_title,
        value: project.id,
      }));
    },

    hasProjects() {
      return this.hasActiveFeature({ name: "projects" });
    },

    userOptions() {
      return this.users
        .map((user) => {
          return { value: user.id, text: user.name };
        })
        .sort((a, b) => this.compareText(a, b, "text"));
    },

    invalid() {
      return this.hasErrors(this.localTask);
    },

    descriptionErrors() {
      const value = this.getErrors(this.localTask, "description");
      if (Array.isArray(value)) {
        return value;
      } else if (value) {
        return [value];
      } else {
        return [];
      }
    },
  },

  watch: {
    task: {
      handler(newTask) {
        this.localTask = this.cloneDeep(newTask);
      },

      deep: true,
    },
  },

  methods: {
    ...materialHelpers.mapActions(["updateTask", "removeTask"]),
    ...materialHelpers.mapMutations(["setDirty"]),

    inputId(field) {
      return `task-${this.task.id}-${field}`;
    },

    async handleRemove() {
      let title = "";

      const itemType = this.isAgenda
        ? this.$t("models.item.task_suggestion").toLowerCase()
        : this.$t("activerecord.models.task.one").toLowerCase();

      if (this.task.description) {
        title = this.$t("nav.confirm_delete_w_item_type_w_title", {
          item_type: itemType,
          title: this.truncateText(this.task.description, 64),
        });
      } else {
        if (this.isAgenda) {
          title = this.$t(
            "models.material.minutes.confirm_remove_task_suggestion"
          );
        } else {
          title = this.$i18n.t("nav.confirm_delete_w_item_type", {
            item_type: itemType,
          });
        }
      }

      const isConfirmed = await this.promptRemovalConfirm(title);

      if (isConfirmed) {
        this.removeTask({
          task: this.localTask,
          item: this.item,
        });
      }
    },

    setDate(date) {
      this.localTask.due_at =
        date.length > 0 ? new Date(date).toISOString() : "";
    },

    // Called after debounce
    async update() {
      const result = this.updateTask({
        task: this.localTask,
        item: this.item,
      });

      if (!result) {
        EventBus.emit("new-notification", {
          type: "danger",
          message: this.$t("models.task.update.failure"),
        });
      }
    },

    // Called when a field changes
    updateAttribute(attribute, debounce = false) {
      this.clearErrors(this.localTask, attribute);
      this.setDirty(`task-${this.localTask.id}`);
      if (debounce) {
        this.updateDebounce();
      } else {
        this.update();
      }
    },
  },
};
</script>
