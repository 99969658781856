<template>
  <be-modal
    id="contract-request"
    :title="$t('components.contracts.contract_request.title')"
    no-teleport
  >
    <be-form-group
      label-for="request-receiver"
      :label="$t('components.contracts.contract_request.send_to')"
      required
    >
      <be-form-select
        id="request-receiver"
        v-model="userId"
        name="user"
        :options="usersExceptCurrent"
      />
    </be-form-group>

    <be-form-group
      label-for="request-message"
      :label="$t('components.contracts.contract_request.message')"
      :description="
        $t('components.contracts.contract_request.message_description')
      "
      required
    >
      <be-form-textarea
        id="request-message"
        v-model="message"
        class="form-control"
        rows="3"
        max-rows="20"
        name="message"
      />
    </be-form-group>

    <template #footer="{ cancel }">
      <be-button variant="light" @click="cancel">
        {{ $t("buttons.titles.cancel") }}
      </be-button>

      <be-button :loading="loading" variant="primary" @click="submitForm">
        {{ $t("buttons.titles.send") }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      message: null,
      userId: null,
    };
  },

  computed: {
    ...mapGetters({
      companyUsers: "company/users",
    }),

    usersExceptCurrent() {
      let unformattedUsers = this.companyUsers.filter(
        (user) => user.id != this.$currentUser.id
      );

      return unformattedUsers
        .map((user) => ({ text: user.name, value: user.id }))
        .sort((a, b) => (b.text < a.text ? 1 : -1));
    },
  },

  mounted() {
    if (this.usersExceptCurrent) {
      this.userId = this.usersExceptCurrent[0].value;
    }
  },

  methods: {
    async submitForm(event) {
      event.preventDefault();
      this.loading = true;

      try {
        const response = await axios.post(
          this.url("/contracts/send_contract_request"),
          {
            user: this.userId,
            message: this.message,
          }
        );

        if (response.status == 200) {
          this.$beModal.hide("contract-request");
          this.message = null;
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
