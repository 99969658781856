<template>
  <Teleport v-if="isTeleportActive" to="body">
    <div class="be-drawer-outer" @mousedown="onClickOutside">
      <transition name="be-drawer-backdrop">
        <div
          v-if="isBackdropVisible"
          class="be-drawer-backdrop"
          @click="closeDrawer"
        />
      </transition>

      <transition :name="transition">
        <div
          v-if="isDrawerVisible"
          ref="drawer"
          :class="[
            'be-drawer',
            fromDirection,
            { bordered, 'sticky-footer': stickyFooter },
          ]"
          :style="{
            maxWidth: fromDirection === 'bottom' ? '100%' : maxWidth,
            minWidth: fromDirection === 'bottom' ? '100%' : minWidth,
          }"
          tabindex="-1"
          @keydown="onEscape"
        >
          <div :class="['be-drawer-header', headerClass]">
            <h2 class="be-drawer-title">
              <slot name="title">
                {{ title }}
              </slot>
            </h2>

            <div class="be-drawer-header-actions">
              <slot name="actions" />

              <be-button
                v-be-tooltip="'Close drawer'"
                class="be-drawer-close-button"
                icon="fa-times"
                @click="closeDrawer"
              />
            </div>
          </div>

          <div :class="['be-drawer-content', bodyClass]">
            <slot />
          </div>

          <div :class="['be-drawer-footer', footerClass]">
            <slot name="footer" />
          </div>
        </div>
      </transition>
    </div>
  </Teleport>
</template>

<script>
import { generateId } from "@/utils/id";
import { EventBus } from "@/event-bus";
import { KEY_CODE_ESCAPE } from "@/constants/key-codes";

export default {
  name: "BeDrawer",

  props: {
    bodyClass: {
      type: String,
      required: false,
      default: "",
    },

    bordered: {
      type: Boolean,
      required: false,
      default: false,
    },

    footerClass: {
      type: String,
      required: false,
      default: "",
    },

    fromDirection: {
      type: String,
      required: false,
      default: "right",
      validator: (value) => ["left", "right", "bottom"].includes(value),
    },

    headerClass: {
      type: String,
      required: false,
      default: "",
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("be-drawer"),
    },

    maxWidth: {
      type: String,
      required: false,
      default: undefined,
    },

    minWidth: {
      type: String,
      required: false,
      default: undefined,
    },

    open: {
      type: Boolean,
      required: false,
      default: false,
    },

    stickyFooter: {
      type: Boolean,
      required: false,
      default: false,
    },

    title: {
      type: String,
      required: false,
      default: "",
    },
  },

  emits: ["update:open", "open", "close"],

  data() {
    return {
      isBackdropVisible: false,
      isDrawerVisible: false,
      isTeleportActive: false,
    };
  },

  computed: {
    drawerOuterId() {
      return `${this.id}-outer`;
    },

    transition() {
      return `be-drawer-transition-${this.fromDirection}`;
    },
  },

  watch: {
    open: {
      immediate: true,

      handler(open) {
        if (open) {
          this.openDrawer();
        } else {
          this.closeDrawer();
        }
      },
    },

    isDrawerVisible(visible) {
      this.$emit("update:open", visible);
    },
  },

  mounted() {
    EventBus.on("be::drawer::toggle", this.toggleHandler);
  },

  methods: {
    openDrawer() {
      this.$emit("open");
      this.isTeleportActive = true;

      this.$nextTick(() => {
        this.isBackdropVisible = true;
        this.isDrawerVisible = true;
        document.body.classList.add("modal-open"); // This might interfere with other modals

        this.$nextTick(() => {
          this.$refs.drawer.focus();
        });
      });
    },

    closeDrawer() {
      this.$emit("close");
      this.isBackdropVisible = false;
      this.isDrawerVisible = false;

      setTimeout(() => {
        this.isTeleportActive = false;
        document.body.classList.remove("modal-open");

        if (this.$_returnFocus) {
          this.$_returnFocus.focus();
          this.$_returnFocus = null;
        }
      }, 400);
    },

    toggleHandler(id, triggerElement) {
      if (id === this.id) {
        if (this.isDrawerVisible) {
          this.closeDrawer();
        } else {
          if (triggerElement) {
            this.$_returnFocus = triggerElement;
          }

          this.openDrawer();
        }
      }
    },

    onClickOutside(event) {
      if (!this.isDrawerVisible || !document.body.contains(event.target)) {
        return;
      }

      // If click was outside of drawer, close it
      if (!this.$refs.drawer.contains(event.target)) {
        this.closeDrawer();
      }
    },

    onEscape(event) {
      if (this.isDrawerVisible && event.keyCode === KEY_CODE_ESCAPE) {
        this.closeDrawer();
      }
    },
  },
};
</script>
