<template>
  <transition
    v-bind="transitionProps"
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @leave="onLeave"
    @after-leave="onAfterLeave"
  >
    <component
      :is="tag"
      v-show="show"
      :id="id"
      ref="collapse"
      :class="computedClass"
    >
      <slot :scope="slotScope" />
    </component>
  </transition>
</template>

<script>
import { EventBus } from "@/event-bus";
import { generateId } from "@/utils/id";
import { BeEvent } from "@/helpers/be-event";

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;

export default {
  name: "BeCollapse",

  props: {
    accordionGroup: {
      type: String,
      required: false,
      default: undefined,
    },

    appear: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("be-collapse"),
    },

    isNav: {
      type: Boolean,
      required: false,
      default: false,
    },

    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },

    tag: {
      type: String,
      required: false,
      default: "div",
    },

    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["update:modelValue", "show", "shown", "hide", "hidden", "input"],

  data() {
    return {
      show: this.modelValue || this.visible,
      transitioning: false,
    };
  },

  computed: {
    computedClass() {
      return [
        "be-collapse",
        {
          "navbar-collapse": this.isNav,
        },
      ];
    },

    slotScope() {
      return {
        visible: this.show,

        close: () => {
          this.show = false;
        },
      };
    },

    transitionProps() {
      const { appear } = this;

      return {
        appear,
        css: true,
        enterClass: "",
        enterActiveClass: "collapsing",
        enterToClass: "collapse show",
        leaveClass: "collapse show",
        leaveActiveClass: "collapsing",
        leaveToClass: "collapse",
      };
    },
  },

  watch: {
    modelValue(newValue) {
      if (newValue !== this.show) {
        this.show = newValue;
      }
    },

    visible(newValue) {
      if (newValue !== this.show) {
        this.show = newValue;
      }
    },

    show(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.emitState();
      }
    },
  },

  mounted() {
    // Listen for toggle events on EventBus to open/close us
    EventBus.on("be::toggle::collapse", this.handleToggleEvent);

    // Listen to other collapses for accordion events
    EventBus.on("be::collapse::accordion", this.handleAccordionEvent);

    // Set up nav handlers
    if (this.isNav) {
      this.setWindowEvents(true);
      this.handleResize();
    }

    // Emit state on next tick
    this.$nextTick(() => {
      this.emitState();
    });

    // Listen for "Sync state" requests from `v-be-toggle` directive
    EventBus.on("be::toggle::request-state", (id) => {
      if (id === this.id) {
        this.$nextTick(this.emitSync);
      }
    });
  },

  updated() {
    // Emit a private event every time this components updated to ensure
    // the toggle button is in sync with the collapse's state.
    // It is emitted regardless of whether the state changed or not.
    this.emitSync();
  },

  deactivated() {
    // Remove window events
    if (this.isNav) {
      this.setWindowEvents(false);
    }
  },

  activated() {
    // Add window events
    if (this.isNav) {
      this.setWindowEvents(true);
    }

    // Emit sync
    this.emitSync();
  },

  beforeUnmount() {
    // Trigger state emit if needed
    this.show = false;

    // Remove window events
    if (this.isNav) {
      this.setWindowEvents(false);
    }
  },

  methods: {
    setWindowEvents(on) {
      if (on) {
        window.addEventListener("resize", this.handleResize);
        window.addEventListener("orientationchange", this.handleResize);
      } else {
        window.removeEventListener("resize", this.handleResize);
        window.removeEventListener("orientationchange", this.handleResize);
      }
    },

    toggle() {
      this.show = !this.show;
    },

    onEnter(el) {
      this.transitioning = true;
      this.emitEvent("show");
      el.style.height = "0";

      // We need to wait for the next animation frame for `appear` to work
      requestAnimationFrame(() => {
        /* eslint-disable @typescript-eslint/no-unused-expressions */
        // Requesting an elements height will trigger a reflow
        el.offsetHeight;
        /* eslint-enable @typescript-eslint/no-unused-expressions */

        // Set the height to the scroll height
        el.style.height = `${el.scrollHeight}px`;
      });
    },

    onAfterEnter(el) {
      this.transitioning = false;
      this.emitEvent("shown");

      // Remove the height style
      el.style.height = "";
    },

    onLeave(el) {
      this.transitioning = true;
      this.emitEvent("hide");

      // Resets the height to 0
      el.style.height = "auto";
      el.style.display = "block";
      el.style.height = `${el.getBoundingClientRect().height}px`;
      el.style.height = "0";

      /* eslint-disable @typescript-eslint/no-unused-expressions */
      // Requesting an elements height will trigger a reflow
      el.offsetHeight;
      /* eslint-enable @typescript-eslint/no-unused-expressions */
    },

    onAfterLeave(el) {
      this.transitioning = false;
      this.emitEvent("hidden");

      // Remove the height style
      el.style.height = "";
    },

    emitState() {
      const { accordionGroup, id, show } = this;

      // Emit state
      this.$emit("input", show);

      // Let `v-be-toggle` directive know about the state
      EventBus.emit("be::toggle::state", {
        id,
        state: show,
      });

      // Tell other collapses in the same accordion group to close
      if (accordionGroup && show) {
        EventBus.emit("be::collapse::accordion", {
          id,
          accordionGroup,
        });
      }
    },

    emitSync() {
      EventBus.emit("be::toggle::sync", {
        id: this.id,
        state: this.show,
      });
    },

    handleToggleEvent(id) {
      if (id === this.id) {
        this.toggle();
      }
    },

    handleAccordionEvent({
      id: openedId,
      accordionGroup: openedAccordionGroup,
    }) {
      const { accordionGroup, show } = this;

      if (!accordionGroup || accordionGroup !== openedAccordionGroup) {
        return;
      }

      const isThis = openedId === this.id;

      if ((isThis && !show) || (!isThis && show)) {
        this.toggle();
      }
    },

    handleResize() {
      // Handler for orientation/resize to set collapsed state in nav/navbar
      this.show = getComputedStyle(this.$el).display === "block";
    },

    emitEvent(type) {
      const cancelable = type === "show" || type === "hide";
      const beEvent = new BeEvent(type, {
        cancelable,
        target: this.$refs.collapse || this.$el || null,
        relatedTarget: null,
        trigger: null,
        vueTarget: this,
        componentId: this.id,
      });

      EventBus.emit(`be::collapse::${type}`, beEvent, this.id);
      this.$emit(type, beEvent);
    },
  },
};
</script>
