<script>
import { EventBus } from "@/event-bus";
import BI from "@/vendor/business-intelligence";
import Heartbeat from "@/mixins/heartbeat";
import KeepNotificationsAlive from "@/mixins/KeepNotificationsAlive";
import EventsChannel from "@/mixins/EventsChannel";
import Config from "@/config.js";
import { format } from "date-fns";
import { mapGetters } from "vuex";
import { trackFortnoxWebAnalyticsPageView } from "@/fortnox-web-analytics";

const DELIMITER_PATCH = {
  length: 1,

  charCodeAt: () => {
    return NaN;
  },
};

export default {
  /**
   * Vue uses the delimiters option to define how to tokenize and parse the template strings.
   *
   * If we pass in delimiters, Vue uses a function "toCharCodes" to convert the delimiters to char codes.
   * https://github.com/vuejs/core/blob/ab59bedae4e5e40b28804d88a51305b236d4a873/packages/compiler-core/src/parser.ts#L1062-L1063
   *
   * We cannot pass in `null` as delimiter, because this does not have a `length`. However, we don't want to pass an actual character
   * because we want to disable interpolation.
   * By passing in an object with a `length` property that then returns NaN for `charCodeAt`, we can effectively disable interpolation,
   * undefined, null, NaN, and numeric values are all strictly unequal to NaN.
   * https://github.com/vuejs/core/blob/ab59bedae4e5e40b28804d88a51305b236d4a873/packages/compiler-core/src/tokenizer.ts#L165
   * https://github.com/vuejs/core/blob/ab59bedae4e5e40b28804d88a51305b236d4a873/packages/compiler-core/src/tokenizer.ts#L331
   *
   * It also happens to work to send in an empty object or object that does not respond to length,
   * due to `.length` calls here and there returning undefined and equality checks not being evaluated to true.
   * https://github.com/vuejs/core/blob/ab59bedae4e5e40b28804d88a51305b236d4a873/packages/compiler-core/src/tokenizer.ts#L332
   *
   * Inspired by
   * https://github.com/vuejs/vue/issues/4223#issuecomment-294864675
   * https://github.com/alfa-jpn/vue-disable-interpolation/blob/master/src/vue_disable_interpolation.js#L3
   * Note: This option is deprecated in the options API, but by only applying it to the BaseApp,
   * we can still use {{ mustache interpolation }} as normal in our compiled components.
   * */
  delimiters: [DELIMITER_PATCH, DELIMITER_PATCH],
  mixins: [Heartbeat, KeepNotificationsAlive, EventsChannel],

  data() {
    return {
      fullscreen: false,
    };
  },

  computed: {
    ...mapGetters({
      inCorporateGroup: "company/inCorporateGroup",
    }),
  },

  created() {
    let token = document.head.querySelector('meta[name="csrf-token"]');

    if (token) {
      window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
    } else {
      console.debug(
        "CSRF token not found: https://guides.rubyonrails.org/security.html#cross-site-request-forgery-csrf"
      );
    }

    this.$i18n.locale = document.documentElement.lang || Config.DEFAULT_LOCALE;
    this.$store.dispatch("request_query/initialize");
    this.$store.dispatch("corporate_group_tree/initialize");

    if (window.bootstrapData) {
      this.$store.commit(
        "current_user/setUser",
        window.bootstrapData.current_user
      );

      const companies = window.bootstrapData.companies;
      if (companies) {
        this.$store.commit("company/setCompanies", companies);
      }

      const company = window.bootstrapData.company || {
        id: null,
      };
      this.$store.commit("company/setCompany", company);
      if (window.BIConfig) {
        this.$store.commit("company/setFinancials", {
          displayCorporateGroup: window.BIConfig.displayCorporateGroup || false,
        });
      }

      const adminPanel = window.bootstrapData.admin_panel;
      if (adminPanel) {
        this.$store.commit("platform/setAdminPanel", adminPanel);
      }

      const currentMembership = window.bootstrapData.current_membership || {
        id: null,
      };
      this.$store.commit("platform/setMembership", currentMembership);

      const platform = window.bootstrapData.platform;
      if (platform) {
        this.$store.commit("platform/setPlatform", platform);
      }

      const policies = window.bootstrapData.policies;
      if (policies) {
        this.$store.commit("platform/setPolicies", policies);
      }

      BI.bootstrap({
        organizationUuids: [
          adminPanel?.financials_uuid,
          company?.financials_uuid,
          company?.corporate_group_uuid,
        ],

        policies: {
          create_report: this.checkPolicy("create_report"),
        },
      });
    }
  },

  mounted() {
    EventBus.on("TOGGLE_FULLSCREEN", this.toggleFullscreen);

    EventBus.on("zendesk:set-help-center-suggestions", (labels) => {
      window.zE.setHelpCenterSuggestions({ labels: labels || [] });
    });

    EventBus.on("create-table-report", (data) => {
      this.tableToReport(data);
    });

    this.displayStoredNotifications();

    // Track page view for Fortnox Web Analytics
    trackFortnoxWebAnalyticsPageView();
  },

  methods: {
    async tableToReport(table) {
      const isConfirmed = await this.promptConfirm({
        title: this.$t("models.report.table_to_report_confirm"),
        confirmButtonText: this.$t("buttons.titles.create"),
        stayOpenAfterConfirm: true,
      });

      if (!isConfirmed) return;

      try {
        const { data } = await axios.post(
          `/companies/${this.$currentCompany.id}/financials/table_to_report`,
          {
            table: {
              uuid: table.uuid,
              origin: table.origin,
              date: format(table.date, "yyyy-MM-dd"),
              corporate_group: this.inCorporateGroup,
            },
          }
        );

        window.location.assign(this.url(`/reports/${data.id}/edit`));
      } catch (error) {
        this.handleError(error);
      }
    },

    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
      const body = document.querySelector("body");
      body.classList.toggle("vb-fullscreen-mode");
    },
  },
};
</script>
