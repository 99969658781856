<template>
  <div>
    <h3
      class="mb-3 d-print-none"
      v-text="$t('components.meetings.tabs.time_and_location.title')"
    />

    <tab-activity :owner="meeting" :accepted-keys="['meeting.created']" />

    <meeting-info
      :meeting="meeting"
      :inline-calendar-link="inlineCalendarLink"
    />

    <div class="table-responsive">
      <table v-if="suggestions && suggestions.length > 1" class="table">
        <thead>
          <tr>
            <th>{{ $t("components.meetings.suggestions.suggested_dates") }}</th>

            <th class="d-print-none"></th>

            <th>{{ $t("components.meetings.suggestions.replies") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="suggestion in suggestions"
            :key="suggestion.id"
            :class="{ 'bg-light': suggestion.selected_at }"
          >
            <th class="col-shrink">
              {{ $d(new Date(suggestion.start_at), "dateTime") }}

              <span class="text-muted font-weight-normal">
                ({{ $d(new Date(suggestion.start_at), { weekday: "long" }) }})
              </span>
            </th>

            <td class="col-shrink d-print-none">
              <availability-choices
                :availabilities="availabilities"
                :meeting-sealed="meeting.is_sealed"
                :suggestion="suggestion"
                @mark-availability="markAvailability"
              />
            </td>

            <td class="col-shrink">
              <be-badge
                v-for="availability in suggestionAvailabilities"
                :id="`availability-badge-${availability}-${suggestion.id}`"
                :key="`availability-badge-${availability}`"
                :variant="availabilityBadgeClass(availability)"
                class="ml-1"
              >
                {{ suggestionChoices(availability, suggestion) }}
              </be-badge>

              <template v-if="company && memberships">
                <be-tooltip
                  v-for="availability in suggestionAvailabilities"
                  :key="`availability-tooltip-${availability}`"
                  :target="`availability-badge-${availability}-${suggestion.id}`"
                  :disabled="
                    responsesUsers(availability, suggestion).length === 0
                  "
                >
                  <div
                    v-for="user in responsesUsers(availability, suggestion)"
                    :key="user.id"
                  >
                    {{ user.name }}
                  </div>
                </be-tooltip>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="!disableButtons">
      <be-button
        v-if="meeting.policy && meeting.policy.edit && !meeting.is_sealed"
        :href="meeting.paths.edit"
        variant="outline-primary"
      >
        {{ $t("components.meetings.tabs.time_and_location.edit") }}
      </be-button>

      <be-button
        :href="meeting.paths.calendar"
        variant="outline-secondary"
        icon="fa-arrow-down-to-bracket"
      >
        {{ $t("models.meeting.calendar_file") }}
      </be-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import MeetingInfo from "../components/MeetingInfo.vue";
import TabActivity from "./TabActivity.vue";
import AvailabilityChoices from "@/components/meetings/AvailabilityChoices.vue";

export default {
  components: {
    MeetingInfo,
    TabActivity,
    AvailabilityChoices,
  },

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    inlineCalendarLink: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableButtons: {
      type: Boolean,
      required: false,
      default: false,
    },

    withSuggestions: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    const defaultAvailabilities = ["available", "inconvenient", "unavailable"];
    return {
      availabilities: defaultAvailabilities,

      suggestionAvailabilities: this.withSuggestions
        ? [...defaultAvailabilities, "not_responded"]
        : null,

      suggestions: this.withSuggestions
        ? this.cloneDeep(this.meeting).suggestions
        : null,
    };
  },

  computed: {
    company() {
      return this.$store.getters["company/getById"](this.meeting.company_id);
    },

    memberships() {
      return this.company.memberships;
    },
  },

  methods: {
    ...mapMutations("meetings", ["updateMeetingSuggestionChoice"]),

    async markAvailability(suggestionData) {
      try {
        const response = await axios.patch(
          this.url(`/suggestions/${suggestionData.suggestion.id}/choices`, {
            company: this.company,
          }),
          {
            choice: {
              availability: suggestionData.availability,
            },
          }
        );

        this.updateSuggestionChoice(
          suggestionData.suggestion.id,
          response.data
        );
      } catch (error) {
        this.handleError(error);
      }
    },

    updateSuggestionChoice(suggestionId, choice) {
      // Update the store
      this.updateMeetingSuggestionChoice({
        meetingId: this.meeting.id,
        suggestionId,
        choice,
      });

      // Update the local data, because the store data is probably too deeply
      // nested to be reactive. This is a workaround for now.

      const suggestion = this.suggestions.find(
        (existing) => existing.id == suggestionId
      );

      if (suggestion == null) {
        return;
      }

      let index = suggestion.suggestion_choices.findIndex(
        (existing) => existing.id == choice.id
      );

      if (index === -1) {
        suggestion.suggestion_choices.push(choice);
      } else {
        suggestion.suggestion_choices[index] = choice;
      }
    },

    responsesUsers(availability, suggestion) {
      const userIds = suggestion.suggestion_choices
        .filter((sc) => sc.availability == availability)
        .map((sc) => sc.user_id);

      const users = userIds.map((userId) => {
        return this.memberships.find(
          (membership) => membership.user?.id === userId
        ).user;
      });

      return users;
    },

    suggestionChoices(availability, suggestion) {
      if (suggestion == undefined) {
        return "NO SUGGESTION";
      }

      return suggestion.suggestion_choices.filter(
        (choice) => choice.availability === availability
      ).length;
    },

    availabilityBadgeClass(availability) {
      switch (availability) {
        case "available":
          return "success";
        case "unavailable":
          return "danger";
        case "inconvenient":
          return "warning";
        case "not_responded":
          return "secondary";
        default:
          return "warning";
      }
    },
  },
};
</script>
