<template>
  <be-list-group-item
    :href="activityLink"
    class="text-decoration-none p-2 border-left-0"
  >
    <div class="media px-2">
      <div v-if="activity.avatar" class="d-inline-flex mr-2">
        <user-avatar :user="userId" :avatar="activity.avatar" />
      </div>

      <div class="media-body">
        <div class="row">
          <div class="col">
            <h6 class="my-0" v-text="activityTitle" />
          </div>

          <div class="col-auto">
            <be-badge variant="light">{{ activityDate }}</be-badge>
          </div>
        </div>

        <div class="small text-break">
          <em>
            <span v-if="preText" v-dompurify-html="preText" />

            <span v-dompurify-html="activityString" />
          </em>
        </div>
      </div>
    </div>
  </be-list-group-item>
</template>
<script>
import locales from "@/config/locales";
import { formatDistance } from "date-fns/formatDistance";
import TextUtilities from "@/mixins/textUtilities";

const AGENDA_KEYS = ["material.agenda.created", "material.agenda.updated"];

const MINUTES_KEYS = [
  "material.approved",
  "material.commented",
  "material.comments_requested",
  "material.declined",
  "material.done_reviewing",
  "material.minutes.completed_unlocking",
  "material.minutes.created",
  "material.minutes.unlocking_request.approved",
  "material.minutes.unlocking_request.created",
  "material.minutes.unlocking_request.destroyed",
  "material.minutes.unlocking_request.rejected",
  "material.minutes.updated",
  "material.review_started",
  "material.sealed",
  "material.uploaded_signed_minutes",
];

export default {
  mixins: [TextUtilities],

  props: {
    activity: {
      type: Object,
      required: true,
    },

    preText: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      currentDate: new Date(),
      interval: null,
    };
  },

  computed: {
    userId: {
      get() {
        if (this.activity.owner_type === "User") {
          return this.activity.owner_id;
        } else {
          return null;
        }
      },
    },

    activityTitle() {
      if (
        this.activity.trackable_type === "Material" &&
        MINUTES_KEYS.includes(this.activity.key)
      ) {
        return this.$t("activerecord.models.minutes.one");
      } else if (
        this.activity.trackable_type === "Material" &&
        AGENDA_KEYS.includes(this.activity.key)
      ) {
        return this.$t("activerecord.models.agenda.one");
      }

      const path = this.activity.trackable_type
        .split(/(?=[A-Z])/)
        .map((x) => x.toLowerCase())
        .join("_");

      return this.$t(`public_activity_beta.short.${path}.title`, {
        trackable_name: this.activity.trackable_name,
      });
    },

    activityDate() {
      let creationDate = new Date(this.activity.created_at);

      if (
        creationDate.getDate() == this.currentDate.getDate() &&
        creationDate.getHours() == this.currentDate.getHours()
      ) {
        return formatDistance(new Date(this.activity.created_at), new Date(), {
          addSuffix: true,
          locale: locales[this.$currentUser.locale],
        });
      } else {
        return this.$i18n.d(new Date(this.activity.created_at), "short");
      }
    },

    activityString() {
      let data = {
        user: this.activity.owner_name,
        description: this.activity.trackable_description,

        folder:
          this.activity.folder_title ||
          this.$t("models.folder.root_folder_title"),

        trackable_name: this.activity.trackable_name,

        trackable_body: this.truncateText(
          this.activity.trackable_description,
          64
        ),

        link: this.activityLink,
      };

      if (this.activity.key === "decision.update") {
        data["status_from"] = this.$t(
          `models.decision.statuses.${this.activity.parameters?.changes?.status[0]}`
        ).toLowerCase();
        data["status_to"] = this.$t(
          `models.decision.statuses.${this.activity.parameters?.changes?.status[1]}`
        ).toLowerCase();
      }

      if (this.activity.recipient) {
        data["recipient"] = this.activity.recipient.name;
      }

      if (
        Object.prototype.hasOwnProperty.call(this.activity, "trackable") &&
        Object.prototype.hasOwnProperty.call(
          this.activity.trackable,
          "start_at"
        )
      ) {
        data["trackable_date"] = this.$i18n.d(
          new Date(this.activity.trackable.start_at),
          "short"
        );
      }

      return this.$i18n.t(
        "public_activity_beta.short." + this.activity.key,
        data
      );
    },

    activityLink() {
      return this.activity.link;
    },
  },

  mounted() {
    this.interval = setInterval(() => {
      this.currentDate = new Date();
    }, 60000);
  },

  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>
