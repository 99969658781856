<template>
  <div class="card">
    <div
      v-if="canDestroyAdministrator"
      class="card-header d-flex justify-content-end"
    >
      <be-dropdown ellipsis>
        <be-dropdown-item
          :href="url(`/administrators/${membership.id}`)"
          data-method="delete"
          :data-confirm="destroyMembershipConfirmationTitle(membership)"
          :data-description="destroyMembershipConfirmationText(membership)"
          :data-stay-open-after-confirm="true"
          variant="danger"
        >
          {{ $t("buttons.titles.remove") }}
        </be-dropdown-item>
      </be-dropdown>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-lg-6">
          <be-form-group
            label-for="membership-user-name"
            :label="translateAttribute('user', 'name')"
            :error="getErrors(membership.user, 'name')"
            required
          >
            <be-form-input
              id="membership-user-name"
              v-model="membership.user.name"
              :disabled="disabledUserFields"
              @change="clearErrors(membership.user, 'name')"
            />
          </be-form-group>

          <be-form-group
            v-if="!platformEnabled('email_authentication')"
            label-for="membership-user-identification-number"
            :label="$t('activerecord.attributes.user.identification_number')"
            :error="getErrors(membership.user, 'identification_number')"
            :description="$t('models.user.hints.identification_number')"
            required
          >
            <be-form-input
              id="membership-user-identification-number"
              v-model="membership.user.identification_number"
              :disabled="disabledUserFields"
              :formatter="formatIdentificationNumber"
              required
              autocomplete="off"
              inputmode="numeric"
              trim
              @change="clearErrors(membership.user, 'identification_number')"
            />
          </be-form-group>

          <be-form-group
            label-for="membership-user-email"
            :label="translateAttribute('user', 'email')"
            :error="getErrors(membership.user, 'email')"
            required
          >
            <be-form-input
              id="membership-user-email"
              v-model="membership.user.email"
              :disabled="disabledUserFields"
              type="email"
              @change="clearErrors(membership.user, 'email')"
            />
          </be-form-group>

          <be-form-group
            label-for="membership-user-phone"
            :label="translateAttribute('user', 'phone')"
            :error="getErrors(membership.user, 'phone')"
          >
            <be-tel-input
              id="membership-user-phone"
              v-model="membership.user.phone"
              :disabled="disabledUserFields"
              @change="clearErrors(membership.user, 'phone')"
            />
          </be-form-group>

          <be-alert variant="info">
            {{
              disabledUserFields
                ? $t(
                    "components.admin_panel.administrators.membership_form.user_not_editable"
                  )
                : $t(
                    "components.admin_panel.administrators.membership_form.user_editable"
                  )
            }}
          </be-alert>

          <hr class="border-dashed mx-0" />

          <be-form-group
            label-for="membership-policy-level"
            :label="translateAttribute('membership', 'policy_level')"
            :invalid-feedback="getErrors(membership, 'policy_level')"
            required
          >
            <be-form-radio-group
              id="membership-policy-level"
              v-model="membership.policy_level"
              :state="validationState(membership, 'policy_level')"
              :options="policyLevelOptions"
              required
              @change="clearErrors(membership, 'policy_level')"
            />
          </be-form-group>

          <be-alert variant="info">
            <span
              v-dompurify-html="policyInformation(membership.policy_level)"
            />
          </be-alert>

          <be-alert v-if="changingOwnPolicyLevel" variant="danger">
            {{
              $t(
                "components.admin_panel.administrators.membership_form.changing_own_policy_level_warning"
              )
            }}
          </be-alert>
        </div>

        <div class="col-12 col-lg-6">
          <template v-if="adminCompanies.length">
            <be-form-group
              label-for="membership-admin-companies"
              :label="$t('activerecord.models.company.other')"
            >
              <multi-select
                :items="adminCompanies"
                :preselected-ids="membership.administered_admin_company_ids"
                :state="validationState(membership, 'admin_companies')"
                value-selector="company.title"
                key-selector="id"
                @change="
                  (membership.administered_admin_company_ids = $event) &&
                  clearErrors(membership, 'admin_companies')
                "
              />
            </be-form-group>
          </template>
        </div>
      </div>
    </div>

    <div class="card-footer d-flex">
      <be-button
        variant="primary"
        class="ml-auto"
        :loading="loading"
        @click="submit"
      >
        {{ buttonText }}
      </be-button>
    </div>
  </div>
</template>

<script>
import formatIdentificationNumber from "@/utils/format-identification-number";

export default {
  props: {
    adminPanelMembership: {
      type: Object,
      required: true,
    },

    adminCompanies: {
      type: Array,
      required: true,
    },

    canDestroyAdministrator: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      membership: this.cloneDeep(this.adminPanelMembership),
      loading: false,
    };
  },

  computed: {
    disabledUserFields() {
      if (this.membership.id && this.membership.user.id) {
        return !this.membership.user.can_accept_invitation;
      } else {
        return false;
      }
    },

    buttonText() {
      if (this.membership.user.id) {
        return this.$t("buttons.titles.save");
      } else {
        return this.$t("models.admin_panel_membership.create");
      }
    },

    policyLevelOptions() {
      return [
        {
          text: this.$t("models.admin_panel_membership.policy_levels.admin"),
          value: "admin",
        },
        {
          text: this.$t("models.admin_panel_membership.policy_levels.observer"),
          value: "observer",
        },
        {
          text: this.$t("models.admin_panel_membership.policy_levels.limited"),
          value: "limited",
        },
        {
          text: this.$t(
            "models.admin_panel_membership.policy_levels.admin_company_document_manager"
          ),

          value: "admin_company_document_manager",
          disabled: !this.flipperFlag("admin_panel_document_managers"),
        },
      ].filter(({ disabled }) => !disabled);
    },

    changingOwnPolicyLevel() {
      return (
        this.membership.user.id === this.$currentUser.id &&
        this.membership.policy_level !== "admin"
      );
    },
  },

  methods: {
    formatIdentificationNumber,

    policyInformation(policy) {
      switch (policy) {
        case "limited":
          return this.$t(
            "models.admin_panel_membership.policy_level_info.limited_html"
          );
        case "observer":
          return this.$t(
            "models.admin_panel_membership.policy_level_info.observer_html"
          );
        case "admin":
          return this.$t(
            "models.admin_panel_membership.policy_level_info.admin_html"
          );
        case "admin_company_document_manager":
          return this.$t(
            "models.admin_panel_membership.policy_level_info.document_manager_html"
          );
        default:
          return "";
      }
    },

    async request(parameters) {
      if (this.membership.user.id) {
        return axios.patch(
          `/admin_panel/${this.$currentAdminPanel.id}/administrators/${this.membership.id}`,
          parameters
        );
      } else {
        return axios.post(
          `/admin_panel/${this.$currentAdminPanel.id}/administrators`,
          parameters
        );
      }
    },

    async confirmChangingPolicyLevel() {
      if (this.changingOwnPolicyLevel) {
        const isConfirmed = await this.promptRemovalConfirm({
          confirmButtonText: this.$t("buttons.titles.continue"),

          title: this.$t(
            "components.admin_panel.administrators.membership_form.confirm_changing_own_policy_level_title"
          ),

          description: this.$t(
            "components.admin_panel.administrators.membership_form.changing_own_policy_level_warning"
          ),
        });

        return isConfirmed;
      } else {
        return true;
      }
    },

    async submit() {
      try {
        const confirm = await this.confirmChangingPolicyLevel();
        if (!confirm) {
          return;
        }

        this.loading = true;

        const { data } = await this.request({
          admin_panel_membership: this.membership,
        });

        this.membership = data;

        if (window.location.pathname.includes("/administrators/new")) {
          window.location.href = `/admin_panel/${this.$currentAdminPanel.id}/administrators/${this.membership.id}/edit`;
        } else {
          this.loading = false;
        }
      } catch (error) {
        if (error.response?.status === 422) {
          this.membership = error.response.data;
        } else if (error.response?.status === 409 && !this.membership.id) {
          window.location.href = `/admin_panel/${this.$currentAdminPanel.id}/administrators/new`;
        } else {
          this.handleError(error);
        }
        this.loading = false;
      }
    },

    destroyMembershipConfirmationText(membership) {
      if (membership.user_id === this.$currentUser.id) {
        return this.$t(
          "components.admin_panel.administrators.index.confirm_delete_current_user_description"
        );
      } else {
        return "";
      }
    },

    destroyMembershipConfirmationTitle(membership) {
      if (membership.user_id === this.$currentUser.id) {
        return this.$t(
          "components.admin_panel.administrators.index.confirm_delete_current_user"
        );
      } else {
        return this.$t(
          "components.admin_panel.administrators.index.confirm_delete_w_name",
          { name: membership.user.name }
        );
      }
    },
  },
};
</script>
