<template>
  <transition
    enter-active-class="fade"
    enter-to-class="show"
    leave-class="show"
    leave-active-class="fade"
    @before-enter="onBeforeEnter"
    @after-enter="onAfterEnter"
    @before-leave="onBeforeLeave"
    @after-leave="onAfterLeave"
  >
    <div
      v-if="isVisible"
      :id="id"
      ref="tooltip"
      :class="computedClasses"
      role="tooltip"
      tabindex="-1"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      @focusin="onFocusIn"
      @focusout="onFocusOut"
    >
      <div ref="arrow" class="arrow"></div>

      <div class="tooltip-inner">
        <slot>
          {{ title }}
        </slot>
      </div>
    </div>
  </transition>
</template>

<script>
import Popper from "popper.js";
import { EventBus } from "@/event-bus";
import { generateId } from "@/utils/id";

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;

export default {
  name: "BeTooltip",

  props: {
    boundary: {
      type: [HTMLElement, Object, String],
      required: false,
      default: "scrollParent",
    },

    boundaryPadding: {
      type: [Number, String],
      required: false,
      default: 5,
    },

    customClass: {
      type: String,
      required: false,
      default: undefined,
    },

    delay: {
      type: [Array, Number, Object, String],
      required: false,
      default: 50,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    fallbackPlacement: {
      type: [Array, String],
      required: false,
      default: "flip",
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("tooltip"),
    },

    interactive: {
      type: Boolean,
      required: false,
      default: true,
    },

    offset: {
      type: [Number, String],
      required: false,
      default: 0,
    },

    placement: {
      type: String,
      required: false,
      default: "top",
    },

    target: {
      type: [HTMLElement, String],
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: undefined,
    },

    trigger: {
      type: [String, Array],
      required: false,
      default: "hover focus",
    },
  },

  emits: [
    "focusin",
    "focusout",
    "hidden",
    "hide",
    "mouseenter",
    "mouseleave",
    "show",
    "shown",
  ],

  data() {
    return {
      attachment: null,
      isClosing: false,
      isVisible: false,
    };
  },

  computed: {
    computedClasses() {
      return [
        "tooltip",
        "be-tooltip",
        [`bs-tooltip-${this.computedPlacementClass}`],
        {
          noninteractive: !this.interactive,
        },
        this.customClass,
      ];
    },

    computedDelay() {
      const delay = { show: 0, hide: 0 };

      // If delay is a number or a string
      if (typeof this.delay === "number" || typeof this.delay === "string") {
        delay.show = delay.hide = parseInt(this.delay, 10);
      } else if (typeof this.delay === "object") {
        // If delay is an object
        delay.show = parseInt(this.delay.show, 10) || 0;
        delay.hide = parseInt(this.delay.hide, 10) || 0;
      } else if (Array.isArray(this.delay)) {
        // If delay is an array
        delay.show = parseInt(this.delay[0], 10) || 0;
        delay.hide = parseInt(this.delay[1], 10) || 0;
      }

      return delay;
    },

    computedPlacementClass() {
      if (this.attachment) {
        return this.attachment;
      }

      switch (this.placement) {
        case "topright":
        case "topleft":
          return "top";
        case "bottomright":
        case "bottomleft":
          return "bottom";
        case "righttop":
        case "rightbottom":
          return "right";
        case "lefttop":
        case "leftbottom":
          return "left";
        default:
          return this.placement;
      }
    },

    computedPlacement() {
      switch (this.placement) {
        case "topright":
          return "top-end";
        case "topleft":
          return "top-start";
        case "bottomright":
          return "bottom-end";
        case "bottomleft":
          return "bottom-start";
        case "righttop":
          return "right-start";
        case "rightbottom":
          return "right-end";
        case "lefttop":
          return "left-start";
        case "leftbottom":
          return "left-end";
        default:
          return this.placement;
      }
    },

    computedTriggers() {
      if (typeof this.trigger === "string") {
        return this.trigger.split(" ");
      } else if (Array.isArray(this.trigger)) {
        return this.trigger;
      } else {
        return [];
      }
    },
  },

  watch: {
    disabled() {
      if (this.disabled) {
        this.hide(true);
      }
    },

    title() {
      this.updatePopper();
    },
  },

  mounted() {
    if (this.target) {
      // Bind trigger events to target
      this.bindTriggerEvents();
    }

    // If another tooltip is opened, hide this one
    EventBus.on("be::tooltip::show", (id) => {
      if (id && id !== this.id) {
        this.hide(true);
      }
    });

    // Listen to global root show event
    EventBus.on("be::show::tooltip", (id) => {
      // If an ID is passed, only show if it matches the current ID
      if (id && id === this.id) {
        this.show();
      }
    });

    // Listen to global root hide event
    EventBus.on("be::hide::tooltip", (id) => {
      // If an ID is passed, only hide if it matches the current ID,
      // otherwise hide all tooltips
      if (id && id === this.id) {
        this.hide();
      } else {
        this.hide(true);
      }
    });
  },

  beforeUnmount() {
    // Remove tooltip element from DOM
    requestAnimationFrame(() => {
      this.$el.parentNode?.removeChild(this.$el);
    });

    // Unbind trigger events from target
    this.unbindTriggerEvents();
  },

  methods: {
    show() {
      // Return if tooltip is disabled or title is empty or the default slot is not used
      if (this.disabled || (!this.title && !this.$slots.default)) {
        return;
      }

      // Wait for the defined delay
      setTimeout(() => {
        this.isVisible = true;

        // Set `aria-describedby` on target
        this.setAriaDescribedBy();

        // Initialize Popper
        this.$nextTick(() => {
          requestAnimationFrame(() => {
            this.initPopper();
          });
        });
      }, this.computedDelay.show);
    },

    hide(force = false) {
      if (this.disabled && !force) {
        return;
      }

      // Set `isClosing` to true
      this.isClosing = true;

      // Wait for the defined delay
      setTimeout(() => {
        // If `isClosing` is false, it means the tooltip was hovered
        // befpre the delay ended, so we'll cancel the hide, unless
        // `force` is true
        if (!this.isClosing && !force) {
          return;
        }

        // Hide tooltip
        this.isVisible = false;

        // Remove `aria-describedby` from target
        this.removeAriaDescribedBy();
      }, this.computedDelay.hide);
    },

    toggle() {
      if (this.disabled) {
        return;
      }

      if (this.isVisible) {
        this.hide();
      } else {
        this.show();
      }
    },

    initPopper() {
      // Return if tooltip element doesn't exist
      if (!this.$refs.tooltip) {
        return;
      }

      // If an existing popper instance exists, destroy it first
      if (this._popper) {
        this._popper.destroy();
      }

      // Get target element
      const target = this.getTarget() || this.$refs.tooltip.parentNode;

      // Instantiate Popper
      this._popper = new Popper(target, this.$refs.tooltip, {
        placement: this.computedPlacement,

        modifiers: {
          arrow: {
            element: ".arrow",
          },

          flip: {
            behavior: this.fallbackPlacement,
          },

          offset: {
            offset: this.offset,
          },

          preventOverflow: {
            boundariesElement: this.boundary,
            padding: this.boundaryPadding,
          },
        },

        onCreate: (data) => {
          // Handle flipping arrow classes
          if (data.originalPlacement !== data.placement) {
            this.attachment = data.placement;
          } else {
            this.attachment = null;
          }
        },

        onUpdate: (data) => {
          // Handle flipping arrow classes
          if (data.originalPlacement !== data.placement) {
            this.attachment = data.placement;
          } else {
            this.attachment = null;
          }
        },
      });
    },

    updatePopper() {
      if (this._popper) {
        this._popper.scheduleUpdate();
      }
    },

    destroyPopper() {
      if (this._popper) {
        this._popper.destroy();
      }
    },

    bindTriggerEvents() {
      const target = this.getTarget();

      if (!target) {
        return;
      }

      // Listen to trigger events on target
      this.computedTriggers.forEach((trigger) => {
        if (trigger === "click") {
          target.addEventListener("click", this.toggleHandler);
        } else if (trigger === "hover") {
          target.addEventListener("mouseenter", this.showHandler);
          target.addEventListener("mouseleave", this.hideHandler);
        } else if (trigger === "focus") {
          target.addEventListener("focusin", this.showHandler);
          target.addEventListener("focusout", this.hideHandler);
        } else if (trigger === "blur") {
          target.addEventListener("blur", this.hideHandler);
        }
      });
    },

    unbindTriggerEvents() {
      const target = this.getTarget();

      if (!target) {
        return;
      }

      // Remove trigger events on target
      this.computedTriggers.forEach((trigger) => {
        if (trigger === "click") {
          target.removeEventListener("click", this.toggleHandler);
        } else if (trigger === "hover") {
          target.removeEventListener("mouseenter", this.showHandler);
          target.removeEventListener("mouseleave", this.hideHandler);
        } else if (trigger === "focus") {
          target.removeEventListener("focusin", this.showHandler);
          target.removeEventListener("focusout", this.hideHandler);
        } else if (trigger === "blur") {
          target.removeEventListener("blur", this.hideHandler);
        }
      });
    },

    showHandler() {
      this.show();
    },

    hideHandler() {
      this.hide();
    },

    toggleHandler() {
      this.toggle();
    },

    getTarget() {
      // If target is a string, we need to fetch it from the DOM,
      // otherwise we'll assume it's an element
      if (typeof this.target === "string") {
        // If string contains "#" or ".", assume it's a CSS selector,
        // otherwise assume it's an id
        if (this.target.includes("#") || this.target.includes(".")) {
          return document.querySelector(this.target);
        } else {
          return document.getElementById(this.target);
        }
      } else {
        return this.target;
      }
    },

    setAriaDescribedBy() {
      const target = this.getTarget();

      if (!target) {
        return;
      }

      // Get current `aria-describedby` value
      const ariaDescribedBy = target.getAttribute("aria-describedby");

      // If `aria-describedby` exists, append tooltip ID
      if (ariaDescribedBy) {
        target.setAttribute(
          "aria-describedby",
          `${ariaDescribedBy} ${this.id}`
        );
      } else {
        target.setAttribute("aria-describedby", this.id);
      }
    },

    removeAriaDescribedBy() {
      const target = this.getTarget();

      if (!target) {
        return;
      }

      // Get current `aria-describedby` value
      const ariaDescribedBy = target.getAttribute("aria-describedby");

      // If `aria-describedby` exists, remove tooltip ID
      if (ariaDescribedBy) {
        const ids = ariaDescribedBy
          .split(" ")
          .filter((id) => id !== this.id)
          .join(" ");

        if (ids) {
          target.setAttribute("aria-describedby", ids);
        } else {
          target.removeAttribute("aria-describedby");
        }
      }
    },

    emitEvent(event) {
      this.$emit(event, this.id);
      EventBus.emit(`be::tooltip::${event}`, this.id);
    },

    onMouseEnter() {
      this.emitEvent("mouseenter");

      // If tooltip is closing, cancel the hide
      this.isClosing = false;
    },

    onMouseLeave(event) {
      this.emitEvent("mouseleave");

      // Hide the tooltip if the trigger is `hover`,
      // unless the trigger target is hovered
      if (this.computedTriggers.includes("hover")) {
        const target = this.getTarget();

        if (target && !target.contains(event.relatedTarget)) {
          this.hide();
        }
      }
    },

    onFocusIn() {
      this.emitEvent("focusin");
    },

    onFocusOut() {
      this.emitEvent("focusout");

      // Hide the tooltip if the trigger is `focus`,
      // unless the trigger target is focused
      if (this.computedTriggers.includes("focus")) {
        const target = this.getTarget();

        if (target && !target.contains(document.activeElement)) {
          this.hide();
        }
      }
    },

    onBeforeEnter() {
      this.emitEvent("show");
    },

    onAfterEnter() {
      this.emitEvent("shown");
    },

    onBeforeLeave() {
      this.emitEvent("hide");
    },

    onAfterLeave() {
      this.emitEvent("hidden");

      // Ensure popper is destroyed
      this.destroyPopper();

      // Ensure tooltip is hidden
      this.isClosing = false;
      this.isVisible = false;
    },
  },
};
</script>
